import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button.tsx";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select.tsx";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { CreateRouteDialog } from "@/modules/routes/dialogs/create-route.dialog.tsx";
import { TourLegQueries } from "@/modules/routes/queries/tour-leg.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { Route } from "@repo/types";
import { Link, useNavigate } from "@tanstack/react-router";
import Fuse from "fuse.js";
import { uniq } from "lodash";
import { ListFilter, PlusCircle, Search } from "lucide-react";
import { FunctionComponent, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import * as z from "zod";
import { RouteQueries } from "../queries/route.queries";

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { TourQueries } from "@/modules/tours/queries/tour.queries";

type FilterValues = z.infer<typeof filterSchema>;

const filterSchema = z.object({
	search: z.string(),
	tourId: z.string().optional(),
});

export function RoutesRoutea() {
	const form = useForm<FilterValues>({
		resolver: zodResolver(filterSchema),
		defaultValues: {
			search: "",
		},
	});

	return (
		<>
			<Form {...form}>
				<div className="flex items-center gap-4 justify-between">
					<h1 className="text-lg font-semibold md:text-2xl">Routes</h1>
					<CreateRouteDialog>
						<Button variant={"outline"} size={"sm"}>
							Create Route
						</Button>
					</CreateRouteDialog>
				</div>
				<div className="lg:grid flex-1 gap-4 overflow-auto md:grid-cols-2 lg:grid-cols-3">
					<Filter />
					<div className="relative flex h-full min-h-[50vh] flex-col rounded-xl bg-muted/50 p-4 lg:col-span-2">
						<RoutesTable />
					</div>
				</div>
			</Form>
		</>
	);
}

const Filter = () => {
	const { data: tours } = TourQueries.useTours();

	return (
		<div className="relative flex-col items-start gap-8 md:flex">
			<div className="grid w-full items-start gap-6">
				<fieldset className="grid gap-6 rounded-lg border p-4">
					<legend className="-ml-1 px-1 text-sm font-medium">Filter</legend>

					<FormField<FilterValues>
						name="search"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Search</FormLabel>
								<FormControl>
									<Input
										{...field}
										type="text"
										placeholder="23 Day Twin Island ..."
									/>
								</FormControl>
								<FormDescription />
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						name="tourId"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Tour</FormLabel>
								<FormControl>
									<Select value={field.value} onValueChange={field.onChange}>
										<SelectTrigger>
											<SelectValue defaultValue={field.value} />
										</SelectTrigger>
										<SelectContent className={"z-[2000]"}>
											{tours?.map((tour) => (
												<SelectItem value={tour.id}>{tour.name}</SelectItem>
											))}
										</SelectContent>
									</Select>
								</FormControl>
								{/*<FormDescription>*/}
								{/*	{field.value === "ACTIVE" &&*/}
								{/*		"Tour date is showing to customers"}*/}
								{/*	{field.value === "INACTIVE" &&*/}
								{/*		"Tour date is not showing to customers"}*/}
								{/*	{field.value === "DELETED" &&*/}
								{/*		"Tour date is deleted and not showing to customers"}*/}
								{/*	{field.value === "FULL" && "Tour date is full"}*/}
								{/*	{field.value === "CANCELLED" &&*/}
								{/*		"Tour date is cancelled and not showing to customers"}*/}
								{/*</FormDescription>*/}
								<FormMessage />
							</FormItem>
						)}
					/>
				</fieldset>
			</div>
		</div>
	);
};

const RoutesTable = () => {
	const { data: routes } = RouteQueries.useRoutes();

	const { search } = useWatch<FilterValues>();

	const fuzzy = useMemo(() => {
		return new Fuse(routes ?? [], {
			keys: ["name"],
		});
	}, [routes]);

	const filteredRoutes = useMemo(() => {
		let items = routes ?? [];

		if (search != null && search !== "") {
			items = fuzzy.search(search).map((result) => result.item);
		}

		// if (tourId != null) {
		// 	items = items.filter((route) => route.t === tourId);
		// }

		return items;
	}, [routes, search, fuzzy]);

	const navigate = useNavigate();

	const onRowClick = (route: Route) => {
		void navigate({
			to: "/routes/$routeId",
			params: {
				routeId: route.id,
			},
		});
	};

	return (
		<Table>
			<TableHeader>
				<TableRow>
					{/*<TableHead className={"w-40"} />*/}
					<TableHead>Id</TableHead>
					<TableHead>Name</TableHead>
					<TableHead>Tours</TableHead>
					{/*<TableHead>Type</TableHead>*/}
					{/*<TableHead className="text-right">Status</TableHead>*/}
				</TableRow>
			</TableHeader>
			<TableBody>
				{filteredRoutes?.map((route) => {
					return <RouteRow route={route} key={route.id} onClick={onRowClick} />;
				})}
			</TableBody>
		</Table>
	);
};

type RouteRowProps = {
	route: Route;
	onClick: (route: Route) => void;
};

const RouteRow: FunctionComponent<RouteRowProps> = ({ route, onClick }) => {
	// const geometry = route.geometry != null ? JSON.parse(route.geometry) : null;
	// const bbox = geometry != null ? turf.bbox(geometry) : null;
	//
	// const enlargedBbox =
	// 	bbox != null ? turf.bbox(turf.buffer(turf.bboxPolygon(bbox), 0.1)) : null;
	//
	// const layerStyle: LineLayer = {
	// 	id: `route-line-${route.id}`,
	// 	type: "line",
	// 	paint: {
	// 		"line-width": 4,
	// 		"line-color": "#13a34a",
	// 	},
	// };

	return (
		<TableRow
			onClick={() => onClick(route)}
			className="cursor-pointer hover:bg-gray-300/80"
		>
			<TableCell>{route.id}</TableCell>
			{/*<div className="w-60 h-60 relative aspect-square rounded-xl overflow-hidden">*/}
			{/*	{enlargedBbox != null && (*/}
			{/*		<MapboxMap*/}
			{/*			id={"map"}*/}
			{/*			mapLib={Mapbox}*/}
			{/*			mapboxAccessToken={*/}
			{/*				"pk.eyJ1IjoidXJiYW5jaHJpc3kiLCJhIjoiY2xzbGo5cnhwMGVoazJqcDY0N3RqeG92OSJ9.C9sIOo45b61JpdvgbMhtVw"*/}
			{/*			}*/}
			{/*			initialViewState={{*/}
			{/*				bounds: [*/}
			{/*					enlargedBbox[0],*/}
			{/*					enlargedBbox[1],*/}
			{/*					enlargedBbox[2],*/}
			{/*					enlargedBbox[3],*/}
			{/*				],*/}
			{/*				fitBoundsOptions: {*/}
			{/*					padding: 20,*/}
			{/*				},*/}
			{/*			}}*/}
			{/*			interactive={false}*/}
			{/*			reuseMaps*/}
			{/*			style={{ width: "100%", height: "100%" }}*/}
			{/*			mapStyle="mapbox://styles/urbanchrisy/clssv8s57001y01r5hmvs0x1z"*/}
			{/*		>*/}
			{/*			<Source*/}
			{/*				id={`route-line-${route.id}`}*/}
			{/*				type="geojson"*/}
			{/*				data={geometry}*/}
			{/*			>*/}
			{/*				<Layer {...layerStyle} />*/}
			{/*			</Source>*/}
			{/*		</MapboxMap>*/}
			{/*	)}*/}
			{/*</div>*/}
			{/*</TableCell>*/}
			<TableCell className="font-medium">
				<h3 className="text-lg font-semibold">{route.name}</h3>
				<span className="text-sm text-gray-500">
					{route.from}
					{" - "}
					{route.to}
				</span>
			</TableCell>
			<TableCell>
				<RouteTours routeId={route.id} />
			</TableCell>
			{/*<TableCell>{route.type.map(getTourType).join(" - ")}</TableCell>*/}
			{/*<TableCell  className="text-right">*/}
			{/*	<Badge variant={getBadgeVariant(route.status)}>*/}
			{/*		{route.status}*/}
			{/*	</Badge>*/}
			{/*</TableCell>*/}
		</TableRow>
	);
};

type RouteToursProps = {
	routeId: string;
};

const RouteTours: FunctionComponent<RouteToursProps> = ({ routeId }) => {
	const { data: tourLegs } = TourLegQueries.useTourLegsByRouteId(routeId);
	const { data: allTours } = TourQueries.useTours();

	const tourIds = uniq(tourLegs?.map((tourLeg) => tourLeg.tour_id) ?? []);

	const tours = tourIds?.map((tourId) =>
		allTours?.find((tour) => tour.id === tourId),
	);

	return (
		<div className={"pointer-events-none flex flex-rap gap-2"}>
			{tours?.map(
				(tour) =>
					tour != null && (
						<Link
							to={"/tours/$tourId"}
							params={{ tourId: tour.id }}
							onClick={(e) => e.stopPropagation()}
							className={"pointer-events-auto hover:underline"}
						>
							{tour.name}
						</Link>
					),
			)}
		</div>
	);
};

// const getTourType = (type: string) => {
// 	switch (type) {
// 		case "GUIDED":
// 			return "Guided";
// 		case "SELF_GUIDED":
// 			return "Self-guided";
// 		default:
// 			return "Unknown";
// 	}
// };
//
// const getBadgeVariant = (status: TourStatusEnum) => {
// 	switch (status) {
// 		case "ACTIVE":
// 			return "default";
// 		case "INACTIVE":
// 			return "secondary";
// 		default:
// 			return "secondary";
// 	}
// };

type Filter = "ALL" | "ACTIVE" | "INACTIVE" | "DELETED";

export function RoutesRoute() {
	const { data: routes } = RouteQueries.useRoutes();

	const [search, setSearch] = useState<string>("");
	const [filter, setFilter] = useState<Filter>("ALL");

	const fuzzy = useMemo(() => {
		return new Fuse(routes ?? [], {
			keys: ["name", "from", "to", "description"],
		});
	}, [routes]);

	const filteredRoutes = useMemo(() => {
		if (search == null || search === "") {
			return routes;
		}
		return fuzzy.search(search).map((result) => result.item);
	}, [search, fuzzy, routes]);

	return (
		<div className="flex flex-col sm:gap-4 sm:py-4">
			<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
				<Breadcrumb className="hidden md:flex">
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink asChild>
								<Link href="#" params={{}}>
									Routes
								</Link>
							</BreadcrumbLink>
						</BreadcrumbItem>
						{/*<BreadcrumbSeparator/>*/}
						{/*<BreadcrumbItem>*/}
						{/*	<BreadcrumbLink asChild>*/}
						{/*		<Link href="#">Products</Link>*/}
						{/*	</BreadcrumbLink>*/}
						{/*</BreadcrumbItem>*/}
						{/*<BreadcrumbSeparator/>*/}
						{/*<BreadcrumbItem>*/}
						{/*	<BreadcrumbPage>All Products</BreadcrumbPage>*/}
						{/*</BreadcrumbItem>*/}
					</BreadcrumbList>
				</Breadcrumb>
				<div className="relative ml-auto flex-1 md:grow-0">
					<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
					<Input
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						type="search"
						placeholder="Search..."
						className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]"
					/>
				</div>
			</header>
			<main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
				<div className="flex items-center">
					<div className="ml-auto flex items-center gap-2">
						{/*<Select value={filter} onValueChange={(e) => setFilter(e as Filter)}>*/}
						{/*	<SelectTrigger className="h-7 gap-1">*/}
						{/*		<SelectValue placeholder="All" />*/}
						{/*	</SelectTrigger>*/}
						{/*	<SelectContent>*/}
						{/*		<SelectItem value="ALL">All</SelectItem>*/}
						{/*		<SelectItem value="ACTIVE">Active</SelectItem>*/}
						{/*		<SelectItem value="INACTIVE">Inactive</SelectItem>*/}
						{/*		<SelectItem value="DELETED">Deleted</SelectItem>*/}
						{/*	</SelectContent>*/}
						{/*</Select>*/}

						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="outline"
									size="sm"
									className={cn(
										"h-7 gap-1 text-sm",
										filter !== "ALL" && "outline outline-primary",
									)}
								>
									<ListFilter className="h-3.5 w-3.5" />
									<span className="sr-only sm:not-sr-only">Status</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuLabel>Filter status by</DropdownMenuLabel>
								<DropdownMenuSeparator />
								<DropdownMenuCheckboxItem
									checked={filter === "ALL"}
									onClick={() => setFilter("ALL")}
								>
									All
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									checked={filter === "ACTIVE"}
									onClick={() => setFilter("ACTIVE")}
								>
									Active
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									checked={filter === "INACTIVE"}
									onClick={() => setFilter("INACTIVE")}
								>
									Inactive
								</DropdownMenuCheckboxItem>
							</DropdownMenuContent>
						</DropdownMenu>

						<CreateRouteDialog>
							<Button size="sm" className="h-7 gap-1">
								<PlusCircle className="h-3.5 w-3.5" />
								<span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
									Add Route
								</span>
							</Button>
						</CreateRouteDialog>
					</div>
				</div>
				<Card>
					<CardHeader>
						<CardTitle>Routes</CardTitle>
						<CardDescription>Manage routes</CardDescription>
					</CardHeader>
					<CardContent>
						<Table>
							<TableHeader>
								<TableRow>
									{/*<TableHead className="hidden w-[100px] sm:table-cell">*/}
									{/*	<span className="sr-only">Image</span>*/}
									{/*</TableHead>*/}
									<TableHead>Name</TableHead>
									<TableHead>From</TableHead>
									<TableHead>To</TableHead>
									<TableHead>Tours</TableHead>
									{/*<TableHead className="hidden md:table-cell">*/}
									{/*	Total Sales*/}
									{/*</TableHead>*/}
									{/*<TableHead>*/}
									{/*	<span className="sr-only">Actions</span>*/}
									{/*</TableHead>*/}
								</TableRow>
							</TableHeader>
							<TableBody>
								{filteredRoutes?.map((tour) => (
									<RoutesTableRow route={tour} key={tour.id} />
								))}
							</TableBody>
						</Table>
					</CardContent>
					{/*<CardFooter>*/}
					{/*	<div className="text-xs text-muted-foreground">*/}
					{/*		Showing <strong>1-10</strong> of <strong>32</strong>{" "}*/}
					{/*		routes*/}
					{/*	</div>*/}
					{/*</CardFooter>*/}
				</Card>
			</main>
		</div>
	);
}

const RoutesTableRow: FunctionComponent<{ route: Route }> = ({ route }) => {
	const navigate = useNavigate();

	const onRowClick = () => {
		void navigate({
			to: "/routes/$routeId",
			params: {
				routeId: route.id,
			},
		});
	};

	return (
		<TableRow
			onClick={onRowClick}
			className="cursor-pointer hover:bg-gray-100/80"
		>
			{/*<TableCell className="hidden sm:table-cell">*/}
			{/*	<Image*/}
			{/*		alt="Product image"*/}
			{/*		className="aspect-square rounded-md object-cover"*/}
			{/*		height="64"*/}
			{/*		src="/placeholder.svg"*/}
			{/*		width="64"*/}
			{/*	/>*/}
			{/*</TableCell>*/}
			<TableCell className="font-medium">{route.name}</TableCell>
			<TableCell>{route.from}</TableCell>
			<TableCell>{route.to}</TableCell>
			<TableCell>
				<RouteTours routeId={route.id} />
			</TableCell>
			{/*<TableCell>*/}
			{/*	<Badge variant={getBadgeVariant(route.status)}>{route.status}</Badge>*/}
			{/*</TableCell>*/}
			{/*<TableCell>{route.type.map(getTourType).join(" - ")}</TableCell>*/}
			{/*<TableCell>{route.price_from}</TableCell>*/}
			{/*<TableCell className="hidden md:table-cell">*/}
			{/*	25*/}
			{/*</TableCell>*/}
			{/*<TableCell>*/}
			{/*	<DropdownMenu>*/}
			{/*		<DropdownMenuTrigger asChild>*/}
			{/*			<Button aria-haspopup="true" size="icon" variant="ghost">*/}
			{/*				<MoreHorizontal className="h-4 w-4" />*/}
			{/*				<span className="sr-only">Toggle menu</span>*/}
			{/*			</Button>*/}
			{/*		</DropdownMenuTrigger>*/}
			{/*		<DropdownMenuContent align="end">*/}
			{/*			<DropdownMenuLabel>Actions</DropdownMenuLabel>*/}
			{/*			<DropdownMenuItem>Edit</DropdownMenuItem>*/}
			{/*			<DropdownMenuItem>Delete</DropdownMenuItem>*/}
			{/*		</DropdownMenuContent>*/}
			{/*	</DropdownMenu>*/}
			{/*</TableCell>*/}
		</TableRow>
	);
};
