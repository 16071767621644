import { Checkbox } from "@/components/ui/checkbox";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { TourFormSchema } from "@/modules/tours/components/tour-details-form.tsx";
import { FunctionComponent } from "react";

const items = [
	{
		id: "GUIDED",
		label: "Guided",
	},
	{
		id: "SELF_GUIDED",
		label: "Self-Guided",
	},
] as const;

export const TourTypeField: FunctionComponent = () => {
	return (
		<FormField<TourFormSchema, "type">
			name="type"
			render={() => (
				<FormItem>
					<div className="mb-4">
						<FormLabel className="text-base">Tour Type</FormLabel>
					</div>
					{items.map((item) => (
						<FormField<TourFormSchema, "type">
							key={item.id}
							name="type"
							render={({ field }) => {
								return (
									<FormItem
										key={item.id}
										className="flex flex-row items-start space-x-3 space-y-0"
									>
										<FormControl>
											<Checkbox
												checked={field.value?.includes(item.id)}
												onCheckedChange={() => field.onChange([item.id])}
											/>
										</FormControl>
										<FormLabel className="font-normal">{item.label}</FormLabel>
									</FormItem>
								);
							}}
						/>
					))}
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
