import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Loading } from "@/components/ui/loading";
import { useDialogState } from "@/hooks/use-dialog-state";
import { FileExplorer } from "@/modules/images/components/file-explorer";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Route } from "@/routes/tours/$tourId/images";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import { CSS } from "@dnd-kit/utilities";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { useQuery } from "@tanstack/react-query";
import { isEqual, uniq } from "lodash";
import { X } from "lucide-react";
import {
	FunctionComponent,
	PropsWithChildren,
	useEffect,
	useState,
} from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { toast } from "sonner";

export const TourImagesRoute: FunctionComponent = () => {
	const { tourId } = Route.useParams();
	const { data: tour } = TourQueries.useTour(tourId);

	const useUpdateTourMutation = TourQueries.useUpdateTourMutation();
	const addImageDialog = useDialogState();
	const { client } = OpenroadClientService.useState();

	const imagesQuery = useQuery({
		initialData: tour?.images ?? [],
		queryKey: ["tours", "images", tour?.id, tour?.images],
		queryFn: () => {
			return tour?.images ?? [];
		},
	});

	const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

	useEffect(() => {
		if (tour != null) {
			setSelectedFiles(tour.images);
		}
	}, [tour]);

	const isDirty = !isEqual(imagesQuery.data, selectedFiles);

	const onSaveChangesPress = async () => {
		if (tour == null) {
			return;
		}

		await useUpdateTourMutation.mutateAsync({
			...tour,
			images: selectedFiles,
		});

		toast.success("Tour images updated");
	};

	if (tour == null) {
		return <Loading />;
	}

	const onDragEnd = (event: DragEndEvent) => {
		const targetIndex = selectedFiles.indexOf(event.active.id as string);
		const destinationIndex = selectedFiles.indexOf(event.over?.id as string);

		if (targetIndex === -1 || destinationIndex === -1) {
			return;
		}

		setSelectedFiles((prev) => {
			const newFiles = [...prev];

			newFiles.splice(targetIndex, 1);
			newFiles.splice(destinationIndex, 0, event.active.id as string);

			return newFiles;
		});
	};

	const onSelectImages = (images: string[]) => {
		setSelectedFiles((prev) => uniq([...prev, ...images]));
		addImageDialog.closeDialog();
	};

	const onRemoveImagePress = (path: string) => {
		setSelectedFiles(selectedFiles.filter((f) => f !== path));
	};

	return (
		<div className={"flex-1 flex flex-col gap-4 relative"}>
			<div className={"flex gap-4 justify-between"}>
				<Dialog {...addImageDialog}>
					<DialogTrigger asChild>
						<Button variant={"secondary"}>Add images</Button>
					</DialogTrigger>
					<DialogContent
						className={"w-full h-full max-h-screen max-w-screen p-0"}
					>
						<FileExplorer
							onSelectPress={onSelectImages}
							onCancel={() => addImageDialog.closeDialog()}
						/>
					</DialogContent>
				</Dialog>
				<div className={"flex gap-4"}>
					<Button
						variant={"secondary"}
						onClick={() => setSelectedFiles(tour.images)}
						disabled={!isDirty}
					>
						Reset
					</Button>
					<Button
						onClick={onSaveChangesPress}
						isLoading={useUpdateTourMutation.isPending}
						disabled={!isDirty || useUpdateTourMutation.isPending}
					>
						Save changes
					</Button>
				</div>
			</div>

			<DndContext onDragEnd={onDragEnd}>
				<div className={"grid grid-cols-3 w-full gap-x-4 gap-y-4"}>
					{selectedFiles.map((path, index) => {
						const imagePath = selectedFiles[index];

						const { publicUrl } = client.supabase.getFile(imagePath);

						return (
							<Droppable key={path} id={path}>
								<Draggable id={imagePath} onClick={() => console.log("remove")}>
									<div
										className={
											"group w-full aspect-square rounded overflow-hidden relative"
										}
									>
										<img
											src={publicUrl}
											alt={publicUrl}
											className={"w-full h-full object-cover"}
										/>

										<div
											className={
												"cursor-pointer absolute top-0 right-0 p-2 bg-gray-800/50 text-white hover:bg-gray-800/80 z-50]"
											}
											onMouseDown={() => {
												onRemoveImagePress(imagePath);
											}}
										>
											<X className={"h-4 w-4"} />
										</div>

										<div
											className={
												"absolute bottom-0 right-0 p-2 bg-gray-800/50 text-white "
											}
										>
											{index + 1}
										</div>
									</div>
								</Draggable>
							</Droppable>
						);
					})}
				</div>
			</DndContext>
		</div>
	);
};

export function Droppable(props: PropsWithChildren<{ id: string }>) {
	const { isOver, setNodeRef } = useDroppable({
		id: props.id,
	});
	const style = {
		opacity: isOver ? 0.5 : 1,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			className={
				"border aspect-square flex justify-center items-center bg-gray-800/10 rounded"
			}
		>
			{props.children}
		</div>
	);
}
function Draggable(
	props: PropsWithChildren<{ id: string; onClick: () => void }>,
) {
	const { id, children } = props;

	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id,
	});
	const style = {
		// Outputs `translate3d(x, y, 0)`
		transform: CSS.Translate.toString(transform),
	};

	return (
		<div ref={setNodeRef} style={style} {...listeners} {...attributes}>
			{children}
		</div>
	);
}
