import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	useDialogContext,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form.tsx";
import { TourNameField } from "@/modules/tours/components/tour-name-field.tsx";
import { TourSlugField } from "@/modules/tours/components/tour-slug-field.tsx";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { slugify } from "@repo/api";
import { useNavigate } from "@tanstack/react-router";
import { FunctionComponent, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type CreateTourDialogProps = PropsWithChildren;

export const CreateTourDialog: FunctionComponent<CreateTourDialogProps> = (
	props,
) => {
	const { children } = props;

	return (
		<Dialog>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Create tour</DialogTitle>
				</DialogHeader>

				<CreateTourDialogContent />
			</DialogContent>
		</Dialog>
	);
};

const formValidation = z.object({
	error: z.string().optional().nullable(),
	name: z.string(),
	slug: z
		.string()
		.regex(
			/^[a-z0-9]+(?:-[a-z0-9]+)*$/,
			"Invalid slug. Must be all lowercase and contain only letters, numbers, and dashes",
		),
});

type FormSchema = z.infer<typeof formValidation>;

export const CreateTourDialogContent: FunctionComponent<CreateTourDialogProps> =
	() => {
		const navigate = useNavigate();

		const dialog = useDialogContext();
		const createTourMutation = TourQueries.useCreateTourMutation();

		const form = useForm<FormSchema>({
			defaultValues: {
				name: "",
				slug: "",
			},
			resolver: zodResolver(formValidation),
		});

		const onSubmit = async ({ name, slug }: FormSchema) => {
			const tour = await createTourMutation.mutateAsync({
				name: name,
				slug: slug,
				highlight: "",
				description: "",
				number_of_days: 3,
				number_of_days_riding: 3,
				images: [],
				type: ["GUIDED"],
				is_popular: false,
				on_sale: false,
			});

			dialog.close();
			toast.success("Tour created");

			await navigate({
				to: "/tours/$tourId",
				params: {
					tourId: tour.id,
				},
			});
		};

		return (
			<Form {...form}>
				<TourNameField
					onChange={(e) => {
						form.setValue("slug", slugify(e.target.value));
					}}
				/>
				<TourSlugField />

				<div className="text-red-500">
					{form.formState.errors?.error?.message}
				</div>
				<Button
					onClick={form.handleSubmit(onSubmit)}
					type="submit"
					isLoading={form.formState.isSubmitting}
				>
					Create tour
				</Button>
			</Form>
		);
	};
