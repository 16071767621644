import { Card } from "@/components/ui/card.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { WaypointMarker } from "@/modules/routes/components/waypoint.marker.tsx";
import { WaypointQueries } from "@/modules/routes/queries/waypoint.queries";
import { MapboxQueries } from "@/queries/mapbox.queries";
import { UpdateRoute, Waypoint } from "@repo/types";
import * as turf from "@turf/turf";
import { LineLayer } from "mapbox-gl";
import { FunctionComponent, useEffect, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";
import MapboxMap, { Layer, MapRef, Marker, Source } from "react-map-gl";
import { toast } from "sonner";

export type RouteMapProps = {
	selectedWaypointIds: string[];
	onChange: (waypoints: string[]) => void;
};

export const RouteMap: FunctionComponent<RouteMapProps> = (props) => {
	const { selectedWaypointIds, onChange } = props;

	const { data: waypoints, isLoading } = WaypointQueries.useWaypoints();
	const map = useRef<MapRef>(null);

	const mappedWaypoints = useMemo(() => {
		if (waypoints == null) {
			return [];
		}

		return waypoints.map((waypoint) => {
			const selectedIndex = selectedWaypointIds.indexOf(waypoint.id);
			const isSelected = selectedIndex !== -1;

			return {
				...waypoint,
				isSelected,
				selectedIndex,
			};
		}) satisfies (Waypoint & {
			isSelected: boolean;
			selectedIndex: number;
		})[];
	}, [selectedWaypointIds, waypoints]);

	const selectedWaypoints =
		mappedWaypoints != null
			? mappedWaypoints
					.filter((waypoint) => waypoint.isSelected)
					.sort((a, b) => {
						return a.selectedIndex - b.selectedIndex;
					})
			: null;

	const collection =
		selectedWaypoints != null && selectedWaypoints.length > 1
			? turf.lineString(
					selectedWaypoints.map((waypoint) => [
						waypoint.longitude,
						waypoint.latitude,
					]),
			  )
			: null;

	const bbox = collection != null ? turf.bbox(collection) : null;
	const createWaypointMutation = WaypointQueries.useCreateWaypoint();

	const createWaypoint = async (point: [number, number]) => {
		await createWaypointMutation.mutateAsync({
			longitude: point[0],
			latitude: point[1],
			name: "",
			images: [],
			highlight: "",
		});
		toast.success("Waypoint created");
	};

	const onWaypointClick = (waypoint: Waypoint) => {
		const index = selectedWaypointIds.indexOf(waypoint.id);

		if (index === -1) {
			onChange([...selectedWaypointIds, waypoint.id]);
		} else {
			onChange(selectedWaypointIds.filter((id) => id !== waypoint.id));
		}
	};

	const form = useFormContext<UpdateRoute>();

	const waypointsDirty = form.formState.dirtyFields.waypoints != null;

	const { data: directionsRoute } = MapboxQueries.useRoute(
		selectedWaypoints?.map((waypoint) => [
			waypoint.longitude,
			waypoint.latitude,
		]) ?? [],
	);

	useEffect(() => {
		if (!waypointsDirty) {
			return;
		}

		if (directionsRoute == null) {
			return;
		}

		form.setValue(
			"geometry",
			JSON.stringify(directionsRoute.routes[0].geometry),
		);
	}, [
		directionsRoute,
		directionsRoute?.routes[0].geometry,
		form,
		waypointsDirty,
	]);

	return (
		<Card className={"overflow-hidden h-[600px] relative flex"}>
			{isLoading && <Skeleton className={"w-full h-full"} />}

			{!isLoading && (
				<div className="relative w-full h-full flex flex-1">
					<MapboxMap
						ref={map}
						mapboxAccessToken={
							"pk.eyJ1IjoidXJiYW5jaHJpc3kiLCJhIjoiY2xzbGo5cnhwMGVoazJqcDY0N3RqeG92OSJ9.C9sIOo45b61JpdvgbMhtVw"
						}
						onDblClick={(e) => createWaypoint([e.lngLat.lng, e.lngLat.lat])}
						// interactiveLayerIds={tourLegsWithRoutes.flatMap((tourLeg) => [
						// 	`route-line-${tourLeg.id}`,
						// 	`route-start-circle-${tourLeg.id}`,
						// 	`route-start-label-${tourLeg.id}`,
						// 	`route-end-circle-${tourLeg.id}`,
						// 	`route-end-label-${tourLeg.id}`,
						// ])}
						initialViewState={
							bbox != null
								? {
										bounds: [bbox[0], bbox[1], bbox[2], bbox[3]],
										fitBoundsOptions: {
											padding: {
												top: 48,
												left: 48,
												right: 48,
												bottom: 48,
											},
										},
								  }
								: {
										longitude: 174.763336,
										latitude: -36.848461,
										zoom: 6,
								  }
						}
						style={{ width: "100%", height: "100%" }}
						doubleClickZoom={false}
						mapStyle="mapbox://styles/urbanchrisy/clssv8s57001y01r5hmvs0x1z"
					>
						<Marker longitude={-122.4376} latitude={37.7577}>
							<div style={{ color: "white" }}>You are here</div>
						</Marker>

						{mappedWaypoints.map((waypoint) => {
							return (
								<WaypointMarker
									key={waypoint.id}
									waypoint={waypoint}
									selectedIndex={waypoint.selectedIndex}
									onClick={onWaypointClick}
								/>
							);
						})}

						{directionsRoute?.routes?.map((route, index) => {
							const layerStyle: LineLayer = {
								source: "route",
								id: `route-line-${index}`,
								type: "line",
								paint: {
									"line-width": 4,
									"line-color": "#13a34a",
								},
							};

							return (
								<Source
									key={layerStyle.id}
									type="geojson"
									data={route.geometry}
								>
									<Layer {...layerStyle} />
								</Source>
							);
						})}
					</MapboxMap>
				</div>
			)}
		</Card>
	);
};
