import { queryClient } from "@/queries";
import { SupabaseService } from "@/services/supabase.service";
import { CreateWaypoint, Waypoint } from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["waypoint"] as const,
};

export const WaypointQueries = {
	QueryKeys,

	useWaypoints: () => {
		return useQuery<Waypoint[]>({
			queryKey: QueryKeys.root(),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("waypoint")
					.select("*")
					.throwOnError();

				return (data ?? []) as Waypoint[];
			},
		});
	},

	useCreateWaypoint: () => {
		return useMutation({
			mutationFn: async (waypoint: CreateWaypoint) => {
				const { data } = await SupabaseService.instance()
					.from("waypoint")
					.insert(waypoint)
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to create waypoint");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},
	useUpdateWaypoint() {
		return useMutation({
			mutationFn: async (waypoint: Waypoint) => {
				const { data } = await SupabaseService.instance()
					.from("waypoint")
					.update(waypoint)
					.eq("id", waypoint.id ?? "")
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to update waypoint");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},
};
