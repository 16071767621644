import { FieldDescription } from "@/components/form/field-description";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { ReactNode } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";

export type SelectFieldProps<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
> = {
	name: TName;
	label: ReactNode;
	description?:
		| ReactNode
		| ((field: ControllerRenderProps<TFieldValues, TName>) => ReactNode);
	children?: ReactNode;
};

export function SelectField<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
>(props: SelectFieldProps<TFieldValues, TName>) {
	const { name, label, description, children } = props;
	return (
		<FormField<TFieldValues, TName>
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Select value={field.value} onValueChange={field.onChange}>
							<SelectTrigger>
								<SelectValue defaultValue={field.value} />
							</SelectTrigger>
							<SelectContent>{children}</SelectContent>
						</Select>
					</FormControl>
					<FieldDescription<TFieldValues, TName> field={field}>
						{description}
					</FieldDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}

export { SelectItem };
