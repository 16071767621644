import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { FunctionComponent } from "react";
import { TourFormSchema } from "./tour-details-form";

export const TourSlugField: FunctionComponent = () => {
	// const [reformatSlug, setReformatSlug] = useState(false);
	//
	// const onCheckedChange = (checked: boolean) => {
	// 	setReformatSlug(checked);
	// }

	return (
		<FormField<TourFormSchema, "slug">
			name="slug"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Slug</FormLabel>
					<FormControl>
						<Input {...field} placeholder="24-day-twin-island-discovery" />
					</FormControl>

					{/*<div className="flex items-center space-x-2">*/}
					{/*	<Checkbox*/}
					{/*		id={field.name}*/}
					{/*		checked={reformatSlug}*/}
					{/*		onCheckedChange={onCheckedChange}*/}
					{/*	/>*/}
					{/*	<label*/}
					{/*		htmlFor={field.name}*/}
					{/*		className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"*/}
					{/*	>*/}
					{/*		Reformat with name change*/}
					{/*	</label>*/}
					{/*</div>*/}
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
