import { Loading } from "@/components/ui/loading.tsx";
import { orderBy } from "lodash";
import { FunctionComponent } from "react";
import { ExplorerFile } from "../services/file-explorer.service";
import { FileObject } from "./file-object.tsx";

export type FileGridProps = {
	files: ExplorerFile[];
	isPending?: boolean;
	selectedFiles?: string[];
	setSelectedFiles?: React.Dispatch<React.SetStateAction<ExplorerFile[]>>;
	onFileClick?: (file: ExplorerFile) => void;
};

export const FileGrid: FunctionComponent<FileGridProps> = (props) => {
	const { isPending, files, selectedFiles, setSelectedFiles, onFileClick } =
		props;
	return (
		<div className={"w-full p-4 flex"}>
			{isPending ? (
				<Loading />
			) : (
				<div className="grid p-4 gap-y-2 gap-x-2 grid-cols-3 md:gap-x-4 md:gap-y-4">
					{orderBy(files, ({ created_at }) => created_at).map((fileObject) => (
						<FileObject
							key={fileObject.id}
							file={fileObject}
							onClick={onFileClick}
							selectedFiles={selectedFiles}
							setSelectedFiles={setSelectedFiles}
						/>
					))}
				</div>
			)}
		</div>
	);
};
