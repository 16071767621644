import {TableCell, TableRow} from "@/components/ui/table.tsx";
import {Region} from "@repo/types";
import {FunctionComponent} from "react";
import {RegionDialog} from "@/modules/regions/dialogs/region.dialog.tsx";

export type RegionTableRowProps = {
	region: Region;
};

export const RegionTableRow: FunctionComponent<RegionTableRowProps> = (
	props,
) => {
	const { region } = props;

	return (
		<RegionDialog region={region}>
			<TableRow
				className="cursor-pointer hover:bg-gray-100/80"
			>
				<TableCell className="font-medium">{region.name}</TableCell>
			</TableRow>
		</RegionDialog>
	);
};
