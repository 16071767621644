import { TextAreaField } from "@/components/form/text-area.field";
import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Separator } from "@/components/ui/separator.tsx";
import { useDialogState } from "@/hooks/use-dialog-state";
import { DeleteFileDialog } from "@/modules/images/dialogs/delete-file.dialog.tsx";
import { ExplorerFile } from "@/modules/images/services/file-explorer.service";
import { queryClient } from "@/queries";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { FunctionComponent, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type UpdateFileDialogProps = PropsWithChildren<{
	fileObject: ExplorerFile;
	onClose?: () => void;
}>;

export const UpdateFileDialog: FunctionComponent<UpdateFileDialogProps> = (
	props,
) => {
	const { children } = props;
	const dialogState = useDialogState();

	return (
		<Dialog {...dialogState}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Update file</DialogTitle>
				</DialogHeader>

				{dialogState.open && (
					<UpdateFileDialogContent
						onClose={dialogState.closeDialog}
						fileObject={props.fileObject}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};

const formValidation = z.object({
	alt_text: z.string(),
});

type FormSchema = z.infer<typeof formValidation>;
export const UpdateFileDialogContent: FunctionComponent<UpdateFileDialogProps> =
	({ onClose, fileObject }) => {
		const form = useForm<FormSchema>({
			defaultValues: fileObject?.metadata ?? {
				name: fileObject.name,
				alt_text: "",
			},
			resolver: zodResolver(formValidation),
		});

		const { client } = OpenroadClientService.useState();
		const imageUrl = client.supabase.getFile(fileObject.fullPath);

		const updateFileMetadataMutation = useMutation({
			mutationFn: async (values: FormSchema) => {
				const { data, error } = await client.supabase
					.storage()
					.from("objects")
					.update({
						metadata: {
							alt_text: values.alt_text,
						},
					})
					.eq("id", fileObject.id);

				if (error) {
					throw new Error(error.message);
				}

				return data;
			},
		});

		const onSubmit = async (values: FormSchema) => {
			console.log("values", values);
			await updateFileMetadataMutation.mutateAsync(values);
			await queryClient.invalidateQueries({
				queryKey: ["folder"],
			});
			toast.success("File updated");
			onClose?.();
		};

		return (
			<Form {...form}>
				<img
					src={imageUrl.publicUrl}
					alt=""
					className="object-cover h-full w-full max-h-96"
				/>
				<TextAreaField
					label="Alt text"
					name="alt_text"
					placeholder="Describe the image to improve accessibility and SEO"
				/>

				<DeleteFileDialog fileObject={fileObject}>
					<Button variant={"secondary"}>
						<Trash className={"h-4 w-4 mr-2"} />
						Delete file
					</Button>
				</DeleteFileDialog>
				<Separator />
				<Button
					onClick={form.handleSubmit(onSubmit)}
					type="submit"
					isLoading={form.formState.isSubmitting}
				>
					Update file
				</Button>
			</Form>
		);
	};
