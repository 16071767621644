import { FunctionComponent, PropsWithChildren } from "react";
import { PortalService } from "../portal.service";

export type PortalContainerProps = PropsWithChildren;

export const PortalContainer: FunctionComponent<PortalContainerProps> = (
	props,
) => {
	const { children } = props;

	const providedState = PortalService.useProvidedState();

	return (
		<PortalService.Provider value={providedState}>
			{children}
		</PortalService.Provider>
	);
};
