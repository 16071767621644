import { Toaster } from "@/components/ui/sonner";
import { AuthContainer } from "@/modules/auth/containers/auth.container";
import { PortalContainer } from "@/packages/portal";
import { queryClient } from "@/queries";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { FunctionComponent } from "react";
import { OpenroadClientContainer } from "./containers";
import { routeTree } from "./route-tree";

const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

export const Application: FunctionComponent = () => {
	return (
		<OpenroadClientContainer queryClient={queryClient}>
			<PortalContainer>
				<AuthContainer>
					<RouterProvider router={router} />
				</AuthContainer>
			</PortalContainer>
			<Toaster />
		</OpenroadClientContainer>
	);
};
