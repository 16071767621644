import { Button } from "@/components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table.tsx";
import { StarRating } from "@/modules/reviews/components/star-rating.tsx";
import { CreateReviewDialog } from "@/modules/reviews/dialogs/create-review.dialog.tsx";
import { ReviewQueries } from "@/modules/reviews/queries/review.queries";
import { TourSelector } from "@/modules/tours/components/tour-selector";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Tour } from "@repo/types";
import { FunctionComponent, useMemo, useState } from "react";
import { UpdateReviewDialog } from "../dialogs/update-review.dialog";

export type ReviewsRouteProps = {};

export const ReviewsRoute: FunctionComponent<ReviewsRouteProps> = (props) => {
	const {} = props;

	const { data: tours } = TourQueries.useTours();
	const { data: reviews } = ReviewQueries.useReviews();

	const [selectedTourId, setSelectedTourId] = useState<string | null>(null);

	const onTourSelectChange = (selectedTour: Tour | null) => {
		setSelectedTourId(selectedTour?.id ?? null);
	};

	const filteredReviews = useMemo(() => {
		return reviews?.filter((review) => {
			if (selectedTourId == null) {
				return true;
			}

			return review.tour_id === selectedTourId;
		});
	}, [reviews, selectedTourId]);

	return (
		<div className={"w-full p-8 flex flex-col gap-4"}>
			<div className={"w-full flex gap-4"}>
				<div className={"max-w-sm w-full"}>
					<TourSelector
						selectedTourId={selectedTourId}
						onTourSelect={onTourSelectChange}
						allowAllTours
					/>
				</div>

				<CreateReviewDialog>
					<Button>Create review</Button>
				</CreateReviewDialog>
			</div>

			<Table>
				<TableHeader>
					<TableRow>
						<TableHead className="w-[300px]">Name</TableHead>
						<TableHead className="w-[300px]">Tour</TableHead>
						<TableHead>Rating</TableHead>
						<TableHead>Review</TableHead>
						<TableHead>Date</TableHead>
						<TableHead />
					</TableRow>
				</TableHeader>
				<TableBody>
					{filteredReviews?.map((review) => {
						const tour = tours?.find((tour) => tour.id === review.tour_id);

						return (
							<TableRow key={review.id} className={"cursor-pointer"}>
								{/*		<div className={"w-full"}>*/}
								<TableCell className="font-medium">{review.name}</TableCell>
								<TableCell className="font-medium">
									{tour?.name ?? "No tour"}
								</TableCell>
								<TableCell>
									<StarRating rating={review.rating} />
								</TableCell>
								{/*<TableCell className="text-truncate w-[300px]">*/}
								{/*	{review.text}*/}
								{/*</TableCell>*/}
								<TableCell>{review.date}</TableCell>
								<TableCell>
									<UpdateReviewDialog key={review.id} review={review}>
										<Button variant={"default"}>Edit Review</Button>
									</UpdateReviewDialog>
								</TableCell>
								{/*<TableCell>{invoice.paymentMethod}</TableCell>*/}
								{/*<TableCell className="text-right">{invoice.totalAmount}</TableCell>*/}
								{/*</div>*/}
								{/*</UpdateReviewDialog>*/}
							</TableRow>
						);
					})}
				</TableBody>
				{/*<TableFooter>*/}
				{/*	<TableRow>*/}
				{/*		<TableCell colSpan={3}>Total</TableCell>*/}
				{/*		<TableCell className="text-right">$2,500.00</TableCell>*/}
				{/*	</TableRow>*/}
				{/*</TableFooter>*/}
			</Table>
		</div>
	);
};
