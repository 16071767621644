import { SelectField } from "@/components/form/select.field.tsx";
import { SelectItem } from "@/components/ui/select.tsx";
import { FunctionComponent } from "react";

export const TourStatusField: FunctionComponent = () => {
	return (
		<SelectField
			name={"status"}
			label={"Status"}
			description={(field) => (
				<>
					{field.value === "INACTIVE" && "Tour is not visible to customers"}
					{field.value === "ACTIVE" && "Tour is visible to customers"}
					{field.value === "DELETED" &&
						"Tour is deleted and not visible to customers"}
				</>
			)}
		>
			<SelectItem value="INACTIVE">Inactive</SelectItem>
			<SelectItem value="ACTIVE">Active</SelectItem>
			<SelectItem value="DELETED">Deleted</SelectItem>
		</SelectField>
	);
};
