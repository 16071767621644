import { Application } from "@/application";
import React from "react";
import ReactDOM from "react-dom/client";
import "./globals.css";

const root = document.getElementById("root");

if (root != null) {
	ReactDOM.createRoot(root).render(
		<React.StrictMode>
			<Application />
		</React.StrictMode>,
	);
}
