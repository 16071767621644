import { FunctionComponent, PropsWithChildren } from "react";
import { FileExplorerService } from "../services/file-explorer.service";

export type FileExplorerContainerProps = PropsWithChildren;

export const FileExplorerContainer: FunctionComponent<
	FileExplorerContainerProps
> = (props) => {
	const { children } = props;

	const providedState = FileExplorerService.useProvidedState();

	return (
		<FileExplorerService.Provider value={providedState}>
			{children}
		</FileExplorerService.Provider>
	);
};
