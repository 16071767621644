import { Login } from "@/modules/auth/login";
import { FunctionComponent } from "react";

export type AuthProps = {};

export const Auth: FunctionComponent<AuthProps> = (props) => {
	const {} = props;
	return (
		<div className="flex-1 flex flex-col items-center justify-center bg-background">
			<Login />
		</div>
	);
};
