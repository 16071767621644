import { Search } from "lucide-react";
import { FunctionComponent } from "react";
import { RegionsTable } from "../components/regions-table";

export type RegionsRouteProps = {};

export const RegionsRoute: FunctionComponent<RegionsRouteProps> = (props) => {
	const {} = props;
	return (
		<>
			<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
				<div className="relative ml-auto flex-1 md:grow-0">
					<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
				</div>
			</header>

			<RegionsTable />
		</>
	);
};
