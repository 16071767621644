import { Database } from "@repo/types";
import { TransformOptions } from "@supabase/storage-js/src/lib/types";
import { SupabaseClient } from "@supabase/supabase-js";
import * as Supabase from "@supabase/supabase-js";
import { ConfigService, SupabaseConfig } from "./config.service";

export type FileBody =
	| ArrayBuffer
	| ArrayBufferView
	| Blob
	| Buffer
	| File
	| FormData
	| NodeJS.ReadableStream
	| ReadableStream<Uint8Array>
	| URLSearchParams
	| string;

export type Metadata = {
	name: string;
	alt_text: string;
};

export class SupabaseService {
	private _supabaseConfig: SupabaseConfig;
	private _instance: SupabaseClient<Database> | null = null;
	private _storage_instance: SupabaseClient<Database, "storage"> | null = null;

	constructor(config: ConfigService) {
		this._supabaseConfig = config.getSupabaseConfig();
	}

	instance() {
		if (this._instance == null) {
			this._instance = Supabase.createClient<Database>(
				this._supabaseConfig.supabaseUrl,
				this._supabaseConfig.supabaseKey,
			);
		}

		return this._instance;
	}

	storage() {
		if (this._storage_instance == null) {
			this._storage_instance = Supabase.createClient<Database, "storage">(
				this._supabaseConfig.supabaseUrl,
				this._supabaseConfig.supabaseKey,
				{
					db: {
						schema: "storage",
					},
				},
			);
		}

		return this._storage_instance;
	}

	upload(path: string, file: FileBody) {
		return this.instance().storage.from("global").upload(path, file);
	}

	getFile(
		path: string,
		options?: { download?: string | boolean; transform?: TransformOptions },
	) {
		const { data } = this.instance()
			.storage.from("global")
			.getPublicUrl(path, {
				...options,
				transform: {
					...options?.transform,
					quality: options?.transform?.quality ?? 40,
				},
			});

		return data;
	}

	async getMetadata(path: string): Promise<
		| { metadata: Metadata; error?: never }
		| {
				metadata?: never;
				error: Supabase.PostgrestError;
		  }
	> {
		const { data, error } = await this.storage()
			.from("objects")
			.select("metadata")
			.eq("name", path)
			.single();

		if (error != null) {
			return { error: error as Supabase.PostgrestError };
		}

		return {
			metadata: data.metadata as Metadata,
		};
	}
}
