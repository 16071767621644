"use client";

import { createContext, useContext, useMemo } from "react";
import { OpenroadClient } from "../openroad.client";

export type OpenroadClientServiceContextType = {
	client: OpenroadClient;
};

const Context = createContext<OpenroadClientServiceContextType | null>(null);

export const OpenroadClientService = {
	Context,
	Provider: Context.Provider,

	useState() {
		const state = useContext(Context);
		if (state == null) {
			throw new Error("OpenroadClientService not found");
		}
		return state;
	},

	useProvidedState(): OpenroadClientServiceContextType {
		const client = useMemo(() => new OpenroadClient(), []);

		return {
			client,
		};
	},
};
