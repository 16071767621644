import { SupabaseService } from "@/services/supabase.service";
import { User } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";

export type AuthServiceContextType = {
	user: User | null;
	isLoading: boolean;
	isAuthenticated: boolean;
};

const Context = createContext<AuthServiceContextType | null>(null);

const QueryKeys = {
	user: ["auth", "user"],
} as const;

export const AuthService = {
	QueryKeys,
	Context,
	Provider: Context.Provider,

	useState() {
		const state = useContext(Context);
		if (state == null) {
			throw new Error("AuthService not found");
		}
		return state;
	},

	useProvidedState(): AuthServiceContextType {
		const { data: user } = useQuery({
			queryKey: QueryKeys.user,
			queryFn: async () => {
				const { data } = await SupabaseService.instance().auth.getUser();
				return data.user;
			},
		});

		const isAuthenticated = !!user;

		return {
			user: user ?? null,
			isLoading: user === undefined,
			isAuthenticated,
		};
	},
};
