import { Loading } from "@/components/ui/loading.tsx";
import { FunctionComponent, PropsWithChildren } from "react";
import { AuthService } from "../services/auth.service";

export type AuthContainerProps = PropsWithChildren;

export const AuthContainer: FunctionComponent<AuthContainerProps> = (props) => {
	const { children } = props;

	const providedState = AuthService.useProvidedState();

	if (providedState.isLoading) {
		return <Loading />;
	}

	return (
		<AuthService.Provider value={providedState}>
			{children}
		</AuthService.Provider>
	);
};
