import {
	Dialog,
	DialogContent,
	DialogProps,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form.tsx";
import { ReactNode } from "react";
import {
	FieldValues,
	UseFormProps,
	UseFormReturn,
	useForm,
} from "react-hook-form";
import { cn } from "../../lib/utils";

export type FormDialogProps<TFieldValues extends FieldValues> = {
	className?: string;
	trigger?: ReactNode;
} & Omit<DialogProps, "children"> &
	FormContentProps<TFieldValues>;

export function FormDialog<TFieldValues extends FieldValues>(
	props: FormDialogProps<TFieldValues>,
) {
	const { className, form, children, trigger, ...otherProps } = props;

	return (
		<Dialog {...otherProps}>
			{trigger != null && (
				<DialogTrigger asChild type={undefined}>
					{trigger}
				</DialogTrigger>
			)}
			<DialogContent className={cn(className, "")}>
				<FormDialogContent form={form}>{children}</FormDialogContent>
			</DialogContent>
		</Dialog>
	);
}

export type FormContentProps<TFieldValues extends FieldValues> = {
	form: UseFormProps<TFieldValues>;
	children: (form: UseFormReturn<TFieldValues>) => ReactNode;
};

function FormDialogContent<TFieldValues extends FieldValues>(
	props: FormContentProps<TFieldValues>,
) {
	const { form: options, children } = props;

	const form = useForm<TFieldValues>(options);

	return <Form {...form}>{children(form)}</Form>;
}
