"use client";

import { Button } from "@/components/ui/button.tsx";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { TourDateQueries } from "@/modules/tours/queries/tour-date.queries";
import { Bike, BikeTourDatePrice, TourDate } from "@repo/types";
import { ColumnDef } from "@tanstack/react-table";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export const columns: ColumnDef<Bike & { tourDate: TourDate }>[] = [
	{
		id: "bike",
		header: "Bike",
		cell: ({ row }) => {
			return <BikeCell bike={row.original} />;
		},
	},
	{
		accessorKey: "amount",
		header: "",
		cell: (row) => {
			return <BikeTourDatePriceCell bike={row.row.original} />;
		},
	},
];

const BikeCell: FunctionComponent<{ bike: Bike }> = (props) => {
	const { bike } = props;

	return <>{bike.name}</>;
};

const formValidation = z.object({
	error: z.string().optional().nullable(),
	amount: z.number().int().positive(),
	bikes_available: z.number().int().positive(),
});

export type FormSchema = z.infer<typeof formValidation>;

const BikeTourDatePriceCell: FunctionComponent<{
	bike: Bike & { tourDate: TourDate };
}> = (props) => {
	const { bike } = props;

	const { data, isPending } = TourDateQueries.useBikeTourDatePrice(
		bike.tourDate.id,
		bike.id,
	);

	if (isPending) {
		return <Skeleton className={"w-32"} />;
	}

	return <BikeTourDatePriceForm bike={bike} bikeTourDatePrice={data ?? null} />;
};

const BikeTourDatePriceForm: FunctionComponent<{
	bike: Bike & { tourDate: TourDate };
	bikeTourDatePrice: BikeTourDatePrice | null;
}> = (props) => {
	const { bike, bikeTourDatePrice } = props;

	const form = useForm<FormSchema>({
		defaultValues: {
			amount: bikeTourDatePrice?.amount ?? 0,
			bikes_available: bikeTourDatePrice?.bikes_available ?? 0,
		},
	});

	const createBikeTourDatePriceMutation =
		TourDateQueries.useCreateBikeTourDatePriceMutation();
	const updateBikeTourDatePriceMutation =
		TourDateQueries.useUpdateBikeTourDatePriceMutation();

	const onSubmit = async (values: FormSchema) => {
		let bikeTourDatePriceResp: BikeTourDatePrice;

		if (bikeTourDatePrice != null) {
			bikeTourDatePriceResp = await updateBikeTourDatePriceMutation.mutateAsync(
				{
					id: bikeTourDatePrice.id,
					amount: values.amount,
					bikes_available: values.bikes_available,
				},
			);
		} else {
			bikeTourDatePriceResp = await createBikeTourDatePriceMutation.mutateAsync(
				{
					bike_id: bike.id,
					tour_date_id: bike.tourDate.id,
					amount: values.amount,
					bikes_available: values.bikes_available,
				},
			);
		}
		form.reset(bikeTourDatePriceResp);
		toast.success("Saved");
	};

	const isPending =
		createBikeTourDatePriceMutation.isPending ||
		updateBikeTourDatePriceMutation.isPending;

	return (
		<Form {...form}>
			<div className={"flex flex-row gap-4 w-full justify-end items-end"}>
				<FormField
					name="amount"
					render={({ field }) => (
						<FormItem className={"w-24"}>
							<FormLabel className={"block text-xs font-medium text-gray-400"}>
								Amount
							</FormLabel>
							<FormControl>
								<Input
									{...field}
									// className={"min-h-[200px] w-full"}
									placeholder="2568"
								/>
							</FormControl>
							<FormDescription />
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					name="bikes_available"
					render={({ field }) => (
						<FormItem>
							<FormLabel className={"block text-xs font-medium text-gray-400"}>
								Bikes Available
							</FormLabel>
							<FormControl>
								<Input
									{...field}
									// className={"min-h-[200px] w-full"}
									placeholder="2568"
								/>
							</FormControl>
							<FormDescription />
							<FormMessage />
						</FormItem>
					)}
				/>

				<Button
					className={"mb-2"}
					size={"sm"}
					disabled={isPending || !form.formState.isDirty}
					isLoading={isPending}
					onClick={form.handleSubmit(onSubmit)}
				>
					Save
				</Button>
			</div>
		</Form>
	);
};
