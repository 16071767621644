import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input, InputProps } from "@/components/ui/input";
import { FunctionComponent } from "react";
import { BikeFormSchema } from "./bike-details-form";

export type TourNameFieldProps = {
	onChange?: InputProps["onChange"];
};

export const BikeNameField: FunctionComponent<TourNameFieldProps> = (props) => {
	const { onChange } = props;

	return (
		<FormField<BikeFormSchema, "name">
			name="name"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Bike name</FormLabel>
					<FormControl>
						<Input
							{...field}
							placeholder="Triumph Tiger 800XR"
							onChange={(e) => {
								field.onChange(e);
								onChange?.(e);
							}}
						/>
					</FormControl>
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
