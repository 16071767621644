import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form.tsx";
import { useDialogState } from "@/hooks/use-dialog-state";
import { FileExplorerService } from "@/modules/images/services/file-explorer.service";
import { queryClient } from "@/queries";
import {
	OpenroadClientService,
	isValidSlug,
} from "@/services/openroad-client.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { FunctionComponent, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import { PathField } from "../components/path-field.tsx";

export type CreateFolderDialogProps = PropsWithChildren<{
	onClose?: () => void;
}>;

export const CreateFolderDialog: FunctionComponent<CreateFolderDialogProps> = (
	props,
) => {
	const { children } = props;
	const dialogState = useDialogState();

	return (
		<Dialog {...dialogState}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Create folder</DialogTitle>
				</DialogHeader>

				{dialogState.open && (
					<CreateFolderContent onClose={dialogState.closeDialog} />
				)}
			</DialogContent>
		</Dialog>
	);
};

const formValidation = z.object({
	path: z.string().refine(isValidSlug, "Invalid folder name"),
});

type FormSchema = z.infer<typeof formValidation>;

export const CreateFolderContent: FunctionComponent<CreateFolderDialogProps> =
	({ onClose }) => {
		const form = useForm<FormSchema>({
			defaultValues: {
				path: "",
			},
			resolver: zodResolver(formValidation),
		});

		const { folderStack } = FileExplorerService.useState();
		const { client } = OpenroadClientService.useState();

		const createFolderMutation = useMutation({
			mutationFn: async (path: string) => {
				const fullPath = `${folderStack.join("/").substring(1)}/${path}`;
				await client.supabase.upload(`${fullPath}/.emptyFolderPlaceholder`, "");
			},
		});

		const onSubmit = async ({ path }: FormSchema) => {
			await createFolderMutation.mutateAsync(path);
			await queryClient.invalidateQueries({
				queryKey: ["folder"],
			});

			toast.success("Folder created");
			onClose?.();
		};

		return (
			<Form {...form}>
				<PathField />
				<Button
					onClick={form.handleSubmit(onSubmit)}
					type="submit"
					isLoading={form.formState.isSubmitting}
				>
					Create folder
				</Button>
			</Form>
		);
	};
