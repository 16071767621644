import { cn } from "@/lib/utils";
import { Waypoint } from "@repo/types";
import { CircleDot } from "lucide-react";
import { FunctionComponent } from "react";
import { Marker } from "react-map-gl";

export type WaypointMarkerProps = {
	waypoint: Waypoint;
	selectedIndex?: number;
	onClick?: (waypoint: Waypoint, selectedIndex: number) => void;
};

export const WaypointMarker: FunctionComponent<WaypointMarkerProps> = (
	props,
) => {
	const { waypoint, selectedIndex = -1, onClick } = props;

	const isSelected = selectedIndex !== -1;

	return (
		<Marker
			longitude={waypoint.longitude}
			latitude={waypoint.latitude}
			anchor={"center"}
			onClick={() => onClick?.(waypoint, selectedIndex)}
		>
			<div className={"group relative flex items-center justify-center"}>
				<CircleDot
					className={cn(
						"w-8 h-8 text-primary fill-white cursor-pointer",
						"group-hover:fill-gray-400 transition-colors duration-200",
						isSelected
							? "fill-gray-800 group-hover:fill-gray-600"
							: "fill-white",
					)}
				/>

				{isSelected && (
					<div
						className={
							"absolute -top-4 p-2 align-center -translate-y-3/4 bg-white rounded-md shadow-md self-center"
						}
					>
						<span
							className={cn("text-primary font-semibold text-md text-gray-800")}
						>
							{selectedIndex + 1}
						</span>
					</div>
				)}
			</div>
		</Marker>
	);
};
