"use client";

import { FileDropzone } from "@/modules/images/components/file-dropzone";
import { FileExplorerFooter } from "@/modules/images/components/file-explorer-footer";
import { FileExplorerToolbar } from "@/modules/images/components/file-explorer-toolbar";
import { FileViewer } from "@/modules/images/components/file-viewer";
import { FolderPicker } from "@/modules/images/components/folder-picker";
import { FileExplorerContainer } from "@/modules/images/containers/file-explorer.container";
import { FunctionComponent } from "react";

export type FileExplorerProps = {
	onSelectPress?: (images: string[]) => void;
	onCancel?: () => void;
};

export const FileExplorer: FunctionComponent<FileExplorerProps> = ({
	onSelectPress,
	onCancel,
}) => {
	return (
		<FileExplorerContainer>
			<div className="flex-1 flex max-h-screen">
				{/*Sidebar*/}
				<div
					className={
						"static flex-1 w-full h-full max-w-sm gap-2 flex flex-col p-4"
					}
				>
					<div className={"flex-1"}>
						<FolderPicker />
					</div>
					<FileExplorerToolbar />
					<FileDropzone />
				</div>
				<div className={"flex-1 flex flex-col gap-4"}>
					<div className={"flex flex-col flex-1 overflow-auto"}>
						{/*<SelectedFileGrid />*/}
						{/*<div className={"w-full h-px bg-gray-200"} />*/}
						<FileViewer />
						{/*<SelectedFiles />*/}
					</div>
					<FileExplorerFooter
						onCancel={onCancel}
						onSelectPress={onSelectPress}
					/>
				</div>
			</div>
		</FileExplorerContainer>
	);
};

// const SelectedFiles: FunctionComponent = () => {
// 	const { selectedFiles } = FileExplorerService.useState();
// 	return (
// 		<div className={"absolute left-0"}>
// 			<Card>
// 				<CardHeader>
// 					<CardTitle>Selected file: {selectedFiles.length}</CardTitle>
// 				</CardHeader>
// 			</Card>
// 		</div>
// 	);
// };
