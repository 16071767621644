import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input, InputProps } from "@/components/ui/input.tsx";
import { FunctionComponent } from "react";
import { TourFormSchema } from "./tour-details-form";

export type TourNameFieldProps = {
	onChange?: InputProps["onChange"];
};

export const TourNameField: FunctionComponent<TourNameFieldProps> = (props) => {
	const { onChange } = props;

	return (
		<FormField<TourFormSchema, "name">
			name="name"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Tour name</FormLabel>
					<FormControl>
						<Input
							{...field}
							placeholder="24 Day Twin Island Discovery"
							onChange={(e) => {
								field.onChange(e);
								onChange?.(e);
							}}
						/>
					</FormControl>
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
