import { queryClient } from "@/queries";
import { SupabaseService } from "@/services/supabase.service";
import {
	CreateBikeTourDatePrice,
	CreateTourDate,
	UpdateBikeTourDatePrice,
	UpdateTourDate,
} from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { format } from "date-fns";

export const QueryKeys = {
	root: () => ["tour-date"] as const,
	byTourId: (tourId: string) => [...QueryKeys.root(), { tourId }] as const,
	byId: (tourDateId: string) => [...QueryKeys.root(), { tourDateId }] as const,
	byTourDateId: (tourDateId: string) =>
		[...QueryKeys.root(), { tourDateId }] as const,
	byTourDateIdAndBikeId: (tourDateId: string, bikeId: string) =>
		[...QueryKeys.root(), { tourDateId, bikeId }] as const,
};

export const TourDateQueries = {
	QueryKeys,

	useTourDatesByTourId: (tourId: string) => {
		return useQuery({
			queryKey: QueryKeys.byTourId(tourId),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("tour_date")
					.select("*")
					.eq("tour_id", tourId)
					.neq("status", "DELETED")
					.gte("finish", format(new Date(), "yyyy-MM-dd"))
					.select("*")
					.throwOnError();

				if (data == null) {
					throw new Error("Failed to get tour dates");
				}

				return data;
			},
		});
	},

	useTourDateById: (tourDateId: string) => {
		return useQuery({
			queryKey: QueryKeys.byId(tourDateId),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("tour_date")
					.select("*")
					.eq("id", tourDateId)
					.single()
					.throwOnError();

				if (data == null) {
					throw new Error("Failed to get tour date");
				}

				return data;
			},
		});
	},

	useCreateTourDateMutation: () => {
		return useMutation({
			mutationFn: async (tourDate: CreateTourDate) => {
				const { data } = await SupabaseService.instance()
					.from("tour_date")
					.insert(tourDate)
					.select("*")
					.single()
					.throwOnError();

				if (data == null) {
					throw new Error("Failed to create tour date");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useUpdateTourDateMutation: () => {
		return useMutation({
			mutationFn: async (tourDate: UpdateTourDate & { id: string }) => {
				const { data } = await SupabaseService.instance()
					.from("tour_date")
					.update(tourDate)
					.eq("id", tourDate.id)
					.select("*")
					.single()
					.throwOnError();

				if (data == null) {
					throw new Error("Failed to update tour date");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useCreateBikeTourDatePriceMutation: () => {
		return useMutation({
			mutationFn: async (createOrUpdate: CreateBikeTourDatePrice) => {
				const { data } = await SupabaseService.instance()
					.from("bike_tour_date_price")
					.insert(createOrUpdate)
					.throwOnError()
					.select("*")
					.single();

				if (data == null) {
					throw new Error("Failed to create or update bike tour date price");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useUpdateBikeTourDatePriceMutation: () => {
		return useMutation({
			mutationFn: async (
				createOrUpdate: UpdateBikeTourDatePrice & { id: string },
			) => {
				const { data } = await SupabaseService.instance()
					.from("bike_tour_date_price")
					.update(createOrUpdate)
					.eq("id", createOrUpdate.id)
					.throwOnError()
					.select("*")
					.single();

				if (data == null) {
					throw new Error("Failed to create or update bike tour date price");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useBikeTourDatePrice: (tourDateId: string, bikeId: string) => {
		return useQuery({
			queryKey: QueryKeys.byTourDateIdAndBikeId(tourDateId, bikeId),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("bike_tour_date_price")
					.select("*")
					.eq("tour_date_id", tourDateId)
					.eq("bike_id", bikeId)
					.single();

				return data ?? null;
			},
		});
	},
};
