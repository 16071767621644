import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading.tsx";
import { Textarea } from "@/components/ui/textarea";
import { RouteMap } from "@/modules/routes/components/route-map.tsx";
import { RouteQueries } from "@/modules/routes/queries/route.queries";
import { Route } from "@/routes/routes/$routeId/index.tsx";
import { Route as IRoute, UpdateRoute } from "@repo/types";
import { Link } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import "mapbox-gl/dist/mapbox-gl.css";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

export function RouteRoute() {
	const { routeId } = Route.useParams();
	const { data: route } = RouteQueries.useRoute(routeId);

	if (route == null) {
		return <Loading />;
	}

	return <RouteContent route={route} />;
}

const RouteContent: FunctionComponent<{ route: IRoute }> = ({ route }) => {
	const form = useForm<UpdateRoute>({
		defaultValues: {
			...route,
		},
	});

	const updateRoute = RouteQueries.useUpdateRoute();

	const onSubmit = form.handleSubmit(async (data) => {
		const updatedRoute = await updateRoute.mutateAsync(data);
		toast.success("Route updated");
		form.reset(updatedRoute);
	});

	return (
		<div className="flex min-h-screen w-full flex-col">
			<Form {...form}>
				<div className="flex flex-col sm:gap-4 sm:py-4">
					<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
						<Breadcrumb className="hidden md:flex">
							<BreadcrumbList>
								<BreadcrumbItem>
									<BreadcrumbLink asChild>
										<Link to={"/routes"}>Routes</Link>
									</BreadcrumbLink>
								</BreadcrumbItem>
								<BreadcrumbSeparator />
								<BreadcrumbItem>
									<BreadcrumbLink asChild>
										<Link href="#" params={{}}>
											{route.name}
										</Link>
									</BreadcrumbLink>
								</BreadcrumbItem>
							</BreadcrumbList>
						</Breadcrumb>
					</header>
					<main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
						<div className="mx-auto grid max-w-7xl flex-1 auto-rows-max gap-4 w-full">
							<div className="sticky top-16 z-10 flex items-center gap-4 bg-white py-4">
								<Button
									variant="outline"
									size="icon"
									className="h-7 w-7"
									onClick={() => history.back()}
								>
									<ChevronLeft className="h-4 w-4" />
									<span className="sr-only">Back</span>
								</Button>
								<h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
									{route?.name}
								</h1>
								{/*{route && (*/}
								{/*	<Badge variant="outline" className="ml-auto sm:ml-0">*/}
								{/*		{getBadgeVariant(route.)}*/}
								{/*	</Badge>*/}
								{/*)}*/}
								<div className="items-center gap-2 md:ml-auto md:flex">
									<Button
										size="sm"
										onClick={onSubmit}
										disabled={
											!form.formState.isDirty || form.formState.isSubmitting
										}
										isLoading={form.formState.isSubmitting}
									>
										Save route
									</Button>
								</div>
							</div>
							<div className="grid gap-4 lg:gap-8">
								<div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
									<Card>
										<CardHeader>
											<CardTitle>Route Details</CardTitle>
											<CardDescription>{route.highlight}</CardDescription>
										</CardHeader>
										<CardContent>
											<div className="grid gap-6 grid-cols-3">
												<div className="grid gap-3 col-span-2">
													<FormField
														name="name"
														control={form.control}
														render={({ field }) => (
															<FormItem>
																<FormLabel>Route name</FormLabel>
																<FormControl>
																	<Input
																		{...field}
																		placeholder="Auckland - Taupo"
																	/>
																</FormControl>
																<FormDescription />
																<FormMessage />
															</FormItem>
														)}
													/>
												</div>
												<div className="grid gap-3 grid-cols-2 col-span-2">
													<FormField
														name="from"
														control={form.control}
														render={({ field }) => (
															<FormItem className={"grid-cols-1"}>
																<FormLabel>From</FormLabel>
																<FormControl>
																	<Input {...field} placeholder="Auckland" />
																</FormControl>
																<FormDescription />
																<FormMessage />
															</FormItem>
														)}
													/>
													<FormField
														name="to"
														control={form.control}
														render={({ field }) => (
															<FormItem>
																<FormLabel>To</FormLabel>
																<FormControl>
																	<Input {...field} placeholder="Taupo" />
																</FormControl>
																<FormDescription />
																<FormMessage />
															</FormItem>
														)}
													/>
												</div>

												<div className="grid gap-3 col-span-2">
													<FormField
														control={form.control}
														name="highlight"
														render={({ field }) => (
															<FormItem>
																<FormLabel>Highlight</FormLabel>
																<FormControl>
																	<Textarea
																		{...field}
																		className="min-h-32"
																		placeholder="....."
																	/>
																</FormControl>
																<FormDescription />
																<FormMessage />
															</FormItem>
														)}
													/>
												</div>
												<div className="grid gap-3 col-span-2">
													<FormField
														control={form.control}
														name="description"
														render={({ field }) => (
															<FormItem>
																<FormLabel>Description</FormLabel>
																<FormControl>
																	<Textarea
																		{...field}
																		className="min-h-32"
																		placeholder="....."
																	/>
																</FormControl>
																<FormDescription />
																<FormMessage />
															</FormItem>
														)}
													/>
												</div>
											</div>
										</CardContent>
									</Card>
									<FormField<UpdateRoute>
										control={form.control}
										name="waypoints"
										render={({ field }) => (
											<RouteMap
												selectedWaypointIds={field.value as string[]}
												onChange={field.onChange}
											/>
										)}
									/>
								</div>
							</div>
							{/*<div className="flex items-center justify-center gap-2 md:hidden">*/}
							{/*	<Button variant="outline" size="sm">*/}
							{/*		Discard*/}
							{/*	</Button>*/}
							{/*	<Button size="sm">Save Product</Button>*/}
							{/*</div>*/}
						</div>
					</main>
				</div>
			</Form>
		</div>
	);
};
