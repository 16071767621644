import {FunctionComponent, PropsWithChildren} from "react";
import {useDroppable} from "@dnd-kit/core";

export type DroppableProps = PropsWithChildren<{
    id: string;
}>;

export const Droppable: FunctionComponent<DroppableProps> = (props) => {
    const {id, children} = props;
    const { isOver, setNodeRef } = useDroppable({
        id: id,
    });
    const style = {
        opacity: isOver ? 0.5 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={
                "border aspect-square flex justify-center items-center bg-gray-800/10 rounded"
            }
        >
            {children}
        </div>
    );
};
