import {FunctionComponent, PropsWithChildren} from "react";
import {useDraggable} from "@dnd-kit/core";
import {CSS} from "@dnd-kit/utilities";

export type DraggableProps = PropsWithChildren<{ id: string; onClick: () => void }>;

export const Draggable: FunctionComponent<DraggableProps> = (props) => {
    const { id, children } = props;

    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id,
    });
    const style = {
        // Outputs `translate3d(x, y, 0)`
        transform: CSS.Translate.toString(transform),
    };

    return (
        <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {children}
        </div>
    );
};
