import { Bike, CreateBike, UpdateBike } from "@repo/types";
import { SupabaseService } from "./supabase.service";

export class BikeService {
	private supabase: SupabaseService;

	constructor(supabase: SupabaseService) {
		this.supabase = supabase;
	}

	async getBikes(): Promise<Bike[]> {
		const { data } = await this.supabase
			.instance()
			.from("bike")
			.select("*")
			.throwOnError();
		return data ?? [];
	}

	async getBikeById(id: string): Promise<Bike> {
		const { data } = await this.supabase
			.instance()
			.from("bike")
			.select("*")
			.eq("id", id)
			.single()
			.throwOnError();

		if (data == null) {
			throw new Error("Bike not found");
		}

		return data;
	}

	async getBikeBySlug(slug: string): Promise<Bike> {
		const { data } = await this.supabase
			.instance()
			.from("bike")
			.select("*")
			.eq("slug", slug)
			.single()
			.throwOnError();

		if (data == null) {
			throw new Error("Bike not found");
		}

		return data;
	}

	async createBike(createBike: CreateBike): Promise<Bike> {
		const { data } = await this.supabase
			.instance()
			.from("bike")
			.insert(createBike)
			.select("*")
			.single()
			.throwOnError();

		if (data == null) {
			throw new Error("Failed to create bike");
		}

		return data;
	}

	async updateBike(updateBike: UpdateBike & { id: string }): Promise<Bike> {
		const { data } = await this.supabase
			.instance()
			.from("bike")
			.update(updateBike)
			.eq("id", updateBike.id)
			.select("*")
			.single()
			.throwOnError();

		if (data == null) {
			throw new Error("Failed to update bike");
		}

		return data;
	}
}
