import * as React from "react";

import { cn } from "@/lib/utils";

export type InputProps = Omit<
	React.InputHTMLAttributes<HTMLInputElement>,
	"prefix"
> & {
	prefix?: string;
	suffix?: string;
};

const useRefSize = () => {
	const elementRef = React.useRef<HTMLDivElement>(null);
	const [size, setSize] = React.useState({ width: 0, height: 0 });

	React.useEffect(() => {
		if (!elementRef.current) return;

		const { width, height } = elementRef.current.getBoundingClientRect();
		setSize({ width, height });

		const resizeObserver = new ResizeObserver(() => {
			if (!elementRef.current) {
				return;
			}
			const { width, height } = elementRef.current.getBoundingClientRect();
			console.log(width, height);
			setSize({ width, height });
		});
		resizeObserver.observe(elementRef.current);
		return () => resizeObserver.disconnect(); // clean up
	}, []);

	return { elementRef, size };
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	({ className, type, prefix, suffix, ...props }, ref) => {
		const prefixRef = useRefSize();
		const suffixRef = useRefSize();

		return (
			<div className={"relative w-full"}>
				{prefix && (
					<div
						ref={prefixRef.elementRef}
						className={
							"absolute left-[1px] top-[1px] bottom-[1px] flex items-center pl-2 pr-1 bg-white rounded-md"
						}
					>
						<span className={"text-sm font-medium text-muted-foreground"}>
							{prefix}
						</span>
					</div>
				)}
				<input
					type={type}
					className={cn(
						"flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
						className,
					)}
					style={{
						paddingLeft: prefix ? `${prefixRef.size.width}px` : undefined,
						paddingRight: suffix ? `${suffixRef.size.width}px` : undefined,
					}}
					ref={ref}
					{...props}
				/>
				{suffix && (
					<div
						ref={suffixRef.elementRef}
						className={
							"absolute right-[1px] top-[1px] bottom-[1px] flex items-center pr-2 pr-2 bg-white rounded-md"
						}
					>
						<span className={"text-sm font-medium text-muted-foreground"}>
							{suffix}
						</span>
					</div>
				)}
			</div>
		);
	},
);
Input.displayName = "Input";

export { Input };
