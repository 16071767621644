import {
	BikeService,
	ConfigService,
	FormatterService,
	RegionService,
	ReviewService,
	RouteService,
	SupabaseService,
	TourDateService,
	TourService,
} from "./services";

// export type OpenroadClientOptions = {
// 	cache?: boolean;
// };

export class OpenroadClient {
	config: ConfigService;
	supabase: SupabaseService;
	// cacheService: CacheService;

	bikes: BikeService;
	regions: RegionService;
	reviews: ReviewService;
	tours: TourService;
	routes: RouteService;
	tourDates: TourDateService;
	formatter: FormatterService;

	constructor() {
		this.config = new ConfigService();
		this.supabase = new SupabaseService(this.config);
		this.bikes = new BikeService(this.supabase);
		this.regions = new RegionService(this.supabase);
		this.reviews = new ReviewService(this.supabase);
		this.tours = new TourService(this.supabase);
		this.routes = new RouteService(this.supabase);
		this.tourDates = new TourDateService(this.supabase);
		this.formatter = new FormatterService();
	}
}
