import { queryClient } from "@/queries";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { SupabaseService } from "@/services/supabase.service";
import { CreateTour, Tour, UpdateTour } from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["tour"] as const,
	tour: (id: string) => [...QueryKeys.root(), id] as const,
	tours: () => [...QueryKeys.root(), "all"] as const,
	toursForRoute: (routeId: string) =>
		[...QueryKeys.root(), "forRoute", routeId] as const,
};

export const TourQueries = {
	QueryKeys,

	useTours: () => {
		// const { client } = OpenroadClientService.useState();

		return useQuery({
			queryKey: QueryKeys.tours(),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("tour")
					.select("*");
				return data ?? [];
			},
		});
	},

	useTour: (tourId: string) => {
		const { client } = OpenroadClientService.useState();

		return useQuery({
			queryKey: QueryKeys.tour(tourId),
			queryFn: async () => {
				return client.tours.getTourById(tourId);
			},
		});
	},

	useCreateTourMutation: () => {
		const { client } = OpenroadClientService.useState();
		return useMutation({
			mutationFn: async (createTour: CreateTour) => {
				const { data } = await client.supabase
					.instance()
					.from("tour")
					.insert(createTour)
					.select("*")
					.single()
					.throwOnError();

				if (data == null) {
					throw new Error("Failed to create tour");
				}

				return data;
			},
			onSuccess: async (tour) => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.tour(tour.id),
				});
			},
		});
	},

	useUpdateTourMutation: () => {
		const { client } = OpenroadClientService.useState();
		return useMutation({
			mutationFn: async (updateTour: UpdateTour & { id: string }) => {
				const { data } = await client.supabase
					.instance()
					.from("tour")
					.update(updateTour)
					.eq("id", updateTour.id)
					.select("*")
					.single()
					.throwOnError();

				if (data == null) {
					throw new Error("Failed to update tour");
				}

				return data as Tour;
			},
			onSuccess: async (tour) => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.tour(tour.id),
				});
			},
		});
	},
};
