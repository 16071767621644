import {
	RefObject,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

export type PortalServiceContextType = React.MutableRefObject<PortalApi>;

const Context = createContext<PortalServiceContextType | null>(null);

export const PortalService = {
	Context,
	Provider: Context.Provider,

	useState() {
		const state = useContext(Context);
		if (state == null) {
			throw new Error("PortalService not found");
		}
		return state;
	},

	usePortal(id: string) {
		const portalApi = PortalService.useState();

		const [element, setElement] = useState<HTMLElement | null>(null);

		useEffect(() => {
			const removeListener = portalApi.current.addListener(id, (event) => {
				if (event.type === "MOUNTED") {
					setElement(event.ref.current);
				} else {
					setElement(null);
				}
			});

			return () => {
				removeListener();
			};
		}, [id, portalApi]);

		return element;
	},

	useProvidedState(): PortalServiceContextType {
		const portalApi = useRef(new PortalApi());
		return portalApi;
	},
};

export type MountedEvent = {
	type: "MOUNTED";
	id: string;
	ref: RefObject<HTMLElement>;
};

export type UnmountedEvent = {
	type: "UNMOUNTED";
	id: string;
};

export type PortalListener = (event: MountedEvent | UnmountedEvent) => void;

class PortalApi {
	private refs: Record<string, RefObject<HTMLElement>> = {};
	private listeners: Record<string, PortalListener[]> = {};

	constructor() {
		this.refs = {};
		this.listeners = {};
	}

	addPortal = (id: string, ref: RefObject<HTMLElement>) => {
		setTimeout(() => {
			this.refs[id] = ref;
			const event: MountedEvent = { type: "MOUNTED", id, ref };
			this.emit(event);
		}, 0);
	};

	removePortal = (id: string) => {
		delete this.refs[id];
		const event: UnmountedEvent = { type: "UNMOUNTED", id };
		this.emit(event);
	};

	getPortal = (id: string) => {
		return this.refs[id];
	};

	addListener = (id: string, listener: PortalListener) => {
		if (!this.listeners[id]) {
			this.listeners[id] = [];
		}
		this.listeners[id].push(listener);

		return () => this.removeListener(id, listener);
	};

	removeListener = (id: string, listener: PortalListener) => {
		this.listeners[id] =
			this.listeners[id]?.filter((l) => l !== listener) ?? [];
	};

	emit = (event: MountedEvent | UnmountedEvent) => {
		for (const listener of this.listeners[event.id]) {
			listener(event);
		}
	};
}
