import { useCallback, useState } from "react";

export type DialogState = {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	openDialog: () => void;
	closeDialog: () => void;
};

export const useDialogState = (): DialogState => {
	const [isOpen, setIsOpen] = useState(false);

	const onOpenChange = useCallback((open: boolean) => {
		setIsOpen(open);
	}, []);

	const openDialog = useCallback(() => {
		setIsOpen(true);
	}, []);

	const closeDialog = useCallback(() => {
		setIsOpen(false);
	}, []);

	return {
		open: isOpen,
		onOpenChange,
		openDialog,
		closeDialog,
	};
};
