import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { FunctionComponent, ReactNode } from "react";

export type TextAreaFieldProps = {
	name: string;
	placeholder: string;
	label: ReactNode;
	description?: ReactNode;
};

export const TextAreaField: FunctionComponent<TextAreaFieldProps> = (props) => {
	const { name, label, placeholder, description } = props;
	return (
		<FormField
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Textarea
							{...field}
							className={"min-h-[100px] w-full"}
							placeholder={placeholder}
						/>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
