import { Button } from "@/components/ui/button";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { AuthService } from "@/modules/auth/services/auth.service";
import { queryClient } from "@/queries";
import { SupabaseService } from "@/services/supabase.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

const formValidation = z.object({
	email: z.string().email("Invalid email"),
	password: z.string(),
});

type FormSchema = z.infer<typeof formValidation>;

export const Login: FunctionComponent = () => {
	const form = useForm<FormSchema>({
		defaultValues: {
			email: "",
			password: "",
		},
		resolver: zodResolver(formValidation),
	});

	const onSubmit = async ({ email, password }: FormSchema) => {
		const { data } = await SupabaseService.instance().auth.signInWithPassword({
			email,
			password,
		});

		await queryClient.setQueryData(AuthService.QueryKeys.user, data.user);
	};

	return (
		<div className={"w-full max-w-sm p-8"}>
			<Form {...form}>
				<FormField<FormSchema>
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Email</FormLabel>
							<FormControl>
								<Input {...field} type="email" placeholder="Email" />
							</FormControl>
							<FormDescription />
							<FormMessage />
						</FormItem>
					)}
				/>
				<FormField<FormSchema>
					name="password"
					render={({ field }) => (
						<FormItem>
							<FormLabel>Password</FormLabel>
							<FormControl>
								<Input {...field} type="password" placeholder="Password" />
							</FormControl>
							<FormDescription />
							<FormMessage />
						</FormItem>
					)}
				/>

				<Button
					onClick={form.handleSubmit(onSubmit)}
					type="submit"
					isLoading={form.formState.isSubmitting}
				>
					Login
				</Button>
			</Form>
		</div>
	);
};
