import { Loading } from "@/components/ui/loading.tsx";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Route } from "@/routes/tours/$tourId/route.tsx";
import { Link, Outlet } from "@tanstack/react-router";
import { FunctionComponent } from "react";

import { Badge } from "@/components/ui/badge.tsx";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb.tsx";
import { cn } from "@/lib/utils";

const LINKS = [
	{
		label: "Details",
		to: "/tours/$tourId",
	},
	{
		label: "Tour Dates",
		to: "/tours/$tourId/tour-dates",
	},
	{
		label: "Images",
		to: "/tours/$tourId/images",
	},
	{
		label: "Route Builder",
		to: "/tours/$tourId/route-builder",
	},
] as const;

export const TourRoute: FunctionComponent = () => {
	const { tourId } = Route.useParams();

	const { data: tour } = TourQueries.useTour(tourId);

	if (tour == null) {
		return <Loading />;
	}

	return (
		<>
			<div className="flex flex-col sm:gap-4 sm:py-4">
				<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
					<Breadcrumb className="hidden md:flex h-10">
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<Link to={"/tours"} params={{}}>
										Tours
									</Link>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbLink className={"flex gap-2"} asChild>
									<Link to={"/tours/$tourId"} params={{ tourId: tourId }}>
										{tour.name}

										<Badge variant={"secondary"}>
											{tour.number_of_days} days
										</Badge>
										<Badge>{tour.status}</Badge>
									</Link>
								</BreadcrumbLink>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				</header>
				<main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
					<div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
						<nav className="grid gap-4 text-sm text-muted-foreground">
							{LINKS.map((link) => (
								<Link
									key={link.to}
									to={link.to}
									params={{
										tourId: tourId,
									}}
									className={cn(
										"p-2 rounded-md",
										// false
										// 	? "bg-primary-foreground text-primary-background"
										// 	: "hover:bg-primary-foreground/10",
									)}
								>
									{link.label}
								</Link>
							))}
						</nav>
						<div className="grid gap-6">
							<Outlet />
						</div>
					</div>
				</main>
			</div>
		</>
	);
};
