import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { Tour, TourStatusEnum } from "@repo/types";
import { ListFilter, PlusCircle, Search } from "lucide-react";
import { FunctionComponent, useMemo, useState } from "react";

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { CreateTourDialog } from "@/modules/tours/dialogs/create-tour.dialog.tsx";
import { useNavigate } from "@tanstack/react-router";
import Fuse from "fuse.js";
import { TourQueries } from "../queries/tour.queries";

// export function Tours() {
// 	const form = useForm<FilterValues>({
// 		resolver: zodResolver(filterSchema),
// 		defaultValues: {
// 			search: "",
// 		},
// 	});
//
// 	return (
// 		<>
// 			<Form {...form}>
// 				<div className="flex items-center gap-4 justify-between">
// 					<h1 className="text-lg font-semibold md:text-2xl">Tours</h1>
// 					<CreateTourDialog>
// 						<Button variant={"outline"} size={"sm"}>
// 							Create Tour
// 						</Button>
// 					</CreateTourDialog>
// 				</div>
// 				<div className="grid flex-1 gap-4 overflow-auto md:grid-cols-2 lg:grid-cols-3">
// 					<Filter />
// 					<div className="relative flex h-full min-h-[50vh] flex-col rounded-xl bg-muted/50 p-4 lg:col-span-2">
// 						<ToursTable />
// 					</div>
// 				</div>
// 			</Form>
// 		</>
// 	);
// }
//
// const Filter = () => {
// 	return (
// 		<div className="relative hidden flex-col items-start gap-8 md:flex">
// 			<div className="grid w-full items-start gap-6">
// 				<fieldset className="grid gap-6 rounded-lg border p-4">
// 					<legend className="-ml-1 px-1 text-sm font-medium">Filter</legend>
//
// 					<FormField<FilterValues>
// 						name="search"
// 						render={({ field }) => (
// 							<FormItem>
// 								<FormLabel>Search</FormLabel>
// 								<FormControl>
// 									<Input
// 										{...field}
// 										type="text"
// 										placeholder="23 Day Twin Island ..."
// 									/>
// 								</FormControl>
// 								<FormDescription />
// 								<FormMessage />
// 							</FormItem>
// 						)}
// 					/>
// 				</fieldset>
// 			</div>
// 		</div>
// 	);
// };
//
// const ToursTable = () => {
// 	const { data: tours } = TourQueries.useTours();
//
// 	const { search } = useWatch<FilterValues>();
//
// 	const fuzzy = useMemo(() => {
// 		return new Fuse(tours ?? [], {
// 			keys: ["name"],
// 		});
// 	}, [tours]);
//
// 	const filteredTours = useMemo(() => {
// 		if (search == null || search === "") {
// 			return tours;
// 		}
// 		return fuzzy.search(search).map((result) => result.item);
// 	}, [search, fuzzy, tours]);
//

//
// 	return (
// 		<Table>
// 			<TableHeader>
// 				<TableRow>
// 					<TableHead>Id</TableHead>
// 					<TableHead>Tour</TableHead>
// 					<TableHead>Type</TableHead>
// 					<TableHead className="text-right">Status</TableHead>
// 				</TableRow>
// 			</TableHeader>
// 			<TableBody>
// 				{filteredTours?.map((tour) => {
// 					return (
// 						<TableRow
// 							onClick={() => onRowClick(tour)}
// 							className="cursor-pointer hover:bg-gray-300/80"
// 						>
// 							<TableCell>{tour.id}</TableCell>
// 							<TableCell className="font-medium">{tour.name}</TableCell>
// 							<TableCell>{tour.type.map(getTourType).join(" - ")}</TableCell>
// 							<TableCell className="text-right">
// 								<Badge variant={getBadgeVariant(tour.status)}>
// 									{tour.status}
// 								</Badge>
// 							</TableCell>
// 						</TableRow>
// 					);
// 				})}
// 			</TableBody>
// 		</Table>
// 	);
// };

const getTourType = (type: string) => {
	switch (type) {
		case "GUIDED":
			return "Guided";
		case "SELF_GUIDED":
			return "Self-guided";
		default:
			return "Unknown";
	}
};

const getBadgeVariant = (status: TourStatusEnum) => {
	switch (status) {
		case "ACTIVE":
			return "default";
		case "INACTIVE":
			return "secondary";
		default:
			return "secondary";
	}
};

type Filter = "ALL" | "ACTIVE" | "INACTIVE" | "DELETED";

export function Tours() {
	const { data: tours } = TourQueries.useTours();

	const [search, setSearch] = useState<string>("");
	const [filter, setFilter] = useState<Filter>("ALL");

	const fuzzy = useMemo(() => {
		return new Fuse(tours ?? [], {
			keys: ["name"],
		});
	}, [tours]);

	const searchedTours = useMemo(() => {
		if (search == null || search === "") {
			return tours;
		}
		return fuzzy.search(search).map((result) => result.item);
	}, [search, fuzzy, tours]);

	return (
		<div className="flex flex-col sm:gap-4 sm:py-4">
			<main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
				<Card>
					<CardHeader className={"flex flex-row justify-between"}>
						<div>
							<CardTitle>Tours</CardTitle>
							<CardDescription>Manage tours</CardDescription>
						</div>
						<div className="ml-auto flex items-center gap-2">
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button
										variant="outline"
										size="sm"
										className={cn(
											"gap-1 text-sm",
											filter !== "ALL" && "outline outline-primary",
										)}
									>
										<ListFilter className="h-3.5 w-3.5" />
										<span className="sr-only sm:not-sr-only">Status</span>
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end">
									<DropdownMenuLabel>Filter status by</DropdownMenuLabel>
									<DropdownMenuSeparator />
									<DropdownMenuCheckboxItem
										checked={filter === "ALL"}
										onClick={() => setFilter("ALL")}
									>
										All
									</DropdownMenuCheckboxItem>
									<DropdownMenuCheckboxItem
										checked={filter === "ACTIVE"}
										onClick={() => setFilter("ACTIVE")}
									>
										Active
									</DropdownMenuCheckboxItem>
									<DropdownMenuCheckboxItem
										checked={filter === "INACTIVE"}
										onClick={() => setFilter("INACTIVE")}
									>
										Inactive
									</DropdownMenuCheckboxItem>
								</DropdownMenuContent>
							</DropdownMenu>

							<CreateTourDialog>
								<Button size={"sm"} className="gap-1">
									<PlusCircle className="h-3.5 w-3.5" />
									<span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
										Add Tour
									</span>
								</Button>
							</CreateTourDialog>

							<div className="relative ml-auto flex-1 md:grow-0">
								<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
								<Input
									onChange={(e) => setSearch(e.target.value)}
									value={search}
									type="search"
									placeholder="Search..."
									className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]"
								/>
							</div>
						</div>
					</CardHeader>
					<CardContent>
						<Table>
							<TableHeader>
								<TableRow>
									{/*<TableHead className="hidden w-[100px] sm:table-cell">*/}
									{/*	<span className="sr-only">Image</span>*/}
									{/*</TableHead>*/}
									<TableHead>Name</TableHead>
									<TableHead>Status</TableHead>
									<TableHead>Type</TableHead>
									<TableHead>Price from</TableHead>
									<TableHead>Region</TableHead>
									{/*<TableHead className="hidden md:table-cell">*/}
									{/*	Total Sales*/}
									{/*</TableHead>*/}
									{/*<TableHead>*/}
									{/*	<span className="sr-only">Actions</span>*/}
									{/*</TableHead>*/}
								</TableRow>
							</TableHeader>
							<TableBody>
								{searchedTours
									?.filter((tour) => {
										if (filter === "ALL") return true;

										return tour.status === filter.toUpperCase();
									})
									?.map((tour) => (
										<TourTableRow tour={tour} key={tour.id} />
									))}
							</TableBody>
						</Table>
					</CardContent>
					{/*<CardFooter>*/}
					{/*	<div className="text-xs text-muted-foreground">*/}
					{/*		Showing <strong>1-10</strong> of <strong>32</strong>{" "}*/}
					{/*		tours*/}
					{/*	</div>*/}
					{/*</CardFooter>*/}
				</Card>
			</main>
		</div>
	);
}

const TourTableRow: FunctionComponent<{ tour: Tour }> = ({ tour }) => {
	const navigate = useNavigate();

	const onRowClick = () => {
		void navigate({
			to: "/tours/$tourId",
			params: {
				tourId: tour.id,
			},
		});
	};

	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		maximumFractionDigits: 0,
	});

	return (
		<TableRow
			onClick={onRowClick}
			className="cursor-pointer hover:bg-gray-100/80"
		>
			{/*<TableCell className="hidden sm:table-cell">*/}
			{/*	<Image*/}
			{/*		alt="Product image"*/}
			{/*		className="aspect-square rounded-md object-cover"*/}
			{/*		height="64"*/}
			{/*		src="/placeholder.svg"*/}
			{/*		width="64"*/}
			{/*	/>*/}
			{/*</TableCell>*/}
			<TableCell className="font-medium">{tour.name}</TableCell>
			<TableCell>
				<Badge variant={getBadgeVariant(tour.status)}>{tour.status}</Badge>
			</TableCell>
			<TableCell>{tour.type.map(getTourType).join(" - ")}</TableCell>
			<TableCell>{formatter.format(tour.price_from)}</TableCell>
			<TableCell>{tour.region_slug}</TableCell>
			{/*<TableCell className="hidden md:table-cell">*/}
			{/*	25*/}
			{/*</TableCell>*/}
			{/*<TableCell>*/}
			{/*	<DropdownMenu>*/}
			{/*		<DropdownMenuTrigger asChild>*/}
			{/*			<Button aria-haspopup="true" size="icon" variant="ghost">*/}
			{/*				<MoreHorizontal className="h-4 w-4" />*/}
			{/*				<span className="sr-only">Toggle menu</span>*/}
			{/*			</Button>*/}
			{/*		</DropdownMenuTrigger>*/}
			{/*		<DropdownMenuContent align="end">*/}
			{/*			<DropdownMenuLabel>Actions</DropdownMenuLabel>*/}
			{/*			<DropdownMenuItem>Edit</DropdownMenuItem>*/}
			{/*			<DropdownMenuItem>Delete</DropdownMenuItem>*/}
			{/*		</DropdownMenuContent>*/}
			{/*	</DropdownMenu>*/}
			{/*</TableCell>*/}
		</TableRow>
	);
};
