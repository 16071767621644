import { Star, StarHalf } from "lucide-react";
import { FunctionComponent } from "react";

type StarRatingProps = {
	rating: number;
	onRatingChange?: (rating: number) => void;
};

export const StarRating: FunctionComponent<StarRatingProps> = ({
	rating,
	onRatingChange,
}) => {
	const stars = Array.from({ length: 5 }, (_, index) => {
		const starNumber = index + 1;

		if (rating >= starNumber) {
			return (
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				<Star key={index} fill={"gold"} stroke={"gold"} className={"h-5 w-5"} />
			);
		}

		if (rating >= starNumber - 0.5) {
			return (
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				<div key={index} className={"relative"}>
					<Star fill="none" className={"h-5 w-5 stroke-gray-300"} />
					<StarHalf
						fill={"gold"}
						stroke={"gold"}
						className={"h-5 w-5 absolute top-0 left-0"}
					/>
				</div>
			);
		}

		return (
			// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
			<Star key={index} fill="none" className={"h-5 w-5 stroke-gray-300"} />
		);
	});

	return (
		<div className={"flex gap-1 relative"}>
			{stars.map((star, index) => {
				return (
					<div
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={index}
						className={"cursor-pointer aspect-square"}
						onKeyDown={() => {}}
						onClick={() => onRatingChange?.(index + 1)}
					>
						{star}
					</div>
				);
			})}
		</div>
	);
};
