import { SupabaseService } from "./supabase.service";

export class RouteService {
	private supabase: SupabaseService;

	constructor(supabase: SupabaseService) {
		this.supabase = supabase;
	}

	async getRoutes() {
		const { data } = await this.supabase
			.instance()
			.from("route")
			.select("*")
			.throwOnError();
		return data ?? [];
	}

	async getRoutesForIds(routeIds: string[]) {
		const { data } = await this.supabase
			.instance()
			.from("route")
			.select("*")
			.in("id", routeIds)
			.throwOnError();
		return data ?? [];
	}
}
