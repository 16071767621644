export class FormatterService {
	currency: Intl.NumberFormat;
	dateLong: Intl.DateTimeFormat;
	dateShort: Intl.DateTimeFormat;

	constructor() {
		this.currency = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "NZD",
			maximumFractionDigits: 0,
		});

		this.dateLong = new Intl.DateTimeFormat("en", {
			month: "long",
			day: "numeric",
			year: "numeric",
		});

		this.dateShort = new Intl.DateTimeFormat("en", {
			month: "short",
			day: "numeric",
			year: "numeric",
		});
	}
}
