import { Loading } from "@/components/ui/loading";
import { BikeDetailsForm } from "@/modules/bikes/components/bike-details-form";
import { BikeQueries } from "@/modules/bikes/queries/bike.queries";
import { Route } from "@/routes/bikes/$bikeId";
import { FunctionComponent } from "react";

export const BikeDetailsRoute: FunctionComponent = () => {
	const params = Route.useParams();

	const { data: bike } = BikeQueries.useBike(params.bikeId);

	if (bike == null) {
		return <Loading />;
	}

	return <BikeDetailsForm bike={bike} />;
};
