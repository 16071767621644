import {
	QueryClient,
	QueryClientConfig,
	QueryClientProvider,
} from "@tanstack/react-query";
import { FunctionComponent, PropsWithChildren } from "react";
import { OpenroadClientService } from "../services/openroad-client.service";

export const DEFAULT_QUERY_CLIENT_CONFIG: QueryClientConfig = {
	defaultOptions: {
		queries: {
			// staleTime: 1000 * 60 * 5, // 5 minutes
		},
	},
};

export type OpenroadClientContainerProps = PropsWithChildren<{
	queryClient: QueryClient;
}>;

export const OpenroadClientContainer: FunctionComponent<
	OpenroadClientContainerProps
> = (props) => {
	const { children, queryClient } = props;

	const providedState = OpenroadClientService.useProvidedState();

	return (
		<QueryClientProvider client={queryClient}>
			<OpenroadClientService.Provider value={providedState}>
				{children}
			</OpenroadClientService.Provider>
		</QueryClientProvider>
	);
};
