import { InputField } from "@/components/form/inputField.tsx";
import { NumberField } from "@/components/form/number.field";
import { TextAreaField } from "@/components/form/text-area.field";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { BikeDescriptionField } from "@/modules/bikes/components/bike-description-field";
import { BikeNameField } from "@/modules/bikes/components/bike-name-field";
import { BikeSlugField } from "@/modules/bikes/components/bike-slug-field";
import { BikeStatusField } from "@/modules/bikes/components/bike-status-field";
import { BikeQueries } from "@/modules/bikes/queries/bike.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { slugify } from "@repo/api";
import { Bike } from "@repo/types";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

const bikeFormValidation = z.object({
	error: z.string().optional().nullable(),
	name: z.string(),
	slug: z
		.string()
		.regex(
			/^[a-z0-9]+(?:-[a-z0-9]+)*$/,
			"Invalid slug. Must be all lowercase and contain only letters, numbers, and dashes",
		),
	highlight: z.string(),
	description: z.string(),
	status: z.enum(["INACTIVE", "ACTIVE", "DELETED"]),
	cc: z.number().int().positive(),
	engine_configuration: z.string(),
	final_drive: z.string(),
	fuel_capacity: z.number().int().positive(),
	luggage_system: z.string(),
	other_features: z.string(),
	power: z.number().int().positive(),
	seat_height: z.number().int().positive(),
	torque_nm: z.number().int().positive(),
	torque_revs: z.number().int().positive(),
	weight: z.number().int().positive(),
	wind_protection: z.string(),
});

export type BikeFormSchema = z.infer<typeof bikeFormValidation>;

export type TourDetailsFormProps = {
	bike: Bike;
};

export const BikeDetailsForm: FunctionComponent<TourDetailsFormProps> = ({
	bike,
}) => {
	const updateBikeMutation = BikeQueries.useUpdateBike();

	const form = useForm<BikeFormSchema>({
		defaultValues: bike,
		resolver: zodResolver(bikeFormValidation),
	});

	const onSubmit = async (values: BikeFormSchema) => {
		const updatedBike = await updateBikeMutation.mutateAsync({
			id: bike.id,
			...values,
		});
		form.reset(updatedBike);
		toast.success("Bike updated");
	};

	return (
		<Form {...form}>
			<Card className={"w-full pt-0"}>
				<CardHeader
					className={
						"flex flex-row justify-between sticky top-16 bg-white border-b"
					}
				>
					<h1 className="text-2xl font-bold">Edit bike details</h1>
					<Button
						onClick={form.handleSubmit(onSubmit)}
						type="submit"
						isLoading={form.formState.isSubmitting}
						disabled={!form.formState.isDirty}
					>
						Save
					</Button>
				</CardHeader>
				<CardContent className={"flex flex-col gap-2"}>
					<BikeStatusField />
					<div className={"max-w-md"}>
						<BikeNameField
							onChange={(e) => {
								form.setValue("slug", slugify(e.target.value));
							}}
						/>
						<BikeSlugField />
					</div>
					<PowerAndSeatHeight />
					<CCAndEngineConfig />
					<FuelAndFinalDrive />
					<WindAndWeight />
					<Torque />
					<LuggageAndOtherFeatures />
					<TextAreaField name="highlight" label="Highlight" placeholder="..." />
					<BikeDescriptionField />
				</CardContent>
			</Card>
		</Form>
	);
};

const Torque = () => {
	return (
		<div className={"grid grid-cols-2 gap-x-4"}>
			<NumberField
				name="torque_nm"
				label="Torque NM"
				placeholder="..."
				description="Torque NM"
			/>

			<NumberField name="torque_revs" label="Torque Revs" placeholder="..." />
		</div>
	);
};

const WindAndWeight = () => {
	return (
		<div className={"grid grid-cols-2 gap-x-4"}>
			<InputField
				name="wind_protection"
				label="Wind Protection"
				placeholder="..."
			/>

			<NumberField name="weight" label="Weight KG" placeholder="..." />
		</div>
	);
};

const CCAndEngineConfig = () => {
	return (
		<div className={"grid grid-cols-2 gap-x-4"}>
			<NumberField name="cc" label="CC" placeholder="..." />
			<InputField
				name="engine_configuration"
				label="Engine Configuration"
				placeholder="..."
			/>
		</div>
	);
};

const FuelAndFinalDrive = () => {
	return (
		<div className={"grid grid-cols-2 gap-x-4"}>
			<NumberField
				name="fuel_capacity"
				label="Fuel Capacity"
				placeholder="..."
			/>
			<InputField
				name={"final_drive"}
				label={"Final Drive"}
				placeholder={"..."}
			/>
		</div>
	);
};

const LuggageAndOtherFeatures = () => {
	return (
		<div className={"grid grid-cols-2 gap-x-4"}>
			<InputField
				name="luggage_system"
				label="Luggage System"
				placeholder="..."
			/>
			<InputField
				name="other_features"
				label="Other Features"
				placeholder="..."
			/>
		</div>
	);
};

const PowerAndSeatHeight = () => {
	return (
		<div className={"grid grid-cols-2 gap-x-4"}>
			<NumberField name="power" label="Power HP" placeholder="..." />

			<NumberField name="seat_height" label="Seat Height" placeholder="..." />
		</div>
	);
};
