import { Loading } from "@/components/ui/loading";
import { TourDateCalendar } from "@/modules/tours/components/tour-date-calendar";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Route } from "@/routes/tours/$tourId/tour-dates";
import { FunctionComponent } from "react";

export const TourDatesRoute: FunctionComponent = () => {
	const { tourId } = Route.useParams();

	const { data: tour } = TourQueries.useTour(tourId);

	if (tour == null) {
		return <Loading />;
	}

	return (
		<div className="w-full">
			<TourDateCalendar tour={tour} />
		</div>
	);
};
