import { Database } from "@repo/types";
import * as Supabase from "@supabase/supabase-js";

const supabaseClient = Supabase.createClient<Database>(
	import.meta.env.VITE_SUPABASE_URL,
	import.meta.env.VITE_SUPABASE_ANON_KEY,
);

export const SupabaseService = {
	instance() {
		return supabaseClient;
	},
};
