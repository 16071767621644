import { Button } from "@/components/ui/button";
import { DialogClose } from "@/components/ui/dialog";
import { FileExplorerService } from "@/modules/images/services/file-explorer.service";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { FunctionComponent } from "react";

export type FileExplorerFooterProps = {
	onSelectPress?: (selectedFiles: string[]) => void;
	onCancel?: () => void;
};

export const FileExplorerFooter: FunctionComponent<FileExplorerFooterProps> = (
	props,
) => {
	const { onSelectPress, onCancel } = props;

	const { selectedFiles } = FileExplorerService.useState();
	const { client } = OpenroadClientService.useState();

	return (
		<div className={"p-4 items-center justify-end flex gap-2 min-h-4"}>
			{selectedFiles.length > 0 && (
				<div className={"flex-1 flex gap-2"}>
					{selectedFiles.map((file) => (
						<div
							key={file}
							className={
								"rounded-lg w-12 h-12 bg-gray-200 flex justify-center items-center aspect-square"
							}
						>
							<img
								src={client.supabase.getFile(file).publicUrl}
								alt={file}
								className={"w-full h-full object-cover"}
							/>
						</div>
					))}
				</div>
			)}
			{onCancel && (
				<DialogClose asChild>
					<Button variant={"secondary"} onClick={onCancel}>
						Cancel
					</Button>
				</DialogClose>
			)}
			{onSelectPress && (
				<Button
					disabled={selectedFiles.length === 0}
					onClick={() => onSelectPress(selectedFiles)}
				>
					Select {selectedFiles.length || ""} images
				</Button>
			)}
		</div>
	);
};
