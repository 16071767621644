import { queryClient } from "@/queries";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { CreateBike, UpdateBike } from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["bike"] as const,
	all: () => [...QueryKeys.root(), "all"] as const,
	byId: (id: string) => [...QueryKeys.root(), "id", id] as const,
};

export const BikeQueries = {
	QueryKeys,

	useBikes: () => {
		const { client } = OpenroadClientService.useState();
		return useQuery({
			queryKey: QueryKeys.all(),
			initialData: [],
			queryFn: async () => {
				return client.bikes.getBikes();
			},
		});
	},

	useBike: (id: string) => {
		const { client } = OpenroadClientService.useState();
		return useQuery({
			queryKey: QueryKeys.byId(id),
			queryFn: async () => {
				return client.bikes.getBikeById(id);
			},
		});
	},

	useCreateBike: () => {
		const { client } = OpenroadClientService.useState();
		return useMutation({
			mutationFn: async (createBike: CreateBike) => {
				return client.bikes.createBike(createBike);
			},
			onSuccess: async (data) => {
				await queryClient.setQueryData(QueryKeys.byId(data.id), data);
			},
		});
	},

	useUpdateBike: () => {
		const { client } = OpenroadClientService.useState();
		return useMutation({
			mutationFn: async (updateBike: UpdateBike & { id: string }) => {
				return client.bikes.updateBike(updateBike);
			},
			onSuccess: async (data) => {
				await queryClient.setQueryData(QueryKeys.byId(data.id), data);
			},
		});
	},
};
