import { InputField } from "@/components/form/inputField.tsx";
import { MultiTextField } from "@/components/form/multi-text.field.tsx";
import { RichTextField } from "@/components/form/rich-text.field.tsx";
import { SelectField, SelectItem } from "@/components/form/select.field.tsx";
import { TextAreaField } from "@/components/form/text-area.field.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@/components/ui/card.tsx";
import { Form } from "@/components/ui/form.tsx";
import { TourDaysFields } from "@/modules/tours/components/tour-days-fields.tsx";
import { TourNameField } from "@/modules/tours/components/tour-name-field.tsx";
import { TourSlugField } from "@/modules/tours/components/tour-slug-field.tsx";
import { TourStatusField } from "@/modules/tours/components/tour-status-field.tsx";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { Tour } from "@repo/types";
import { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import { RegionQueries } from "../../../queries/region.queries";
import { TourTypeField } from "./tour-type-field";

const tourFormValidation = z.object({
	error: z.string().optional().nullable(),
	name: z.string(),
	region_slug: z.string(),
	type: z.array(z.enum(["GUIDED", "SELF_GUIDED"])).min(1),
	slug: z
		.string()
		.regex(
			/^[a-z0-9]+(?:-[a-z0-9]+)*$/,
			"Invalid slug. Must be all lowercase and contain only letters, numbers, and dashes",
		),
	price_from: z.number().min(0),
	pillion_price_from: z.number().min(0),
	single_room_price_from: z.number().min(0),
	number_of_days: z.number(),
	number_of_days_riding: z.number(),
	highlight: z.string(),
	description: z.string(),
	status: z.enum(["INACTIVE", "ACTIVE", "DELETED"]),
	highlights: z.array(z.string()),
	includes: z.array(z.string()),
	excludes: z.array(z.string()),
});

export type TourFormSchema = z.infer<typeof tourFormValidation>;

export type TourDetailsFormProps = {
	tour: Tour;
};

export const TourDetailsForm: FunctionComponent<TourDetailsFormProps> = ({
	tour,
}) => {
	const { data: regions = [] } = RegionQueries.useRegions();

	const form = useForm<TourFormSchema>({
		defaultValues: {
			...tour,
			type: tour.type as ("GUIDED" | "SELF_GUIDED")[],
			includes: tour.includes ?? [],
			excludes: tour.excludes ?? [],
			highlights: tour.highlights ?? [],
		},
		resolver: zodResolver(tourFormValidation),
	});

	const updateTourMutation = TourQueries.useUpdateTourMutation();

	const onSubmit = async (values: TourFormSchema) => {
		const updatedTour = await updateTourMutation.mutateAsync({
			id: tour.id,
			...values,
		});
		form.reset({
			...updatedTour,
			type: updatedTour.type as ("GUIDED" | "SELF_GUIDED")[],
			includes: updatedTour.includes ?? [],
			excludes: updatedTour.excludes ?? [],
			highlights: updatedTour.highlights ?? [],
		});
		toast.success("Tour updated");
	};

	// useBlocker(
	// 	() =>
	// 		window.confirm(
	// 			"Are you sure you want to leave? You have unsaved changes that will be lost.",
	// 		),
	// 	form.formState.isDirty,
	// );

	return (
		<Form {...form}>
			<Card className={"w-full bg-background relative"}>
				<CardHeader
					className={
						"flex flex-row justify-between items-center sticky top-16 bg-white rounded-md overflow-hidden z-40"
					}
				>
					<CardTitle>Edit tour details</CardTitle>
					<Button
						onClick={form.handleSubmit(onSubmit)}
						type="submit"
						isLoading={form.formState.isSubmitting}
						disabled={!form.formState.isDirty}
					>
						Save tour
					</Button>
				</CardHeader>
				<CardContent className={"flex flex-col gap-4"}>
					<div className={"flex flex-col gap-2"}>
						<div className={"w-full flex gap-2 [&>*]:flex-1"}>
							<TourStatusField />
							<SelectField
								name={"region_slug"}
								label={"Region"}
								description={"The region this tour is in"}
							>
								{regions.map((region) => (
									<SelectItem key={region.slug} value={region.slug}>
										{region.name}
									</SelectItem>
								))}
							</SelectField>
						</div>
						<div className={"w-full flex gap-2 [&>*]:flex-1"}>
							<TourNameField />
							<TourSlugField />
						</div>

						<TourTypeField />
						<div className={"w-full flex gap-2 [&>*]:flex-1"}>
							<InputField
								name={"price_from"}
								label={"Price from"}
								type={"number"}
								prefix={"$"}
								placeholder={"5000"}
							/>
							<InputField
								name={"single_room_price_from"}
								label={"Single room price from"}
								type={"number"}
								prefix={"$"}
								placeholder={"1500"}
							/>
							<InputField
								name={"pillion_price_from"}
								label={"Pillion price from"}
								type={"number"}
								prefix={"$"}
								placeholder={"1200"}
							/>
						</div>
					</div>
					<TourDaysFields />
					<TextAreaField
						name={"highlight"}
						label={"Main highlight"}
						description={"The highlight of the tour"}
						placeholder={"The highlight of the tour"}
					/>
					<RichTextField
						name={"description"}
						label={"Description"}
						description={"The description of the tour"}
					/>

					<MultiTextField
						name={"highlights"}
						label={"Highlights"}
						description={"The highlights of the tour"}
					/>

					<MultiTextField
						name={"includes"}
						label={"Tour Includes"}
						description={"What is included in the tour"}
					/>
					<MultiTextField
						name={"excludes"}
						label={"Tour Excludes"}
						description={"What is not included from the tour price"}
					/>
				</CardContent>
				<CardFooter className={"flex justify-end"}>
					<Button
						onClick={form.handleSubmit(onSubmit)}
						type="submit"
						isLoading={form.formState.isSubmitting}
						disabled={!form.formState.isDirty}
					>
						Save tour
					</Button>
				</CardFooter>
			</Card>
		</Form>
	);
};
