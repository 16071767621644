import { queryClient } from "@/queries";
import { SupabaseService } from "@/services/supabase.service";
import { CreateRoute, UpdateRoute } from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["route"] as const,
	byId: (id: string | null) => [...QueryKeys.root(), id] as const,
};

export const RouteQueries = {
	QueryKeys,

	useRoutes: () => {
		return useQuery({
			queryKey: QueryKeys.root(),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("route")
					.select("*")
					.order("name", { ascending: true })
					.throwOnError();

				return data ?? [];
			},
		});
	},

	useRoute: (id: string | null) => {
		return useQuery({
			queryKey: QueryKeys.byId(id),
			queryFn: async () => {
				if (id == null) {
					return null;
				}

				const { data } = await SupabaseService.instance()
					.from("route")
					.select("*")
					.eq("id", id)
					.single()
					.throwOnError();

				return data;
			},
		});
	},

	useCreateRoute: () => {
		return useMutation({
			mutationFn: async (route: CreateRoute) => {
				const { data } = await SupabaseService.instance()
					.from("route")
					.insert(route)
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to create route");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useUpdateRoute: () => {
		return useMutation({
			mutationFn: async (route: UpdateRoute) => {
				const { data } = await SupabaseService.instance()
					.from("route")
					.update(route)
					.eq("id", route.id ?? "")
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to update route");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},
};
