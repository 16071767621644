import { Dialog, DialogContent } from "@/components/ui/dialog.tsx";
import { Spinner } from "@/components/ui/spinner.tsx";
import { cn } from "@/lib/utils";
import { queryClient } from "@/queries";
import { SupabaseService } from "@/services/supabase.service";
import { useMutation } from "@tanstack/react-query";
import { FunctionComponent } from "react";
import Dropzone from "react-dropzone";
import { toast } from "sonner";
import { v4 as uuid } from "uuid";
import { FileExplorerService } from "../services/file-explorer.service";

export const FileDropzone: FunctionComponent = () => {
	const { folderStack } = FileExplorerService.useState();

	const uploadImagesMutation = useMutation({
		mutationFn: async (files: File[]) => {
			const promises = files.map(async (file) => {
				const id = uuid();
				return SupabaseService.instance()
					.storage.from("global")
					.upload(
						[...folderStack, `${id}.${file.name.split(".").pop()}`]
							.join("/")
							.substring(1),
						file,
					);
			});

			await Promise.all(promises);

			await queryClient.invalidateQueries({
				queryKey: ["folder"],
			});

			toast.success("Files uploaded");
		},
	});

	const onFileDrop = async (acceptedFiles: File[]) => {
		uploadImagesMutation.mutate(acceptedFiles);
	};

	return (
		<>
			<Dropzone onDrop={onFileDrop}>
				{({ getRootProps, getInputProps }) => (
					<div
						{...getRootProps()}
						className={cn(
							"flex justify-center items-center rounded-lg",
							"cursor-pointer w-full py-10 bg-gray-100 border-dashed border-2 border-gray-300",
							"hover:bg-gray-200",
						)}
					>
						<input {...getInputProps()} />
						<p className={"text-gray-800 font-semibold text-sm text-center"}>
							Drag images here, or click to select files
						</p>
					</div>
				)}
			</Dropzone>

			<Dialog open={uploadImagesMutation.isPending}>
				<DialogContent
					className={"flex flex-col items-center justify-center space-y-4"}
				>
					<Spinner className="w-8 h-8" />
					<span className={"text-gray-800 font-semibold text-lg text-center"}>
						Uploading image(s)...
					</span>
				</DialogContent>
			</Dialog>
		</>
	);
};
