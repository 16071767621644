import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { useDialogState } from "@/hooks/use-dialog-state";
import { queryClient } from "@/queries";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { useMutation } from "@tanstack/react-query";
import { FunctionComponent, PropsWithChildren } from "react";
import { toast } from "sonner";
import { ExplorerFile } from "../services/file-explorer.service";

export type DeleteFileDialogProps = PropsWithChildren<{
	fileObject: ExplorerFile;
}>;

export const DeleteFileDialog: FunctionComponent<DeleteFileDialogProps> = (
	props,
) => {
	const { children, fileObject } = props;
	const dialogState = useDialogState();
	const { client } = OpenroadClientService.useState();

	const deleteFileMutation = useMutation({
		mutationFn: async () => {
			const fullPath = `${
				fileObject.folderStack.length > 1
					? `${fileObject.folderStack.join("/").substring(1)}/`
					: ""
			}${fileObject.name}`;
			const { data } = await client.supabase
				.instance()
				.storage.from("global")
				.remove([fullPath]);
			return data?.[0] ?? null;
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ["folder"],
			});
		},
	});

	const onDeletePress = async () => {
		await deleteFileMutation.mutateAsync();
		dialogState.closeDialog();
		toast.success("File deleted");
	};

	return (
		<Dialog {...dialogState}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Are you sure you want to delete this file?</DialogTitle>
				</DialogHeader>
				<DialogFooter>
					<Button onClick={dialogState.closeDialog} variant={"secondary"}>
						Cancel
					</Button>
					<Button
						onClick={onDeletePress}
						variant={"destructive"}
						isLoading={deleteFileMutation.isPending}
						disabled={deleteFileMutation.isPending}
					>
						Delete file
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
