import { queryClient } from "@/queries";
import { SupabaseService } from "@/services/supabase.service";
import { CreateTourLeg, UpdateTourLeg } from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["tour-leg"] as const,
	byTourId: (tourId: string) =>
		[...QueryKeys.root(), "tour_id", tourId] as const,
	byId: (id: string | null) => [...QueryKeys.root(), "id", id] as const,
	byRouteId: (routeId: string) =>
		[...QueryKeys.root(), "route_id", routeId] as const,
};

export const TourLegQueries = {
	QueryKeys,

	useTourLegs: () => {
		return useQuery({
			queryKey: QueryKeys.root(),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.select("*")
					.throwOnError();

				return data ?? [];
			},
		});
	},

	useTourLegsByTourId: (tourId: string) => {
		return useQuery({
			queryKey: QueryKeys.byTourId(tourId),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.select("*")
					.eq("tour_id", tourId)
					.order("day")
					.throwOnError();

				return data ?? [];
			},
		});
	},

	useTourLeg: (id: string | null) => {
		return useQuery({
			queryKey: QueryKeys.byId(id),
			queryFn: async () => {
				if (id == null) {
					return null;
				}

				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.select("*")
					.eq("id", id)
					.single()
					.throwOnError();

				return data;
			},
		});
	},

	useUpsertTourLeg: () => {
		return useMutation({
			mutationFn: async (tourLeg: CreateTourLeg) => {
				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.upsert(tourLeg)
					.eq("tour_id", tourLeg.tour_id)
					.eq("day", tourLeg.day)
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to update tour leg");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useCreateTourLeg: () => {
		return useMutation({
			mutationFn: async (tourLeg: CreateTourLeg) => {
				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.insert(tourLeg)
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to create tour leg");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useUpdateTourLeg: () => {
		return useMutation({
			mutationFn: async (tourLeg: UpdateTourLeg & { id: string }) => {
				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.update(tourLeg)
					.eq("id", tourLeg.id)
					.select("*")
					.throwOnError()
					.single();

				if (data == null) {
					throw new Error("Failed to update tour leg");
				}

				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useTourLegsByRouteId: (routeId: string) => {
		return useQuery({
			queryKey: QueryKeys.byRouteId(routeId),
			queryFn: async () => {
				const { data } = await SupabaseService.instance()
					.from("tour_leg")
					.select("*")
					.eq("route_id", routeId);

				return data ?? [];
			},
		});
	},
};
