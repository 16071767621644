import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { FunctionComponent, ReactNode } from "react";
import { ImageSelector } from "../image-selector/image-selector";
import { Button } from "../ui/button";

export type ImageSelectorFieldProps = {
	name: string;
	// placeholder: string;
	label: ReactNode;
	description?: ReactNode;
};

export const ImageSelectorField: FunctionComponent<ImageSelectorFieldProps> = (
	props,
) => {
	const { name, label, description } = props;
	return (
		<FormField
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<ImageSelector
							onChange={field.onChange}
							initialSelectedFiles={field.value}
						>
							<Button>Select images</Button>
						</ImageSelector>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
