import { Badge } from "@/components/ui/badge.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Folder } from "lucide-react";
import { FunctionComponent } from "react";
import { FileExplorerService } from "../services/file-explorer.service";

export const FolderPicker: FunctionComponent = () => {
	const { folderStack, setFolderStack, folders } =
		FileExplorerService.useState();

	return (
		<div className={"w-full"}>
			<div className={"flex w-full gap-1"}>
				{folderStack.map((path, i) => (
					<Badge
						// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
						key={`${path}-${i}`}
						onClick={() => setFolderStack((prev) => prev.slice(0, i + 1))}
					>
						{path === "" ? "/" : path}
					</Badge>
				))}
			</div>

			{/*<Button onClick={() => setFolderStack([""])} disabled={isRoot}>*/}
			{/*	Root*/}
			{/*</Button>*/}

			{/*<Button onClick={() => setFolderStack((prev) => prev.slice(0, -1))} disabled={isRoot}>*/}
			{/*	Back*/}
			{/*</Button>*/}

			<div className="space-y-1 py-2">
				{folders.map((folder, i) => (
					<Button
						key={`${folder.id}-${i}`}
						variant="ghost"
						className="w-full px-1 cursor-pointer justify-start relative"
						onClick={() => setFolderStack((prev) => [...prev, folder.name])}
					>
						<Folder className="w-6 h-6 mr-2" />
						{folder.name}
					</Button>
				))}
			</div>
		</div>
	);
};
