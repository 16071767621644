import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useDialogState } from "@/hooks/use-dialog-state";
import { TourDateQueries } from "@/modules/tours/queries/tour-date.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { Tour } from "@repo/types";
import { format } from "date-fns";
import { FunctionComponent, PropsWithChildren, useState } from "react";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type CreateTourDateDialogProps = PropsWithChildren<{
	tour: Tour;
}>;

export const CreateTourDateDialog: FunctionComponent<
	CreateTourDateDialogProps
> = (props) => {
	const { children, ...otherProps } = props;
	const dialogState = useDialogState();

	return (
		<Dialog {...dialogState}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent className={"w-auto max-w-2xl"}>
				<DialogHeader>
					<DialogTitle>Create tour date</DialogTitle>
				</DialogHeader>

				{dialogState.open && (
					<CreateTourDialogContent {...otherProps} dialogState={dialogState} />
				)}
			</DialogContent>
		</Dialog>
	);
};

const formValidation = z.object({
	error: z.string().optional().nullable(),
	pillion_amount: z.number().int().positive(),
	single_room_amount: z.number().int().positive(),
});

type FormSchema = z.infer<typeof formValidation>;

export const CreateTourDialogContent: FunctionComponent<
	CreateTourDateDialogProps & {
		dialogState: ReturnType<typeof useDialogState>;
	}
> = ({ tour, dialogState }) => {
	const createTourDateMutation = TourDateQueries.useCreateTourDateMutation();

	const form = useForm<FormSchema>({
		defaultValues: {
			error: null,
		},
		resolver: zodResolver(formValidation),
	});

	const [selectedDateRange, setSelectedDateRange] = useState<
		DateRange | undefined
	>(undefined);

	const onSubmit = async ({
		pillion_amount,
		single_room_amount,
	}: FormSchema) => {
		await createTourDateMutation.mutateAsync({
			tour_id: tour.id,
			start: format(selectedDateRange?.from as Date, "yyyy-MM-dd"),
			finish: format(selectedDateRange?.to as Date, "yyyy-MM-dd"),
			pillion_amount,
			single_room_amount,
			status: "INACTIVE",
		});
		dialogState.closeDialog();
		toast.success("Tour date created");
	};

	return (
		<Form {...form}>
			<div className={"flex items-center w-full"}>
				<Calendar
					mode="range"
					min={tour.number_of_days}
					max={tour.number_of_days}
					selected={selectedDateRange}
					onSelect={setSelectedDateRange}
					numberOfMonths={2}
				/>
			</div>
			<FormField
				name="pillion_amount"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Pillion amount</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder="$2568"
								type={"number"}
								onChange={(e) => {
									field.onChange(Number(e.target.value));
								}}
							/>
						</FormControl>
						<FormDescription />
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				name="single_room_amount"
				render={({ field }) => (
					<FormItem>
						<FormLabel>Singe room amount</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder="$2568"
								type={"number"}
								onChange={(e) => {
									field.onChange(Number(e.target.value));
								}}
							/>
						</FormControl>
						<FormDescription />
						<FormMessage />
					</FormItem>
				)}
			/>

			<Button
				onClick={form.handleSubmit(onSubmit)}
				type="submit"
				isLoading={form.formState.isSubmitting}
				disabled={
					selectedDateRange?.to == null || selectedDateRange?.from == null
				}
			>
				Create tour date
			</Button>
		</Form>
	);
};
