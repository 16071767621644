import { Loading } from "@/components/ui/loading";
import { TourDetailsForm } from "@/modules/tours/components/tour-details-form.tsx";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Route } from "@/routes/tours/$tourId/index.tsx";
import { FunctionComponent } from "react";

export const TourDetailsRoute: FunctionComponent = () => {
	const { tourId } = Route.useParams();

	const { data: tour } = TourQueries.useTour(tourId);

	if (tour == null) {
		return <Loading />;
	}

	return <TourDetailsForm key={tour.id} tour={tour} />;
};
