"use client";

import { cn } from "@/lib/utils";
import { Loader as Loader2 } from "lucide-react";
import { FunctionComponent } from "react";

export type SpinnerProps = {
	size?: number;
	className?: string;
};

export const Spinner: FunctionComponent<SpinnerProps> = (props) => {
	const { size = 6, className } = props;
	return (
		<Loader2
			className={cn(
				`h-${size} w-${size} animate-spin stroke-gray-600`,
				className,
			)}
		/>
	);
};
