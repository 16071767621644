import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select.tsx";
import { BikeFormSchema } from "@/modules/bikes/components/bike-details-form.tsx";
import { FunctionComponent } from "react";

export const BikeStatusField: FunctionComponent = () => {
	return (
		<FormField<BikeFormSchema, "status">
			name="status"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Status</FormLabel>
					<FormControl>
						<Select value={field.value ?? ""} onValueChange={field.onChange}>
							<SelectTrigger className="w-[180px]">
								<SelectValue defaultValue={field.value ?? ""} />
							</SelectTrigger>
							<SelectContent>
								<SelectItem value="INACTIVE">Inactive</SelectItem>
								<SelectItem value="ACTIVE">Active</SelectItem>
								<SelectItem value="DELETED">Deleted</SelectItem>
							</SelectContent>
						</Select>
					</FormControl>
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
