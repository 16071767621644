import { Review } from "@repo/types";
import { SupabaseService } from "./supabase.service";

export class ReviewService {
	private supabase: SupabaseService;

	constructor(supabase: SupabaseService) {
		this.supabase = supabase;
	}

	async getReviews(): Promise<Review[]> {
		const { data } = await this.supabase
			.instance()
			.from("review")
			.select("*")
			.order("date", { ascending: false })
			.throwOnError();

		return data ?? [];
	}

	async getReviewsForTourId(tourId: string): Promise<Review[]> {
		const { data } = await this.supabase
			.instance()
			.from("review")
			.select("*")
			.eq("tour_id", tourId);

		return data ?? [];
	}
}
