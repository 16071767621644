import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { slugify } from "@repo/api";
import { FunctionComponent } from "react";

export const PathField: FunctionComponent = () => {
	return (
		<FormField
			name="path"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Folder name</FormLabel>
					<FormControl>
						<Input
							{...field}
							onChange={(e) => {
								const slugified = slugify(e.target.value);
								field.onChange(slugified);
							}}
							placeholder="bikes .... tours .... scenic "
						/>
					</FormControl>
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
