import { Button } from "@/components/ui/button.tsx";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Separator } from "@/components/ui/separator.tsx";
import { Plus, X } from "lucide-react";
import { ReactNode, useState } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { Input } from "../ui/input";
import { FieldDescription } from "./field-description";

export type MultiTextFieldProps<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
> = {
	name: TName;
	label: ReactNode;
	description?:
		| ReactNode
		| ((field: ControllerRenderProps<TFieldValues, TName>) => ReactNode);
	placeholder?: string;
};

export function MultiTextField<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
>(props: MultiTextFieldProps<TFieldValues, TName>) {
	const { name, label, description, placeholder } = props;

	const [text, setText] = useState<string>("");

	return (
		<FormField<TFieldValues, TName>
			name={name}
			render={({ field }) => (
				<FormItem className={"w-full"}>
					<FormLabel>{label}</FormLabel>
					<FieldDescription<TFieldValues, TName> field={field}>
						{description}
					</FieldDescription>
					<FormMessage />
					<FormControl>
						<div className={"flex flex-col gap-4 w-full"}>
							<div className={"flex flex-row w-full gap-4"}>
								<Input
									className={"flex-1"}
									placeholder={placeholder}
									value={text}
									onChange={(e) => {
										setText(e.target.value);
									}}
								/>
								<Button
									size={"icon"}
									variant={"secondary"}
									onClick={() => {
										field.onChange([...field.value, text]);
										setText("");
									}}
								>
									<Plus />
								</Button>
							</div>
							<Separator />
							<div className={"flex flex-col gap-2"}>
								{(field.value as string[]).map((f, i) => {
									return (
										<div
											key={`${i}-${f}`}
											className={"flex flex-row w-full gap-4"}
										>
											<FormField
												name={`${name}.${i}`}
												render={(fieldProps) => (
													<Input
														{...fieldProps.field}
														className={"flex-1"}
														placeholder={placeholder}
													/>
												)}
											/>
											<Button
												size={"icon"}
												variant={"secondary"}
												onClick={() => {
													field.onChange(
														(field.value as string[]).filter(
															(_, index) => index !== i,
														),
													);
												}}
											>
												<X />
											</Button>
										</div>
									);
								})}
							</div>
						</div>
					</FormControl>
				</FormItem>
			)}
		/>
	);
}
