import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { FunctionComponent } from "react";
import { TourFormSchema } from "./tour-details-form";

export const TourDaysFields: FunctionComponent = () => {
	return (
		<div className={"flex gap-4 max-w-xs"}>
			<FormField<TourFormSchema, "number_of_days">
				name="number_of_days"
				render={({ field }) => (
					<FormItem className={"flex-1"}>
						<FormLabel>Tour length</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder="24"
								type={"number"}
								suffix={"days"}
								value={`${Number(field.value)}`}
								onChange={(e) => field.onChange(Number(e.target.value))}
							/>
						</FormControl>
						<FormDescription />
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField<TourFormSchema, "number_of_days_riding">
				name="number_of_days_riding"
				render={({ field }) => (
					<FormItem className={"flex-1"}>
						<FormLabel>Riding days</FormLabel>
						<FormControl>
							<Input
								{...field}
								placeholder="18"
								type={"number"}
								suffix={"days"}
								value={`${Number(field.value)}`}
								onChange={(e) => field.onChange(Number(e.target.value))}
							/>
						</FormControl>
						<FormDescription />
						<FormMessage />
					</FormItem>
				)}
			/>
		</div>
	);
};
