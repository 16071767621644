import { Dashboard } from "@/components/dashboard";
import { Auth } from "@/modules/auth/routes/auth.route";
import { AuthService } from "@/modules/auth/services/auth.service";
import { Outlet, createRootRoute } from "@tanstack/react-router";

export const Route = createRootRoute({
	component: () => {
		return <Root />;
	},
});

const Root = () => {
	const { isAuthenticated } = AuthService.useState();

	if (!isAuthenticated) {
		return <Auth />;
	}

	return (
		<>
			<Dashboard>
				<Outlet />
			</Dashboard>
		</>
	);
};
