import {CreateRegion, Region, UpdateRegion} from "@repo/types";
import {SupabaseService} from "./supabase.service";

export class RegionService {
	private supabase: SupabaseService;

	constructor(supabase: SupabaseService) {
		this.supabase = supabase;
	}

	async getRegions(): Promise<Region[]> {
		const { data } = await this.supabase
			.instance()
			.from("region")
			.select("*")
			.throwOnError();
		return data ?? [];
	}

	async getRegionBySlug(slug: string) {
		const { data } = await this.supabase
			.instance()
			.from("region")
			.select("*")
			.eq("slug", slug)
			.single()
			.throwOnError();

			if (data === null) {
				throw new Error("Region not found");
			}

		return data;
	}

	async getRegionById(id: string) {
		const { data } = await this.supabase
			.instance()
			.from("region")
			.select("*")
			.eq("id", id)
			.single()
			.throwOnError()

			if (data === null) {
				throw new Error("Region not found");
			}

		return data;
	}

	async createRegion(createRegion: CreateRegion) {
		const { data } = await this.supabase
			.instance()
			.from("region")
			.insert(createRegion)
			.select("*")
			.single()
			.throwOnError();

			if (data === null) {
				throw new Error("Failed to create region");
			}

		return data;
	}

	async updateRegion(region: UpdateRegion) {
		const { data } = await this.supabase
			.instance()
			.from("region")
			.update(region)
			.eq("id", region.id)
			.select("*")
			.single()
			.throwOnError();

		if (data === null) {
			throw new Error("Failed to update region");
		}

		return data;
	}
}
