import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useMemo, useState } from "react";

import { OpenroadClientService } from "@/services/openroad-client.service";
import { FileObject as IFileObject } from "@supabase/storage-js/dist/main/lib/types";

export type ExplorerFile = IFileObject & {
	folderStack: string[];
	fullPath: string;
	isSelected: boolean;
};

export type FileExplorerServiceContextType = {
	folderStack: string[];
	setFolderStack: React.Dispatch<React.SetStateAction<string[]>>;
	selectedFiles: string[];
	setSelectedFiles: React.Dispatch<React.SetStateAction<string[]>>;

	isLoading: boolean;
	files: ExplorerFile[];
	folders: ExplorerFile[];
};

const Context = createContext<FileExplorerServiceContextType | null>(null);

export const FileExplorerService = {
	Context,
	Provider: Context.Provider,

	useState() {
		const state = useContext(Context);
		if (state == null) {
			throw new Error("FileExplorerService not found");
		}
		return state;
	},

	useProvidedState(): FileExplorerServiceContextType {
		const [folderStack, setFolderStack] = useState<string[]>([""]);
		const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

		const pathContents = this.usePathContents(folderStack);

		const { files, folders } = useMemo<{
			files: ExplorerFile[];
			folders: ExplorerFile[];
		}>(() => {
			return {
				files:
					pathContents.data?.files?.map((file) => ({
						...file,
						isSelected: selectedFiles.includes(file.fullPath),
					})) ?? [],

				folders:
					pathContents.data?.folders?.map((folder) => ({
						...folder,
						isSelected: selectedFiles.includes(folder.fullPath),
					})) ?? [],
			};
		}, [pathContents.data?.files, pathContents.data?.folders, selectedFiles]);

		return {
			folderStack,
			setFolderStack,
			selectedFiles,
			setSelectedFiles,

			isLoading: pathContents.isLoading,
			files,
			folders,
		};
	},

	usePathContents(folderStack: string[]) {
		const { client } = OpenroadClientService.useState();

		return useQuery<{
			files: Omit<ExplorerFile, "isSelected">[];
			folders: Omit<ExplorerFile, "isSelected">[];
		}>({
			queryKey: ["folder", folderStack],
			queryFn: async () => {
				const { data } = await client.supabase
					.instance()
					.storage.from("global")
					.list(this.buildFullPath(folderStack, ""));

				const fileObject = data ?? [];
				const folders: Omit<ExplorerFile, "isSelected">[] = [];
				const files: Omit<ExplorerFile, "isSelected">[] = [];

				for (let i = 0; i < fileObject.length; i++) {
					const file = fileObject[i];

					if (file.id == null) {
						folders.push({
							...file,
							folderStack,
							fullPath: this.buildFullPath(folderStack, file.name),
						});
					} else {
						files.push({
							...file,
							folderStack,
							fullPath: this.buildFullPath(folderStack, file.name),
						});
					}
				}

				return {
					folders,
					files,
				};
			},
		});
	},

	buildFullPath(folderStack: string[], fileName: string) {
		return `${
			folderStack.length > 1 ? `${folderStack.join("/").substring(1)}/` : ""
		}${fileName}`;
	},
};
