import { DataTable } from "@/components/ui/data-table.tsx";
import { BikeQueries } from "@/modules/bikes/queries/bike.queries";
import { TourDate } from "@repo/types";
import { FunctionComponent, useMemo } from "react";
import { columns } from "./columns";

export type TourDatePricesTableProps = {
	tourDate: TourDate;
};

export const TourDatePricesTable: FunctionComponent<TourDatePricesTableProps> =
	(props) => {
		const { tourDate } = props;
		const bikesQuery = BikeQueries.useBikes();

		const bikes = useMemo(() => {
			const allBikes = bikesQuery.data ?? [];

			return allBikes.map((bike) => {
				return {
					...bike,
					tourDate,
				};
			});
		}, [bikesQuery.data, tourDate]);

		return (
			<DataTable
				columns={columns}
				data={bikes}
				// onRowClick={(data) => {
				// 	void navigate({
				// 		to: "/tours/$tourId",
				// 		params: { tourId: data.id },
				// 	});
				// }}
			/>
		);
	};
