import { Badge, BadgeProps } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Popover } from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { TourDatePricesTable } from "@/modules/tours/components/tour-date-prices-table/tour-date-prices-table";
import { CreateTourDateDialog } from "@/modules/tours/dialogs/create-tour-date.dialog";
import { TourDateQueries } from "@/modules/tours/queries/tour-date.queries";
import { Tour, TourDate, TourDateStatusEnum } from "@repo/types";
import { format, parse, parseISO } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { ActiveModifiers } from "react-day-picker";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type TourDateCalendarProps = {
	tour: Tour;
};

export const TourDateCalendar: FunctionComponent<TourDateCalendarProps> = (
	props,
) => {
	const { tour } = props;

	// const createTourDateMutation = TourDateQueries.useCreateTourDateMutation();

	const { data: tourDates } = TourDateQueries.useTourDatesByTourId(tour.id);

	const { tourDateRanges, tourDateStartingDates, tourDateEndingDates } =
		useMemo(() => {
			const tourDateRanges =
				tourDates?.map((tourDate) => {
					return {
						from: new Date(tourDate.start),
						to: new Date(tourDate.finish),
					};
				}) ?? [];

			const tourDateStartingDates = tourDateRanges.map(
				(tourDate) => tourDate.from,
			);
			const tourDateEndingDates = tourDateRanges.map((tourDate) => tourDate.to);

			return {
				tourDateRanges,
				tourDateStartingDates,
				tourDateEndingDates,
			};
		}, [tourDates]);

	const [selectedDay, setSelectedDay] = useState<Date | null>(null);

	const getTourDateForDay = useCallback(
		(day: Date) => {
			return tourDates?.find((tourDate) => {
				return (
					new Date(tourDate.start).getTime() <= day.getTime() &&
					new Date(tourDate.finish).getTime() >= day.getTime()
				);
			});
		},
		[tourDates],
	);

	const selectedTourDate = useMemo(() => {
		if (selectedDay == null) {
			return null;
		}

		return getTourDateForDay(selectedDay);
	}, [getTourDateForDay, selectedDay]);

	// const selectedTourDateRange = useMemo(() => {
	// 	if (selectedDay == null) {
	// 		return [];
	// 	}
	//
	// 	if (selectedTourDate == null) {
	// 		return [];
	// 	}
	//
	// 	return {
	// 		from: new Date(selectedTourDate.start),
	// 		to: new Date(selectedTourDate.finish),
	// 	};
	// }, [selectedDay, selectedTourDate]);
	//
	const onDayClick = (day: Date, modifiers: ActiveModifiers) => {
		if (modifiers.selectedTourDateRange) {
			setSelectedDay(null);
			return;
		}

		setSelectedDay(day);
	};

	if (tourDates == null) {
		return <Loading />;
	}

	return (
		<div className="w-full flex flex-col">
			<div className="flex py-4 w-full justify-between max-w-[812px]">
				<CreateTourDateDialog tour={tour}>
					<Button>Create Tour Date</Button>
				</CreateTourDateDialog>
			</div>

			<div className="flex flex-col gap-4 w-full">
				<ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
					{tourDates.map((tourDate) => (
						<li
							key={tourDate.id}
							className="relative flex space-x-6 py-6 px-4 cursor-pointer
								hover:bg-gray-50 xl:py-4"
							onKeyDown={() => {}}
							onClick={() => setSelectedDay(new Date(tourDate.start))}
						>
							<div className="flex-auto flex justify-between max-w-3xl">
								<span className="font-semibold text-gray-900 flex items-center gap-2">
									<CalendarIcon
										className="h-5 w-5 text-gray-900"
										aria-hidden="true"
									/>
									{new Date(tourDate.start).toDateString()} -{" "}
									{new Date(tourDate.finish).toDateString()}
								</span>
								<Badge variant={getBadgeVariant(tourDate.status)}>
									{tourDate.status}
								</Badge>
							</div>
						</li>
					))}
				</ol>

				<Calendar
					showOutsideDays={false}
					className={"max-w-xs"}
					classNames={
						{
							// months: "flex flex-col"
						}
					}
					max={tour.number_of_days}
					numberOfMonths={3}
					selected={selectedDay ?? undefined}
					modifiers={{
						tourDateRanges,
						tourDateStartingDates,
						tourDateEndingDates,
						// selectedTourDateRange,
					}}
					onDayClick={onDayClick}
					modifiersStyles={{
						tourDateRanges: {
							// backgroundColor: "rgba(0, 0, 0, 0.1)",
							borderRadius: 0,
							// borderColor: "hsl(var(--primary))",
							// borderWidth: 1,
							backgroundColor: "hsl(142.1 76.2% 32.3%)",
							color: "white",
						},
						tourDateStartingDates: {
							color: "white",
							backgroundColor: "hsl(var(--primary))",
							borderTopLeftRadius: "calc(var(--radius) - 2px)",
							borderBottomLeftRadius: "calc(var(--radius) - 2px)",
						},
						tourDateEndingDates: {
							backgroundColor: "hsl(var(--primary))",
							color: "white",
							borderTopRightRadius: "calc(var(--radius) - 2px)",
							borderBottomRightRadius: "calc(var(--radius) - 2px)",
						},
						selectedTourDateRange: {
							backgroundColor: "hsl(var(--primary))",
							color: "white",
						},
					}}
				/>

				<Dialog
					open={selectedTourDate != null}
					onOpenChange={() => setSelectedDay(null)}
				>
					<DialogContent
						className={"w-full max-w-3xl max-h-full flex flex-col"}
					>
						{selectedTourDate != null && (
							<TourDatePrices
								key={selectedTourDate.id}
								tour={tour}
								selectedTourDate={selectedTourDate}
								onSave={() => setSelectedDay(null)}
							/>
						)}
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};

const getBadgeVariant = (
	status: TourDateStatusEnum,
): BadgeProps["variant"] | undefined => {
	switch (status) {
		case "ACTIVE":
			return "default";
		case "INACTIVE":
			return "secondary";
		case "DELETED":
			return "secondary";
		case "FULL":
			return "destructive";
		case "CANCELLED":
			return "secondary";
		case "LIMITED_SPACES":
			return "default";
	}
};

const formValidation = z.object({
	pillion_amount: z.number().int().positive(),
	single_room_amount: z.number().int().positive(),
	bikePrices: z.array(
		z.object({
			bike_id: z.string(),
			amount: z.number().int().positive(),
		}),
	),
	status: z.enum([
		"ACTIVE",
		"INACTIVE",
		"DELETED",
		"FULL",
		"CANCELLED",
		"LIMITED_SPACES",
	]),
	start: z.date(),
	finish: z.date(),
});

export type FormSchema = z.infer<typeof formValidation>;

const TourDatePrices: FunctionComponent<{
	tour: Tour;
	selectedTourDate: TourDate;
	onSave: () => void;
}> = ({ selectedTourDate, onSave }) => {
	const updateTourDateMutation = TourDateQueries.useUpdateTourDateMutation();

	const form = useForm<FormSchema>({
		defaultValues: {
			...selectedTourDate,
			start: parseISO(selectedTourDate.start),
			finish: parseISO(selectedTourDate.finish),
		},
	});

	const onSubmit = async (values: FormSchema) => {
		const tourDate = await updateTourDateMutation.mutateAsync({
			id: selectedTourDate.id,
			...values,
			start: format(values.start, "yyyy-MM-dd"),
			finish: format(values.finish, "yyyy-MM-dd"),
		});

		form.reset({
			...tourDate,
			start: parse(tourDate.start, "yyyy-MM-dd", new Date()),
			finish: parse(tourDate.finish, "yyyy-MM-dd", new Date()),
		});
		toast.success("Tour date updated");
		onSave();
	};

	return (
		<Form {...form}>
			<DialogHeader className={"flex flex-col"}>
				<DialogTitle>Edit tour date</DialogTitle>
				<DialogDescription>
					{new Date(selectedTourDate.start).toDateString()} -{" "}
					{new Date(selectedTourDate.finish).toDateString()}
				</DialogDescription>
			</DialogHeader>
			<div className={"flex flex-col w-full gap-4 flex-1 overflow-y-auto p-4 "}>
				<div className={"flex flex-row w-full gap-4 items-center"}>
					<FormField
						name="status"
						render={({ field }) => (
							<FormItem className={"max-w-[300px]"}>
								<FormLabel>Status</FormLabel>
								<FormControl>
									<Select value={field.value} onValueChange={field.onChange}>
										<SelectTrigger>
											<SelectValue defaultValue={field.value} />
										</SelectTrigger>
										<SelectContent className={"z-[2000]"}>
											<SelectItem value={"ACTIVE"}>Active</SelectItem>
											<SelectItem value={"LIMITED_SPACES"}>
												Limited Spaces
											</SelectItem>
											<SelectItem value={"FULL"}>Full</SelectItem>
											<SelectItem value={"INACTIVE"}>Inactive</SelectItem>
											<SelectItem value={"DELETED"}>Deleted</SelectItem>
											<SelectItem value={"CANCELLED"}>Cancelled</SelectItem>
										</SelectContent>
									</Select>
								</FormControl>
								<FormDescription>
									{field.value === "ACTIVE" &&
										"Tour date is showing to customers"}
									{field.value === "INACTIVE" &&
										"Tour date is not showing to customers"}
									{field.value === "DELETED" &&
										"Tour date is deleted and not showing to customers"}
									{field.value === "FULL" && "Tour date is full"}
									{field.value === "CANCELLED" &&
										"Tour date is cancelled and not showing to customers"}
									{field.value === "LIMITED_SPACES" &&
										"Tour date is not showing to customers with limited spaces"}
								</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className={"grid grid-cols-2 gap-4"}>
					<FormField
						control={form.control}
						name="start"
						render={({ field }) => (
							<FormItem className="flex flex-col">
								<FormLabel>Start Date</FormLabel>
								<Popover>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												variant={"outline"}
												className={cn(
													"w-[240px] pl-3 text-left font-normal",
													!field.value && "text-muted-foreground",
												)}
											>
												{field.value ? (
													format(field.value, "PPP")
												) : (
													<span>Pick a date</span>
												)}
												<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" align="start">
										<Calendar
											mode="single"
											selected={field.value}
											defaultMonth={field.value}
											onSelect={field.onChange}
										/>
									</PopoverContent>
								</Popover>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="finish"
						render={({ field }) => (
							<FormItem className="flex flex-col">
								<FormLabel>Finish Date</FormLabel>
								<Popover>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												variant={"outline"}
												className={cn(
													"w-[240px] pl-3 text-left font-normal",
													!field.value && "text-muted-foreground",
												)}
											>
												{field.value ? (
													format(field.value, "PPP")
												) : (
													<span>Pick a date</span>
												)}
												<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0" align="start">
										<Calendar
											mode="single"
											selected={field.value}
											defaultMonth={field.value}
											onSelect={field.onChange}
										/>
									</PopoverContent>
								</Popover>
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>
				<div className={"flex flex-row w-full gap-4 items-end"}>
					<FormField
						name="pillion_amount"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Pillion amount</FormLabel>
								<FormControl>
									<Input
										{...field}
										placeholder="$2568"
										type={"number"}
										onChange={(e) => field.onChange(Number(e.target.value))}
									/>
								</FormControl>
								<FormDescription />
								<FormMessage />
							</FormItem>
						)}
					/>

					<FormField
						name="single_room_amount"
						render={({ field }) => (
							<FormItem>
								<FormLabel>Singe room amount</FormLabel>
								<FormControl>
									<Input {...field} placeholder="$2568" type={"number"} />
								</FormControl>
								<FormDescription />
								<FormMessage />
							</FormItem>
						)}
					/>
				</div>

				<TourDatePricesTable tourDate={selectedTourDate} />
			</div>

			<DialogFooter>
				<Button
					onClick={form.handleSubmit(onSubmit)}
					isLoading={updateTourDateMutation.isPending}
					disabled={!form.formState.isDirty || updateTourDateMutation.isPending}
				>
					Save
				</Button>
			</DialogFooter>
		</Form>
	);
};
