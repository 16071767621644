"use client";

import { OpenroadClientService } from "@/services/openroad-client.service";
import { CreateRegion, Region, UpdateRegion } from "@repo/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["region"] as const,
	regions: () => [...QueryKeys.root(), "list"] as const,
	regionBySlug: (slug: string) => [...QueryKeys.root(), "slug", slug] as const,
};

export const RegionQueries = {
	QueryKeys,

	useRegions: () => {
		const { client } = OpenroadClientService.useState();
		return useQuery<Region[]>({
			initialData: [],
			queryKey: QueryKeys.regions(),
			queryFn: async () => {
				return client.regions.getRegions();
			},
		});
	},

	useRegionBySlug: (slug: string) => {
		const { client } = OpenroadClientService.useState();

		return useQuery({
			queryKey: QueryKeys.regionBySlug(slug),
			queryFn: async () => {
				return client.regions.getRegionBySlug(slug);
			},
		});
	},

	useCreateRegion: () => {
		const { client } = OpenroadClientService.useState();
		const queryClient = useQueryClient();
		return useMutation({
			mutationFn: async (region: CreateRegion) => {
				return client.regions.createRegion(region);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.regions(),
				});
			},
		});
	},

	useUpdateRegion: () => {
		const { client } = OpenroadClientService.useState();
		const queryClient = useQueryClient();
		return useMutation({
			mutationFn: async (region: UpdateRegion) => {
				return client.regions.updateRegion(region);
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.regions(),
				});
			},
		});
	},
};
