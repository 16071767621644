import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	useDialogContext,
} from "@/components/ui/dialog";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { StarRating } from "@/modules/reviews/components/star-rating";
import { ReviewQueries } from "@/modules/reviews/queries/review.queries";
import { TourSelector } from "@/modules/tours/components/tour-selector";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { FunctionComponent, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type CreateReviewDialogProps = PropsWithChildren;

export const CreateReviewDialog: FunctionComponent<CreateReviewDialogProps> = (
	props,
) => {
	const { children } = props;
	return (
		<Dialog>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<CreateReviewDialogContent />
			</DialogContent>
		</Dialog>
	);
};

const FormSchema = z.object({
	tour_id: z.string().nullable(),
	date: z.date(),
	name: z.string(),
	country: z.string(),
	rating: z.number(),
	text: z.string(),
});

export type FieldValues = z.infer<typeof FormSchema>;

const CreateReviewDialogContent: FunctionComponent<CreateReviewDialogProps> =
	() => {
		const { close } = useDialogContext();

		const form = useForm<FieldValues>({
			defaultValues: {
				tour_id: null,
				date: new Date(),
				name: "",
				country: "",
				rating: 5,
				text: "",
			},
		});

		const createReviewMutation = ReviewQueries.useCreateReview();

		const onSubmit = async (values: FieldValues) => {
			await createReviewMutation.mutateAsync({
				name: values.name,
				text: values.text,
				rating: values.rating,
				country: values.country,
				date: format(values.date, "yyyy-MM-dd"),
			});
			close();
			toast.success("Review created");
		};

		return (
			<>
				<DialogHeader>
					<DialogTitle>Create review</DialogTitle>
				</DialogHeader>

				<Form {...form}>
					<FormField
						control={form.control}
						name="date"
						render={({ field }) => (
							<FormItem className="flex flex-col">
								<FormLabel>Date</FormLabel>
								<Popover>
									<PopoverTrigger asChild>
										<FormControl>
											<Button
												variant={"outline"}
												className={cn(
													"w-[240px] pl-3 text-left font-normal",
													!field.value && "text-muted-foreground",
												)}
											>
												{field.value ? (
													format(field.value, "PPP")
												) : (
													<span>Pick a date</span>
												)}
												<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
											</Button>
										</FormControl>
									</PopoverTrigger>
									<PopoverContent className="w-auto p-0 z-50" align="start">
										<Calendar
											mode="single"
											selected={field.value}
											onSelect={field.onChange}
											initialFocus
										/>
									</PopoverContent>
								</Popover>
								<FormDescription>Date of the review</FormDescription>
								<FormMessage />
							</FormItem>
						)}
					/>
					<TourSelectorField />

					<FormField<FieldValues, "name">
						name="name"
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Name</FormLabel>
									<FormControl>
										<Input {...field} placeholder="John Wick" />
									</FormControl>
								</FormItem>
							);
						}}
					/>

					<FormField<FieldValues, "country">
						name="country"
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Country</FormLabel>
									<FormControl>
										<Input {...field} placeholder="New Zealand" />
									</FormControl>
								</FormItem>
							);
						}}
					/>

					<FormField<FieldValues, "rating">
						name="rating"
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Rating</FormLabel>
									<FormControl>
										<StarRating
											rating={field.value}
											onRatingChange={field.onChange}
										/>
									</FormControl>
								</FormItem>
							);
						}}
					/>

					<FormField<FieldValues, "text">
						name="text"
						render={({ field }) => {
							return (
								<FormItem>
									<FormLabel>Rating</FormLabel>
									<FormControl>
										<Textarea
											{...field}
											className={"h-[300px]"}
											placeholder="....."
										/>
									</FormControl>
								</FormItem>
							);
						}}
					/>
				</Form>

				<DialogFooter>
					<DialogClose asChild>
						<Button variant={"secondary"}>Cancel</Button>
					</DialogClose>
					<Button
						disabled={!form.formState.isDirty || form.formState.isSubmitting}
						isLoading={form.formState.isSubmitting}
						onClick={form.handleSubmit(onSubmit)}
					>
						Create review
					</Button>
				</DialogFooter>
			</>
		);
	};

const TourSelectorField: FunctionComponent = () => {
	return (
		<FormField<FieldValues, "tour_id">
			name="tour_id"
			render={({ field }) => {
				return (
					<FormItem>
						<FormLabel>Tour</FormLabel>
						<FormControl>
							<TourSelector
								onTourSelect={(tour) =>
									field.onChange(tour != null ? tour.id : null)
								}
								selectedTourId={
									field.value != null && field.value !== "" ? field.value : null
								}
								requiredText={"No tour selected"}
							/>
						</FormControl>
						<FormDescription>Optional</FormDescription>
						{/*<FormLabel className="font-normal">{item.label}</FormLabel>*/}
					</FormItem>
				);
			}}
		/>
	);
};
