import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { ReactNode } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FieldDescription } from "./field-description";

export type RichTextFieldProps<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
> = {
	name: TName;
	label: ReactNode;
	description?:
		| ReactNode
		| ((field: ControllerRenderProps<TFieldValues, TName>) => ReactNode);
};

export function RichTextField<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
>(props: RichTextFieldProps<TFieldValues, TName>) {
	const { name, label, description } = props;
	return (
		<FormField<TFieldValues, TName>
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<div className={"flex-1 relative"}>
							<ReactQuill
								className={"h-full"}
								theme="snow"
								value={field.value}
								onChange={field.onChange}
							/>
						</div>
					</FormControl>
					<FieldDescription<TFieldValues, TName> field={field}>
						{description}
					</FieldDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
}
