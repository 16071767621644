import { Button } from "@/components/ui/button.tsx";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog.tsx";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { useDialogState } from "@/hooks/use-dialog-state";
import { RouteQueries } from "@/modules/routes/queries/route.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { FunctionComponent, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type CreateRouteDialogProps = PropsWithChildren;

export const CreateRouteDialog: FunctionComponent<CreateRouteDialogProps> = (
	props,
) => {
	const { children } = props;
	const dialogState = useDialogState();

	return (
		<Dialog {...dialogState}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Create route</DialogTitle>
				</DialogHeader>

				{dialogState.open && (
					<CreateRouteDialogContent dialogState={dialogState} />
				)}
			</DialogContent>
		</Dialog>
	);
};

const formValidation = z.object({
	from: z.string(),
	to: z.string(),
});

type FormSchema = z.infer<typeof formValidation>;

export const CreateRouteDialogContent: FunctionComponent<
	CreateRouteDialogProps & {
		dialogState: ReturnType<typeof useDialogState>;
	}
> = ({ dialogState }) => {
	const createRouteMutation = RouteQueries.useCreateRoute();
	const navigate = useNavigate();

	const form = useForm<FormSchema>({
		defaultValues: {
			from: "",
			to: "",
		},
		resolver: zodResolver(formValidation),
	});

	const onSubmit = async (values: FormSchema) => {
		const route = await createRouteMutation.mutateAsync({
			from: values.from,
			to: values.to,
			name: `${values.from} to ${values.to}`,
			description: "",
			highlight: "",
			images: [],
			waypoints: [],
			breakfast_included: false,
			dinner_included: false,
			lunch_included: false,
		});

		toast.success("Route created");

		await navigate({
			to: "/routes/$routeId",
			params: {
				routeId: route.id,
			},
		});
		dialogState.closeDialog();
	};

	return (
		<Form {...form}>
			<FormField
				name="from"
				render={({ field }) => (
					<FormItem>
						<FormLabel>From destination:</FormLabel>
						<FormControl>
							<Input {...field} placeholder="Auckland" />
						</FormControl>
						<FormDescription />
						<FormMessage />
					</FormItem>
				)}
			/>

			<FormField
				name="to"
				render={({ field }) => (
					<FormItem>
						<FormLabel>To destination:</FormLabel>
						<FormControl>
							<Input {...field} placeholder="Taupo" />
						</FormControl>
						<FormDescription />
						<FormMessage />
					</FormItem>
				)}
			/>

			{createRouteMutation.error && (
				<span className={"text-red-500"}>
					{createRouteMutation.error.message}
				</span>
			)}
			<Button
				onClick={form.handleSubmit(onSubmit)}
				type="submit"
				isLoading={form.formState.isSubmitting}
			>
				Create route
			</Button>
		</Form>
	);
};
