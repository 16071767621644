import DirectionsClient, {
	DirectionsRequest,
	DirectionsResponse,
	DirectionsWaypoint,
	Route,
} from "@mapbox/mapbox-sdk/services/directions";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { LineString, Position } from "@turf/turf";

type Directions = DirectionsResponse<LineString>;

export type { Directions, DirectionsWaypoint, Route };

export class MapboxService {
	directionsClient = DirectionsClient({
		accessToken:
			"pk.eyJ1IjoidXJiYW5jaHJpc3kiLCJhIjoiY2xxMGZoazh1MTdybjJqcW5rZ3kxcWV5aCJ9.bMB2O5cRlBdc7xCjGVQVpA",
	});

	async getRoute(waypoints: Position[], options?: Partial<DirectionsRequest>) {
		const directionsWaypoints: DirectionsWaypoint[] = waypoints.map(
			(waypoint) => ({
				coordinates: [waypoint[0], waypoint[1]] as [number, number],
			}),
		);

		const data = await this.directionsClient
			.getDirections({
				overview: "full",
				waypoints: directionsWaypoints,
				continueStraight: true,
				profile: "driving",
				...options,
				geometries: "geojson",
			})
			.send();

		// DING DONG: This cast could cause problems if it returns a multi-line string
		return data.body as DirectionsResponse<LineString>;
	}
}
