import { Loading } from "@/components/ui/loading";
import { createFileRoute } from "@tanstack/react-router";
const Home = () => {
	return null;
};

export const Route = createFileRoute("/")({
	component: Home,
	pendingComponent: Loading,
});
