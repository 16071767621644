import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { useDialogState } from "@/hooks/use-dialog-state";
import { BikeNameField } from "@/modules/bikes/components/bike-name-field";
import { BikeSlugField } from "@/modules/bikes/components/bike-slug-field.tsx";
import { BikeQueries } from "@/modules/bikes/queries/bike.queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { slugify } from "@repo/api";
import { CreateBike } from "@repo/types";
import { useNavigate } from "@tanstack/react-router";
import { FunctionComponent, PropsWithChildren } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";

export type CreateBikeDialogProps = PropsWithChildren;

export const CreateBikeDialog: FunctionComponent<CreateBikeDialogProps> = (
	props,
) => {
	const { children } = props;
	const dialogState = useDialogState();

	return (
		<Dialog {...dialogState}>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Create bike</DialogTitle>
				</DialogHeader>

				<CreateBikeDialogContent />
			</DialogContent>
		</Dialog>
	);
};

const formValidation = z.object({
	name: z.string(),
	slug: z
		.string()
		.regex(
			/^[a-z0-9]+(?:-[a-z0-9]+)*$/,
			"Invalid slug. Must be all lowercase and contain only letters, numbers, and dashes",
		),
});

type FormSchema = z.infer<typeof formValidation>;

export const CreateBikeDialogContent: FunctionComponent<CreateBikeDialogProps> =
	() => {
		const navigate = useNavigate();
		const createBikeMutation = BikeQueries.useCreateBike();

		const form = useForm<CreateBike>({
			defaultValues: {
				name: "",
			},
			resolver: zodResolver(formValidation),
		});

		const onSubmit = async (values: FormSchema) => {
			const bike = await createBikeMutation.mutateAsync({
				...values,
				description: "",
				cc: 0,
				engine_configuration: "",
				final_drive: "",
				fuel_capacity: 0,
				luggage_system: "",
				other_features: "",
				power: 0,
				seat_height: 0,
				torque_nm: 0,
				torque_revs: 0,
				weight: 0,
				wind_protection: "",
				images: [],

				name: values.name ?? "",
				slug: values.slug ?? "",
			});

			toast.success("Bike created");

			await navigate({
				to: "/bikes/$bikeId",
				params: {
					bikeId: bike.id,
				},
			});
		};

		return (
			<Form {...form}>
				<BikeNameField
					onChange={(e) => {
						form.setValue("slug", slugify(e.target.value));
					}}
				/>
				<BikeSlugField />

				{createBikeMutation.error && (
					<span className={"text-red-500"}>
						{createBikeMutation.error.message}
					</span>
				)}
				<Button
					onClick={form.handleSubmit(onSubmit)}
					type="submit"
					isLoading={form.formState.isSubmitting}
				>
					Create bike
				</Button>
			</Form>
		);
	};
