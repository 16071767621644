import { FormDialog } from "@/components/form/form.dialog.tsx";
import { ImageSelectorField } from "@/components/form/image-selector.field.tsx";
import { InputField } from "@/components/form/inputField.tsx";
import { TextAreaField } from "@/components/form/text-area.field.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	useDialogContext,
} from "@/components/ui/dialog.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { Region } from "@repo/types";
import { FunctionComponent, PropsWithChildren } from "react";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import * as z from "zod";
import { RegionQueries } from "../../../queries/region.queries";

export type RegionDialogProps = PropsWithChildren<{
	region?: Region;
}>;

const formValidation = z.object({
	name: z.string().min(3),
	slug: z.string(),
	description: z.string(),
	images: z.array(z.string()).nullable(),
	guided_tour_images: z.array(z.string()).nullable(),
	self_guided_tour_images: z.array(z.string()).nullable(),
});

type FormSchema = z.infer<typeof formValidation>;

export const RegionDialog: FunctionComponent<RegionDialogProps> = (props) => {
	const { children, region } = props;

	const isEditing = Boolean(region);

	return (
		<FormDialog<FormSchema>
			className={"max-w-5xl max-h-[80%] flex flex-col p-0"}
			form={{
				defaultValues: region ?? {
					name: "",
					slug: "",
					description: "",
					guided_tour_images: [],
					self_guided_tour_images: [],
				},
				resolver: zodResolver(formValidation),
			}}
			trigger={children}
		>
			{(form) => (
				<>
					<DialogHeader className={"p-6"}>
						<DialogTitle>
							{isEditing ? "Edit region" : "Create region"}
						</DialogTitle>
						<DialogDescription>
							{isEditing
								? "Edit the details of the region"
								: "Enter the details of the new region"}
						</DialogDescription>
					</DialogHeader>

					<div className={"flex flex-1 flex-col overflow-auto px-6"}>
						<div className={"w-full max-w-xl space-y-4"}>
							<InputField name="name" label="Name" placeholder="Enter a name" />
							<InputField
								name="slug"
								label="Slug"
								placeholder="Enter a slug"
								disabled={isEditing}
							/>
							<TextAreaField
								name={"description"}
								label={"Description"}
								placeholder={"Enter a description"}
							/>
						</div>
						<div className={"grid grid-cols-2 gap-4"}>
							<div className={"w-full relative flex h-96"}>
								<ImageSelectorField name={"images"} label={"Region Images"} />
							</div>
						</div>

						<div className={"grid grid-cols-2 gap-4"}>
							<div className={"w-full relative flex h-96"}>
								<ImageSelectorField
									name={"guided_tour_images"}
									label={"Guided Tour Images"}
								/>
							</div>

							<div className={"w-full relative flex h-96"}>
								<ImageSelectorField
									name={"self_guided_tour_images"}
									label={"Self Guided Tour Images"}
								/>
							</div>
						</div>
					</div>

					<Footer region={region} form={form} />
				</>
			)}
		</FormDialog>
	);
};

type FooterProps = {
	region?: Region;
	form: UseFormReturn<FormSchema>;
};

const Footer: FunctionComponent<FooterProps> = (props) => {
	const { form, region } = props;

	const createRegionMutation = RegionQueries.useCreateRegion();
	const updateRegionMutation = RegionQueries.useUpdateRegion();

	const dialog = useDialogContext();

	const onValid = async (values: FormSchema) => {
		dialog.lock();

		try {
			const isEditing = region != null;

			if (isEditing) {
				await updateRegionMutation.mutateAsync({
					id: region.id,
					...values,
				});
			} else {
				await createRegionMutation.mutateAsync({
					name: values.name,
					slug: values.slug,
					description: values.description,
					images: values.images,
					self_guided_tour_images: values.self_guided_tour_images,
					guided_tour_images: values.guided_tour_images,
				});
			}

			dialog.unlock();
			dialog.close();
		} catch (error) {
			dialog.unlock();
			console.error(error);
		}
	};

	const onInvalid = () => {
		toast.error("Please fill in all required fields");
	};

	return (
		<DialogFooter className={"p-6"}>
			<Button
				isLoading={
					createRegionMutation.isPending || updateRegionMutation.isPending
				}
				onClick={form.handleSubmit(onValid, onInvalid)}
			>
				Save region
			</Button>
		</DialogFooter>
	);
};
