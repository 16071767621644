import { FileGrid } from "@/modules/images/components/file-grid.tsx";
import { FileExplorerService } from "@/modules/images/services/file-explorer.service";
import { FunctionComponent } from "react";

export const FileViewer: FunctionComponent = () => {
	const { selectedFiles, files, setSelectedFiles } =
		FileExplorerService.useState();

	return (
		<FileGrid
			files={files}
			selectedFiles={selectedFiles}
			onFileClick={(fileObject) => {
				setSelectedFiles((prev) => {
					if (prev.some((file) => file === fileObject.fullPath)) {
						return prev.filter((file) => file !== fileObject.fullPath);
					}

					return [...prev, fileObject.fullPath];
				});
			}}
		/>
	);
};
