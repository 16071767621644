import { useMutation, useQuery } from "@tanstack/react-query";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Position } from "@turf/turf";

import { MapboxService } from "../services/mapbox.service";

const QueryKeys = {
	root: ["routes"],
	route: (waypoints: Position[]) => [...QueryKeys.root, waypoints],
};

export const MapboxQueries = {
	useRoute: (coordinates: Position[]) => {
		return useQuery({
			enabled: coordinates.length >= 2,
			queryKey: QueryKeys.route(coordinates),
			queryFn: async () => {
				return new MapboxService().getRoute(coordinates);
			},
		});
	},

	useRouteMutation: () => {
		return useMutation({
			mutationFn: async (coordinates: Position[]) => {
				return new MapboxService().getRoute(coordinates);
			},
		});
	},
};
