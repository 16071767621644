import {FunctionComponent, PropsWithChildren} from "react";
import {Dialog, DialogContent, DialogTrigger, useDialogContext} from "@/components/ui/dialog.tsx";
import {Button} from "@/components/ui/button.tsx";
import {FileExplorer} from "@/modules/images/components/file-explorer.tsx";

export type FileExplorerDialogProps = PropsWithChildren<{
    onSelectImages: (images: string[]) => void;
}>

export const FileExplorerDialog: FunctionComponent<FileExplorerDialogProps> = (props) => {
    const { onSelectImages } = props;
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant={"secondary"}>Add images</Button>
            </DialogTrigger>
            <FilExplorerDialogContent onSelectImages={onSelectImages} />
        </Dialog>
    );
};

const FilExplorerDialogContent: FunctionComponent<FileExplorerDialogProps> = (props) => {
    const { onSelectImages } = props;

    const dialog = useDialogContext();

    const handleSelectImages = (images: string[]) => {
        onSelectImages(images);
        dialog.close();
    }

    return (
        <DialogContent
            className={"w-full h-full max-h-screen max-w-screen p-0"}
        >
            <FileExplorer
                onSelectPress={handleSelectImages}
            />
        </DialogContent>
    );
}
