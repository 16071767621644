import { OpenroadClientService } from "@/services/openroad-client.service";
import { DndContext } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import { uniq } from "lodash";
import { X } from "lucide-react";
import {
	FunctionComponent,
	PropsWithChildren,
	useEffect,
	useState,
} from "react";
import { Button } from "../ui/button";
import { Draggable } from "./draggable";
import { Droppable } from "./droppable";
import { FileExplorerDialog } from "./file-explorer.dialog";

export type ImageSelectorProps = PropsWithChildren<{
	onChange?: (images: string[]) => void;
	initialSelectedFiles?: string[];
}>;

export const ImageSelector: FunctionComponent<ImageSelectorProps> = (props) => {
	const { children, onChange, initialSelectedFiles } = props;

	const { client } = OpenroadClientService.useState();
	const [selectedFiles, setSelectedFiles] = useState<string[]>(
		initialSelectedFiles ?? [],
	);

	const onDragEnd = (event: DragEndEvent) => {
		const targetIndex = selectedFiles.indexOf(event.active.id as string);
		const destinationIndex = selectedFiles.indexOf(event.over?.id as string);

		if (targetIndex === -1 || destinationIndex === -1) {
			return;
		}

		setSelectedFiles((prev) => {
			const newFiles = [...prev];

			newFiles.splice(targetIndex, 1);
			newFiles.splice(destinationIndex, 0, event.active.id as string);

			return newFiles;
		});
	};

	useEffect(() => {
		onChange?.(selectedFiles);
	}, [selectedFiles]);

	const onSelectImages = (images: string[]) => {
		setSelectedFiles((prev) => uniq([...prev, ...images]));
	};

	const onRemoveImagePress = (path: string) => {
		setSelectedFiles(selectedFiles.filter((f) => f !== path));
	};

	return (
		<div className={"flex-1 flex flex-col gap-4 relative"}>
			<FileExplorerDialog onSelectImages={onSelectImages}>
				{children != null ? (
					children
				) : (
					<Button variant={"secondary"}>Add images</Button>
				)}
			</FileExplorerDialog>
			<DndContext onDragEnd={onDragEnd}>
				<div className={"grid grid-cols-3 w-full gap-x-4 gap-y-4"}>
					{selectedFiles.map((path, index) => {
						const imagePath = selectedFiles[index];
						const { publicUrl } = client.supabase.getFile(imagePath);

						return (
							<Droppable key={path} id={path}>
								<Draggable id={imagePath} onClick={() => console.log("remove")}>
									<div
										className={
											"group w-full aspect-square rounded overflow-hidden relative"
										}
									>
										<img
											src={publicUrl}
											alt={publicUrl}
											className={"w-full h-full object-cover"}
										/>

										<div
											className={
												"cursor-pointer absolute top-0 right-0 p-2 bg-gray-800/50 text-white hover:bg-gray-800/80 z-[1000]"
											}
											onMouseDown={() => {
												onRemoveImagePress(imagePath);
											}}
										>
											<X className={"h-4 w-4"} />
										</div>

										<div
											className={
												"absolute bottom-0 right-0 p-2 bg-gray-800/50 text-white "
											}
										>
											{index + 1}
										</div>
									</div>
								</Draggable>
							</Droppable>
						);
					})}
				</div>
			</DndContext>
		</div>
	);
};
