import { Button } from "@/components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card.tsx";
import {
	Table,
	TableBody,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table.tsx";
import { FunctionComponent } from "react";
import { RegionQueries } from "../../../queries/region.queries";
import { RegionDialog } from "../dialogs/region.dialog.tsx";
import { RegionTableRow } from "./region-table-row";

export type RegionsTableProps = {};

export const RegionsTable: FunctionComponent<RegionsTableProps> = (props) => {
	const {} = props;

	const { data: regions = [] } = RegionQueries.useRegions();

	return (
		<>
			<Card>
				<CardHeader>
					<div className={"flex flex-row justify-between w-full"}>
						<div>
							<CardTitle>Regions</CardTitle>
							<CardDescription>Manage regions</CardDescription>
						</div>
						<RegionDialog>
							<Button variant={"secondary"} size={"xs"}>
								Create region
							</Button>
						</RegionDialog>
					</div>
				</CardHeader>
				<CardContent>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead>Name</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{regions.map((region) => (
								<RegionTableRow key={region.id} region={region} />
							))}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		</>
	);
};
