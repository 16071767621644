import { Badge } from "@/components/ui/badge";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { Bike, BikeStatusEnum } from "@repo/types";
import { ListFilter, PlusCircle, Search } from "lucide-react";
import { FunctionComponent, useMemo, useState } from "react";

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { CreateBikeDialog } from "@/modules/bikes/dialogs/create-bike.dialog";
import { BikeQueries } from "@/modules/bikes/queries/bike.queries";
import { Link, useNavigate } from "@tanstack/react-router";
import Fuse from "fuse.js";

export const getBadgeVariant = (status: BikeStatusEnum) => {
	switch (status) {
		case "ACTIVE":
			return "default";
		case "INACTIVE":
			return "secondary";
		case "DELETED":
			return "destructive";
		default:
			return "secondary";
	}
};

type Filter = "ALL" | "ACTIVE" | "INACTIVE" | "DELETED";

export function BikesRoute() {
	const { data: bikes } = BikeQueries.useBikes();

	const [search, setSearch] = useState<string>("");
	const [filter, setFilter] = useState<Filter>("ALL");

	const fuzzy = useMemo(() => {
		return new Fuse(bikes ?? [], {
			keys: ["name"],
		});
	}, [bikes]);

	const filteredBikes = useMemo(() => {
		if (search == null || search === "") {
			return bikes;
		}
		return fuzzy.search(search).map((result) => result.item);
	}, [search, fuzzy, bikes]);

	return (
		<div className="flex flex-col sm:gap-4 sm:py-4">
			<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
				<Breadcrumb className="hidden md:flex">
					<BreadcrumbList>
						<BreadcrumbItem>
							<BreadcrumbLink asChild>
								<Link href="#">Bikes</Link>
							</BreadcrumbLink>
						</BreadcrumbItem>
						{/*<BreadcrumbSeparator/>*/}
						{/*<BreadcrumbItem>*/}
						{/*	<BreadcrumbLink asChild>*/}
						{/*		<Link href="#">Products</Link>*/}
						{/*	</BreadcrumbLink>*/}
						{/*</BreadcrumbItem>*/}
						{/*<BreadcrumbSeparator/>*/}
						{/*<BreadcrumbItem>*/}
						{/*	<BreadcrumbPage>All Products</BreadcrumbPage>*/}
						{/*</BreadcrumbItem>*/}
					</BreadcrumbList>
				</Breadcrumb>
				<div className="relative ml-auto flex-1 md:grow-0">
					<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
					<Input
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						type="search"
						placeholder="Search..."
						className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]"
					/>
				</div>
			</header>
			<main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
				<div className="flex items-center">
					<div className="ml-auto flex items-center gap-2">
						{/*<Select value={filter} onValueChange={(e) => setFilter(e as Filter)}>*/}
						{/*	<SelectTrigger className="h-7 gap-1">*/}
						{/*		<SelectValue placeholder="All" />*/}
						{/*	</SelectTrigger>*/}
						{/*	<SelectContent>*/}
						{/*		<SelectItem value="ALL">All</SelectItem>*/}
						{/*		<SelectItem value="ACTIVE">Active</SelectItem>*/}
						{/*		<SelectItem value="INACTIVE">Inactive</SelectItem>*/}
						{/*		<SelectItem value="DELETED">Deleted</SelectItem>*/}
						{/*	</SelectContent>*/}
						{/*</Select>*/}

						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="outline"
									size="sm"
									className={cn(
										"h-7 gap-1 text-sm",
										filter !== "ALL" && "outline outline-primary",
									)}
								>
									<ListFilter className="h-3.5 w-3.5" />
									<span className="sr-only sm:not-sr-only">Status</span>
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuLabel>Filter status by</DropdownMenuLabel>
								<DropdownMenuSeparator />
								<DropdownMenuCheckboxItem
									checked={filter === "ALL"}
									onClick={() => setFilter("ALL")}
								>
									All
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									checked={filter === "ACTIVE"}
									onClick={() => setFilter("ACTIVE")}
								>
									Active
								</DropdownMenuCheckboxItem>
								<DropdownMenuCheckboxItem
									checked={filter === "INACTIVE"}
									onClick={() => setFilter("INACTIVE")}
								>
									Inactive
								</DropdownMenuCheckboxItem>
							</DropdownMenuContent>
						</DropdownMenu>

						<CreateBikeDialog>
							<Button size="sm" className="h-7 gap-1">
								<PlusCircle className="h-3.5 w-3.5" />
								<span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
									Add bike
								</span>
							</Button>
						</CreateBikeDialog>
					</div>
				</div>
				<Card>
					<CardHeader>
						<CardTitle>Bikes</CardTitle>
						<CardDescription>Manage bikes</CardDescription>
					</CardHeader>
					<CardContent>
						<Table>
							<TableHeader>
								<TableRow>
									{/*<TableHead className="hidden w-[100px] sm:table-cell">*/}
									{/*	<span className="sr-only">Image</span>*/}
									{/*</TableHead>*/}
									<TableHead>Name</TableHead>
									<TableHead>Status</TableHead>
									{/*<TableHead>Type</TableHead>*/}
									{/*<TableHead>Price</TableHead>*/}
									{/*<TableHead className="hidden md:table-cell">*/}
									{/*	Total Sales*/}
									{/*</TableHead>*/}
									{/*<TableHead>*/}
									{/*	<span className="sr-only">Actions</span>*/}
									{/*</TableHead>*/}
								</TableRow>
							</TableHeader>
							<TableBody>
								{filteredBikes?.map((bike) => (
									<BikeTableRow bike={bike} key={bike.id} />
								))}
							</TableBody>
						</Table>
					</CardContent>
					{/*<CardFooter>*/}
					{/*	<div className="text-xs text-muted-foreground">*/}
					{/*		Showing <strong>1-10</strong> of <strong>32</strong>{" "}*/}
					{/*		bike*/}
					{/*	</div>*/}
					{/*</CardFooter>*/}
				</Card>
			</main>
		</div>
	);
}

const BikeTableRow: FunctionComponent<{ bike: Bike }> = ({ bike }) => {
	const navigate = useNavigate();

	const onRowClick = () => {
		void navigate({
			to: "/bikes/$bikeId",
			params: {
				bikeId: bike.id,
			},
		});
	};

	return (
		<TableRow
			onClick={onRowClick}
			className="cursor-pointer hover:bg-gray-100/80"
		>
			{/*<TableCell className="hidden sm:table-cell">*/}
			{/*	<Image*/}
			{/*		alt="Product image"*/}
			{/*		className="aspect-square rounded-md object-cover"*/}
			{/*		height="64"*/}
			{/*		src="/placeholder.svg"*/}
			{/*		width="64"*/}
			{/*	/>*/}
			{/*</TableCell>*/}
			<TableCell className="font-medium">{bike.name}</TableCell>
			<TableCell>
				<Badge variant={getBadgeVariant(bike.status)}>{bike.status}</Badge>
			</TableCell>
			{/*<TableCell>{bike.type.map(getTourType).join(" - ")}</TableCell>*/}
			{/*<TableCell>{bike.price_from}</TableCell>*/}
			{/*<TableCell className="hidden md:table-cell">*/}
			{/*	25*/}
			{/*</TableCell>*/}
			{/*<TableCell>*/}
			{/*	<DropdownMenu>*/}
			{/*		<DropdownMenuTrigger asChild>*/}
			{/*			<Button aria-haspopup="true" size="icon" variant="ghost">*/}
			{/*				<MoreHorizontal className="h-4 w-4" />*/}
			{/*				<span className="sr-only">Toggle menu</span>*/}
			{/*			</Button>*/}
			{/*		</DropdownMenuTrigger>*/}
			{/*		<DropdownMenuContent align="end">*/}
			{/*			<DropdownMenuLabel>Actions</DropdownMenuLabel>*/}
			{/*			<DropdownMenuItem>Edit</DropdownMenuItem>*/}
			{/*			<DropdownMenuItem>Delete</DropdownMenuItem>*/}
			{/*		</DropdownMenuContent>*/}
			{/*	</DropdownMenu>*/}
			{/*</TableCell>*/}
		</TableRow>
	);
};
