import { queryClient } from "@/queries";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { SupabaseService } from "@/services/supabase.service";
import { CreateReview, UpdateReview } from "@repo/types";
import { useMutation, useQuery } from "@tanstack/react-query";

export const QueryKeys = {
	root: () => ["review"] as const,
	all: () => [...QueryKeys.root(), "all"] as const,
};

export const ReviewQueries = {
	QueryKeys,

	useReviews: () => {
		const { client } = OpenroadClientService.useState();
		return useQuery({
			queryKey: QueryKeys.all(),
			queryFn: async () => {
				return client.reviews.getReviews();
			},
		});
	},

	useCreateReview: () => {
		return useMutation({
			mutationFn: async (review: CreateReview) => {
				const { data } = await SupabaseService.instance()
					.from("review")
					.insert(review)
					.select("*")
					.throwOnError()
					.single();
				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},

	useUpdateReview: () => {
		return useMutation({
			mutationFn: async (review: UpdateReview) => {
				const { data } = await SupabaseService.instance()
					.from("review")
					.update(review)
					.eq("id", review.id)
					.select("*")
					.throwOnError()
					.single();
				return data;
			},
			onSuccess: async () => {
				await queryClient.invalidateQueries({
					queryKey: QueryKeys.root(),
				});
			},
		});
	},
};
