import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { FunctionComponent } from "react";
import { BikeFormSchema } from "./bike-details-form";

export const BikeSlugField: FunctionComponent = () => {
	return (
		<FormField<BikeFormSchema, "slug">
			name="slug"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Slug</FormLabel>
					<FormControl>
						<Input {...field} placeholder="triumph-tiger-800xr" />
					</FormControl>
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
