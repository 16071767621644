import { Button } from "@/components/ui/button.tsx";
import { CreateFolderDialog } from "@/modules/images/dialogs/create-folder.dialog.tsx";
import { Folder } from "lucide-react";
import { FunctionComponent } from "react";

export const FileExplorerToolbar: FunctionComponent = () => {
	return (
		<div className={"flex justify-start items-center gap-2"}>
			<CreateFolderDialog>
				<Button>
					<Folder className="w-5 h-5 mr-2" />
					Create folder
				</Button>
			</CreateFolderDialog>
			{/*{selectedFiles.length > 0 && (*/}
			{/*	<Button onClick={() => setSelectedFiles([])} variant={"secondary"}>*/}
			{/*		<XCircle className="w-4 h-4 mr-2" />*/}
			{/*		Clear selection*/}
			{/*	</Button>*/}
			{/*)}*/}
		</div>
	);
};
