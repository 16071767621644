import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { Spinner } from "@/components/ui/spinner.tsx";
import { cn } from "@/lib/utils";
import { PropsWithChildren, useMemo } from "react";

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default: "bg-primary text-primary-foreground hover:bg-primary/90",
				destructive:
					"bg-destructive text-destructive-foreground hover:bg-destructive/90",
				outline:
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				secondary:
					"bg-secondary text-secondary-foreground hover:bg-secondary/80",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline",
			},
			size: {
				default: "h-10 px-4 py-2",
				xs: "h-7 px-2 py-1",
				sm: "h-9 px-3",
				lg: "h-11 px-8",
				icon: "h-10 w-10",
				"icon-sm": "h-7 w-7",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
	isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(props, ref) => {
		const {
			children,
			className,
			variant,
			size,
			asChild = false,
			isLoading = false,
			...otherProps
		} = props;

		const Comp = asChild ? Slot : "button";

		const child = useMemo(() => {
			if (asChild) {
				const child = React.Children.only(children);

				if (React.isValidElement(child)) {
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					return React.cloneElement<PropsWithChildren>(child as any, {
						children: (
							<>
								{child.props.children}
								{isLoading && <Spinner className="ml-2 h-4 w-4 stroke-white" />}
							</>
						),
					});
				}
			}

			return (
				<>
					{children}
					{isLoading && <Spinner className="ml-2 h-4 w-4 stroke-white" />}
				</>
			);
		}, [asChild, children, isLoading]);

		return (
			<Comp
				className={cn(buttonVariants({ variant, size, className }))}
				ref={ref}
				{...otherProps}
			>
				{child}
			</Comp>
		);
	},
);
Button.displayName = "Button";

export { Button, buttonVariants };
