import type { Tour, TourLegWithRoute } from "@repo/types";
import { SupabaseService } from "./supabase.service";

export class TourService {
	private supabase: SupabaseService;

	constructor(supabase: SupabaseService) {
		this.supabase = supabase;
	}

	async getTours() {
		const { data } = await this.supabase.instance().from("tour").select("*");
		return data ?? [];
	}

	async getTour(tourId: string) {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.eq("id", tourId)
			.single()
			.throwOnError();

		if (!data) {
			throw new Error(`Tour with id ${tourId} not found`);
		}

		return data;
	}

	async getTourById(tourId: string): Promise<Tour> {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.eq("id", tourId)
			.single()
			.throwOnError();

		if (!data) {
			throw new Error(`Tour with id ${tourId} not found`);
		}

		return data;
	}

	async getToursByRegionId(regionId: string) {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.eq("region", regionId);

		return data ?? [];
	}

	async getToursByRegionSlug(regionSlug: string) {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.eq("region_slug", regionSlug);

		return data ?? [];
	}

	async getGuidedTours() {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.contains("type", ["GUIDED"]);

		return data ?? [];
	}

	async getSelfGuidedTours() {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.contains("type", ["SELF_GUIDED"]);
		return data ?? [];
	}

	async getTourBySlug(slug: string) {
		const { data } = await this.supabase
			.instance()
			.from("tour")
			.select("*")
			.eq("slug", slug)
			.single()
			.throwOnError();

		return data;
	}

	async getTourLegsByTourId(tourId: string) {
		const { data } = await this.supabase
			.instance()
			.from("tour_leg")
			.select("*")
			.order("day")
			.eq("tour_id", tourId);

		return data ?? [];
	}

	async getTourLegsWithRoutesForTourId(tourId: string) {
		const tourLegs = await this.getTourLegsByTourId(tourId);

		const routeIds = tourLegs
			.map((leg) => leg.route_id)
			.filter(Boolean) as string[];

		const routeIdBatches = routeIds.reduce((acc, id, index) => {
			const batchIndex = Math.floor(index / 5);
			if (!acc[batchIndex]) {
				acc[batchIndex] = [];
			}
			acc[batchIndex]?.push(id);
			return acc;
		}, [] as string[][]);

		const routePromises = routeIdBatches.map((batch) =>
			this.supabase
				.instance()
				.from("route")
				.select("*")
				.in("id", batch)
				.then(({ data }) => data ?? []),
		);

		const routes = (await Promise.all(routePromises)).flat();

		return tourLegs.map((leg) => {
			const route = routes.find((r) => r.id === leg.route_id);
			return {
				...leg,
				route,
			};
		}) as TourLegWithRoute[];
	}
}
