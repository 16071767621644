import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { BikeFormSchema } from "@/modules/bikes/components/bike-details-form";
import { FunctionComponent } from "react";

export const BikeDescriptionField: FunctionComponent = () => {
	return (
		<FormField<BikeFormSchema, "description">
			name="description"
			render={({ field }) => (
				<FormItem>
					<FormLabel>Bike Description</FormLabel>
					<FormControl>
						<Textarea
							{...field}
							className={"min-h-[600px] w-full"}
							placeholder="....."
						/>
					</FormControl>
					<FormDescription />
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
