import { ReactNode } from "react";
import { ControllerRenderProps, FieldValues, Path } from "react-hook-form";
import { FormDescription } from "../ui/form";

export type FieldDescriptionProps<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
> = {
	field: ControllerRenderProps<TFieldValues, TName>;
	children:
		| ReactNode
		| ((field: ControllerRenderProps<TFieldValues, TName>) => ReactNode);
};

export function FieldDescription<
	TFieldValues extends FieldValues,
	TName extends Path<TFieldValues>,
>(props: FieldDescriptionProps<TFieldValues, TName>) {
	const { field, children } = props;

	return (
		<FormDescription>
			{typeof children === "function" ? children(field) : children}
		</FormDescription>
	);
}
