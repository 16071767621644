import { Link } from "@tanstack/react-router";
import { CircleUser, Menu } from "lucide-react";
import { PropsWithChildren, useState } from "react";

import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import OpenroadLogo from "./openroad_logo.svg";

export type DashboardProps = PropsWithChildren;

type MainNavLink = {
	label: string;
	to: string;
};

const MAIN_NAV_LINKS = [
	{
		label: "Regions",
		to: "/regions",
	},
	{
		label: "Tours",
		to: "/tours",
	},
	{
		label: "Bikes",
		to: "/bikes",
	},
	{
		label: "Routes",
		to: "/routes",
	},
	{
		label: "Reviews",
		to: "/reviews",
	},
	{
		label: "Images",
		to: "/images",
	},
] as MainNavLink[];

export function Dashboard(props: DashboardProps) {
	const { children } = props;

	// const matchRoute = useMatchRoute();

	const [open, setOpen] = useState(false);

	return (
		<div className="flex min-h-screen w-full flex-col">
			<header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-50">
				<nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
					<Link
						href="/"
						params={{}}
						className="flex items-center gap-2 text-lg font-semibold md:text-base"
					>
						<img src={OpenroadLogo} alt={"openroad logo"} className={"w-96"} />
						<span className="sr-only">Open Road</span>
					</Link>

					{MAIN_NAV_LINKS.map(({ to, label }) => {
						return (
							<Link
								key={`${to}-${label}`}
								to={to}
								params={{}}
								className={cn(
									"text-muted-foreground transition-colors hover:text-foreground",
									// matchRoute({ to, fuzzy: true })
									// 	? "text-primary font-semibold"
									// 	: "",
								)}
							>
								{label}
							</Link>
						);
					})}
				</nav>
				<Sheet open={open} onOpenChange={setOpen}>
					<SheetTrigger asChild>
						<Button
							variant="outline"
							size="icon"
							className="shrink-0 md:hidden"
						>
							<Menu className="h-5 w-5" />
							<span className="sr-only">Toggle navigation menu</span>
						</Button>
					</SheetTrigger>
					<SheetContent side="left">
						<nav className="grid gap-6 text-lg font-medium">
							<Link
								href="/"
								params={{}}
								onClick={() => {
									setOpen(false);
								}}
								className="flex items-center gap-2 text-lg font-semibold"
							>
								<img
									src={OpenroadLogo}
									alt={"openroad logo"}
									className={"h-12"}
								/>
								<span className="sr-only">Open Road</span>
							</Link>
							{MAIN_NAV_LINKS.map(({ to, label }) => {
								return (
									<Link
										key={`${to}-${label}`}
										to={to}
										params={{}}
										onClick={() => {
											setOpen(false);
										}}
										className={cn(
											"text-muted-foreground hover:text-foreground",
											// matchRoute({ to, fuzzy: true })
											// 	? "text-primary font-semibold"
											// 	: "",
										)}
									>
										{label}
									</Link>
								);
							})}
						</nav>
					</SheetContent>
				</Sheet>
				<div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
					<form className="ml-auto flex-1 sm:flex-initial">
						{/*<div className="relative">*/}
						{/*	<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />*/}
						{/*	<Input*/}
						{/*		type="search"*/}
						{/*		placeholder="Search products..."*/}
						{/*		className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px]"*/}
						{/*	/>*/}
						{/*</div>*/}
					</form>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button variant="secondary" size="icon" className="rounded-full">
								<CircleUser className="h-5 w-5" />
								<span className="sr-only">Toggle user menu</span>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuLabel>My Account</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuItem>Settings</DropdownMenuItem>
							<DropdownMenuItem>Support</DropdownMenuItem>
							<DropdownMenuSeparator />
							<DropdownMenuItem>Logout</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</header>
			<main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 md:gap-8 max-w-6xl mx-auto w-full">
				{children}
			</main>
		</div>
	);
}
