import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "@/components/ui/command";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Spinner } from "@/components/ui/spinner.tsx";
import { cn } from "@/lib/utils";
import { TourQueries } from "@/modules/tours/queries/tour.queries";
import { Tour } from "@repo/types";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
import { FunctionComponent } from "react";

export type TourSelectorProps = {
	selectedTourId: string | null;
	onTourSelect: (tour: Tour | null) => void;
	allowAllTours?: boolean;
	requiredText?: string;
};

export const TourSelector: FunctionComponent<TourSelectorProps> = (props) => {
	const {
		selectedTourId,
		onTourSelect,
		allowAllTours = false,
		requiredText = "Select tour...",
	} = props;

	const [open, setOpen] = React.useState(false);

	const { data: tours = [], isPending } = TourQueries.useTours();

	const selectedTour = tours.find((tour) => tour.id === selectedTourId);

	return (
		<div className={"flex-1 relative"}>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						role="combobox"
						aria-expanded={open}
						disabled={isPending}
						className="w-full justify-between"
					>
						{selectedTourId != null ? (
							selectedTour?.name
						) : (
							<>{allowAllTours ? "All tours" : requiredText}</>
						)}
						{isPending ? (
							<Spinner className={"ml-2 h-4 w-4 shrink-0"} />
						) : (
							<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className="w-[300px] p-0 z-[2000]"
					align={"start"}
					side={"right"}
				>
					<Command>
						<CommandInput placeholder="Search tours..." />
						<CommandEmpty>No routes found.</CommandEmpty>
						<CommandGroup>
							{allowAllTours && (
								<CommandItem
									onSelect={() => {
										setOpen(false);
										onTourSelect(null);
									}}
								>
									<Check
										className={cn(
											"mr-2 h-4 w-4",
											selectedTourId == null ? "opacity-100" : "opacity-0",
										)}
									/>
									All tours
								</CommandItem>
							)}

							{tours?.map((framework) => (
								<CommandItem
									key={framework.id}
									value={framework.id}
									onSelect={(routeId) => {
										setOpen(false);

										const route = tours.find((r) => r.id === routeId);
										if (route == null) {
											return;
										}

										onTourSelect(route);
									}}
								>
									<Check
										className={cn(
											"mr-2 h-4 w-4",
											selectedTourId === framework.id
												? "opacity-100"
												: "opacity-0",
										)}
									/>
									{framework.name}
								</CommandItem>
							))}
						</CommandGroup>
					</Command>
				</PopoverContent>
			</Popover>
		</div>
	);
};
