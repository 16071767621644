"use client";

import { OpenroadClient, slugify } from "@repo/api";
import { createContext, useContext, useMemo } from "react";

export type OpenroadClientServiceContextType = {
	client: OpenroadClient;
};

const Context = createContext<OpenroadClientServiceContextType | null>(null);

export const OpenroadClientService = {
	Context,
	Provider: Context.Provider,

	useState() {
		const state = useContext(Context);
		if (state == null) {
			throw new Error("OpenroadClientService not found");
		}
		return state;
	},

	useProvidedState(): OpenroadClientServiceContextType {
		const client = useMemo(() => new OpenroadClient(), []);

		return {
			client,
		};
	},
};

export const isValidSlug = (slug: string, throwable = false) => {
	//Check if slug is empty
	if (slug === "") {
		if (throwable) {
			throw new Error("Slug is empty");
		}
		return false;
	}

	const slugified = slugify(slug);
	const matches = slugified === slug;

	if (!matches) {
		if (throwable) {
			throw new Error("Invalid slug");
		}

		return false;
	}

	// Check to see if there are any special characters in the slug
	const specialChars = /[^a-zA-Z0-9-]/; // This regex matches any character that is not a letter, number, or hyphen
	const hasSpecialChars = specialChars.test(slugified);

	if (hasSpecialChars) {
		if (throwable) {
			throw new Error("Slug contains special characters");
		}
		return false;
	}

	// Check to see if there is a hyphen at the beginning or end of the slug
	const startsWithHyphen = slugified.startsWith("-");
	const endsWithHyphen = slugified.endsWith("-");

	if (startsWithHyphen || endsWithHyphen) {
		if (throwable) {
			throw new Error("Slug starts or ends with hyphen");
		}
		return false;
	}

	return true;
};
