import { SupabaseService } from "./supabase.service";

export class TourDateService {
	private supabase: SupabaseService;

	constructor(supabase: SupabaseService) {
		this.supabase = supabase;
	}

	async getTourDates() {
		const { data } = await this.supabase
			.instance()
			.from("tour_date")
			.select("*")
			.order("start", { ascending: true })
			.not("status", "eq", "DELETED")
			.not("status", "eq", "INACTIVE")
			.gte("finish", new Date().toISOString());

		return data ?? [];
	}

	async getTourDatesForTour(tourId: string | null) {
		if (!tourId) {
			return [];
		}

		const { data } = await this.supabase
			.instance()
			.from("tour_date")
			.select("*")
			.in("status", ["ACTIVE", "FULL", "LIMITED_SPACES"])
			.eq("tour_id", tourId)
			.order("start", { ascending: true })
			.gte("finish", new Date().toISOString());

		return data ?? [];
	}

	async getBikeTourDatePrices(tourDateId: string | null) {
		if (!tourDateId) {
			return [];
		}

		const { data } = await this.supabase
			.instance()
			.from("bike_tour_date_price")
			.select("*")
			.eq("tour_date_id", tourDateId);

		return data ?? [];
	}

	async getTourDateById(tourDateId: string) {
		const { data } = await this.supabase
			.instance()
			.from("tour_date")
			.select("*")
			.eq("id", tourDateId)
			.single();

		return data;
	}

	async getBikeTourDatePricesByIds(bikeTourDatePriceIds: string[]) {
		const { data } = await this.supabase
			.instance()
			.from("bike_tour_date_price")
			.select("*")
			.in("id", bikeTourDatePriceIds)
			.throwOnError();

		return data ?? [];
	}
}
