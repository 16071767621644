import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { FunctionComponent, ReactNode } from "react";

export type NumberFieldProps = {
	name: string;
	placeholder: string;
	label: ReactNode;
	description?: ReactNode;
};

export const NumberField: FunctionComponent<NumberFieldProps> = (props) => {
	const { name, label, placeholder, description } = props;
	return (
		<FormField
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Input
							{...field}
							placeholder={placeholder}
							onChange={(e) => {
								const value = Number(e.target.value);

								if (isNaN(value)) {
									return;
								}

								field.onChange(value);
							}}
						/>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
