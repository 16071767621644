import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form.tsx";
import { Input, InputProps } from "@/components/ui/input.tsx";
import { FunctionComponent, ReactNode } from "react";

export type InputFieldProps = {
	name: string;
	placeholder: string;
	label: ReactNode;
	description?: ReactNode;
} & InputProps;

export const InputField: FunctionComponent<InputFieldProps> = (props) => {
	const { name, label, placeholder, description, ...otherProps } = props;

	const isNumber = otherProps.type === "number";

	return (
		<FormField
			name={name}
			render={({ field }) => (
				<FormItem>
					<FormLabel>{label}</FormLabel>
					<FormControl>
						<Input
							{...otherProps}
							{...field}
							onChange={(e) => {
								if (isNumber) {
									field.onChange(Number(e.target.value));
								} else {
									field.onChange(e);
								}
							}}
							placeholder={placeholder}
						/>
					</FormControl>
					<FormDescription>{description}</FormDescription>
					<FormMessage />
				</FormItem>
			)}
		/>
	);
};
