import { Button } from "@/components/ui/button.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { cn } from "@/lib/utils";
import { UpdateFileDialog } from "@/modules/images/dialogs/update-file.dialog.tsx";
import { OpenroadClientService } from "@/services/openroad-client.service";
import { Edit } from "lucide-react";
import { FunctionComponent, useMemo, useState } from "react";
import {
	ExplorerFile,
	FileExplorerService,
} from "../services/file-explorer.service";

export type FileObjectProps = {
	file: ExplorerFile;
	onClick?: (file: ExplorerFile) => void;
	selectedFiles?: string[];
	setSelectedFiles?: React.Dispatch<React.SetStateAction<ExplorerFile[]>>;
};

export const FileObject: FunctionComponent<FileObjectProps> = (props) => {
	const { file, onClick } = props;
	const { client } = OpenroadClientService.useState();

	const canDisplay = file.name !== ".emptyFolderPlaceholder";

	const [isImageLoaded, setIsImageLoaded] = useState(false);

	const imageUrl = client.supabase.getFile(file.fullPath, {
		transform: {
			quality: 20,
		},
	});

	const { selectedFiles, setSelectedFiles } = FileExplorerService.useState();

	const isSelected = useMemo(
		() => selectedFiles?.some((selectedFile) => selectedFile === file.fullPath),
		[selectedFiles, file.fullPath],
	);

	if (!canDisplay) {
		return null;
	}

	return (
		<div key={file.id} className="relative cursor-pointer pointer-events-auto">
			<div
				className={cn(
					"relative cursor-pointer group aspect-square block w-full overflow-hidden rounded-lg bg-gray-100",
					"focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:ring-4 hover:ring-offset-2 hover:ring-offset-gray-100",
					isSelected ? "ring-4 ring-offset-2 ring-green-500" : "",
				)}
				onKeyUp={undefined}
				onClick={() => onClick?.(file)}
			>
				{/*Checkbox*/}
				<div className="absolute top-2 left-2 rounded hidden group-hover:block">
					<Checkbox
						checked={isSelected}
						onCheckedChange={(checked) => {
							if (checked) {
								setSelectedFiles?.((prev) =>
									prev.filter((selectedFile) => selectedFile !== file.fullPath),
								);
							} else {
								setSelectedFiles((prev) => [...prev, file.fullPath]);
							}
						}}
					/>
				</div>

				<div className={"absolute top-0 right-0 p-2 flex flex-row gap-2"}>
					<UpdateFileDialog fileObject={file}>
						<Button size={"icon-sm"} variant={"secondary"}>
							<Edit className={"h-4 w-4 color text-secondary-foreground"} />
						</Button>
					</UpdateFileDialog>
				</div>

				{!isImageLoaded && (
					<div className="absolute -top-2 -bottom-2 -left-2 -right-2 flex items-center justify-center rounded-lg">
						<Skeleton className={"w-full h-full"} />
					</div>
				)}

				{/*<Dialog>*/}
				{/*	<DialogTrigger asChild>*/}
				<img
					src={imageUrl.publicUrl}
					alt=""
					className="flex-1 cursor-pointer object-cover h-full w-full"
					onLoad={() => setIsImageLoaded(true)}
				/>

				{/*	</DialogTrigger>*/}
				{/*	<DialogContent className={"h-auto w-auto max-w-full p-0 border-0 shadow-none outline-none"}>*/}
				{/*		<img src={imageUrl} alt="" className="flex-1 object-cover h-full w-full"/>*/}
				{/*	</DialogContent>*/}
				{/*</Dialog>*/}
			</div>
			<p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
				{file.name}
			</p>
			{/*<p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p>*/}
		</div>
	);
};
