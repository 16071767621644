import { Badge } from "@/components/ui/badge";
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Loading } from "@/components/ui/loading";
import { cn } from "@/lib/utils";
import { BikeQueries } from "@/modules/bikes/queries/bike.queries";
import { getBadgeVariant } from "@/modules/bikes/routes/bikes.route";
import { Route } from "@/routes/bikes/$bikeId/route";
import { Link, Outlet, useMatchRoute } from "@tanstack/react-router";
import { FunctionComponent } from "react";

const LINKS = [
	{
		label: "Details",
		to: "/bikes/$bikeId",
	},
	{
		label: "Images",
		to: "/bikes/$bikeId/images",
	},
] as const;

export const BikeRoute: FunctionComponent = () => {
	const { bikeId } = Route.useParams();
	const matchRoute = useMatchRoute();

	const { data: bike } = BikeQueries.useBike(bikeId);

	if (bike == null) {
		return <Loading />;
	}

	return (
		<>
			<div className="flex flex-col sm:gap-4 sm:py-4">
				<header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
					<Breadcrumb className="hidden md:flex h-10">
						<BreadcrumbList>
							<BreadcrumbItem>
								<BreadcrumbLink asChild>
									<Link to={"/bikes"} params={{}}>
										Bikes
									</Link>
								</BreadcrumbLink>
							</BreadcrumbItem>
							<BreadcrumbSeparator />
							<BreadcrumbItem>
								<BreadcrumbLink className={"flex gap-2"} asChild>
									<Link to={"/bikes/$bikeId"} params={{ bikeId: bikeId }}>
										{bike.name}
									</Link>
								</BreadcrumbLink>
								<Badge variant={getBadgeVariant(bike.status)}>
									{bike.status}
								</Badge>
							</BreadcrumbItem>
						</BreadcrumbList>
					</Breadcrumb>
				</header>
				<main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
					<div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
						<nav className="grid gap-4 text-sm text-muted-foreground">
							{LINKS.map((link) => (
								<Link
									key={link.to}
									to={link.to}
									params={{
										bikeId,
									}}
									className={cn(
										matchRoute({ to: link.to })
											? "text-primary font-semibold"
											: "",
									)}
								>
									{link.label}
								</Link>
							))}
						</nav>
						<div className="grid gap-6">
							<Outlet />
						</div>
					</div>
				</main>
			</div>
		</>
	);
};
